@import "./variables.style.scss";
@import "./colors.style.scss";
@import "./depths.style.scss";
@import "./typography.style.scss";
@import "./animations.style.scss";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $product-font-family;
}

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: $product-font-family;
  border-radius: 4px;
  color: $grey-700;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid $grey-500;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap;

  .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: $grey-700;
  }
}

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  &.show {
    display: block;
  }
  z-index: 5;
}
