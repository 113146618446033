@import "../../styles/index.scss";

@mixin _dropdown() {
  overflow: visible;
  position: relative;

  .nama-icon,
  span {
    float: left;
  }

  &:hover {
    .subactions {
      display: flex;
      justify-content: center;
    }
  }

  @each $size, $submap in $button-sizes {
    &.#{$size} {
      .subactions {
        margin-top: map-get($submap, height) - 4px;
      }
    }
  }

  .subactions {
    background-color: $grey-100;
    border: 1px solid $grey-200;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3);
    margin-top: map-get($button-size-medium, height) - 4px;
    display: none;
    position: absolute;
    flex-flow: column;
    z-index: 10000000;
    right: 0px;
    top: 3px;

    .nama-button-old {
      margin: 0px !important;
    }
  }
}

.nama-button-old,
nama-button-old {
  &.dropdown {
    @include _dropdown();
  }
}
