@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-section-button {
  width: 100%;
  margin: 0; }

.nama-display {
  display: block; }
  .nama-display.simple, .nama-display.card, .nama-display.accordeon {
    overflow: hidden; }
    .nama-display.simple .title, .nama-display.card .title, .nama-display.accordeon .title {
      font-family: "Source Sans Pro", "Open Sans", sans-serif;
      min-height: 64px; }
      .nama-display.simple .title h3, .nama-display.card .title h3, .nama-display.accordeon .title h3 {
        font-size: 14px;
        padding: 16px;
        font-weight: 600;
        color: #A0AAAF;
        margin-top: 0px;
        float: left; }
      .nama-display.simple .title .nama-icon, .nama-display.card .title .nama-icon, .nama-display.accordeon .title .nama-icon {
        width: 18px;
        float: left;
        padding-top: 4px;
        padding-right: 16px; }
        .nama-display.simple .title .nama-icon svg, .nama-display.card .title .nama-icon svg, .nama-display.accordeon .title .nama-icon svg {
          fill: #2962FF; }
      .nama-display.simple .title .nama-mini-donut-chart, .nama-display.card .title .nama-mini-donut-chart, .nama-display.accordeon .title .nama-mini-donut-chart {
        float: right;
        margin: 10px 10px 0px 0px; }
    .nama-display.simple .content, .nama-display.card .content, .nama-display.accordeon .content {
      padding: 16px 0px;
      display: table;
      box-sizing: border-box;
      width: 100%; }
      .nama-display.simple .content .nama-row, .nama-display.card .content .nama-row, .nama-display.accordeon .content .nama-row {
        margin-top: 0px !important;
        margin-bottom: 0px !important; }
      .nama-display.simple .content .cicle, .nama-display.card .content .cicle, .nama-display.accordeon .content .cicle {
        width: 80px;
        height: 80px;
        margin: 22px 16px 16px 22px;
        float: left;
        border-radius: 80px;
        background: #91F2E5;
        /* Old browsers */
        background: -moz-linear-gradient(top, #91F2E5 0%, #2BD9C2 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #91F2E5 0%, #2BD9C2 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #91F2E5 0%, #2BD9C2 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
        .nama-display.simple .content .cicle p, .nama-display.card .content .cicle p, .nama-display.accordeon .content .cicle p {
          background-color: #fff;
          display: inline-block;
          width: 72px;
          margin: 4px;
          font-size: 22px;
          font-weight: 400;
          text-align: center;
          height: 60px;
          padding-top: 12px;
          border-radius: 72px;
          overflow: hidden; }
          .nama-display.simple .content .cicle p span, .nama-display.card .content .cicle p span, .nama-display.accordeon .content .cicle p span {
            display: block;
            font-size: 12px; }
      .nama-display.simple .content .description, .nama-display.card .content .description, .nama-display.accordeon .content .description {
        padding: 8px 16px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        display: table; }
        .nama-display.simple .content .description.cicled, .nama-display.card .content .description.cicled, .nama-display.accordeon .content .description.cicled {
          float: right;
          width: calc(100% - 118px); }
        .nama-display.simple .content .description p, .nama-display.card .content .description p, .nama-display.accordeon .content .description p {
          border-bottom: 1px solid #D2DADE;
          display: block;
          font-size: 14px;
          width: calc(100% - 16px);
          margin: 0px 8px;
          height: 48px;
          line-height: 48px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
          .nama-display.simple .content .description p.col-2, .nama-display.card .content .description p.col-2, .nama-display.accordeon .content .description p.col-2 {
            width: calc(50% - 16px);
            float: left; }
          .nama-display.simple .content .description p span, .nama-display.card .content .description p span, .nama-display.accordeon .content .description p span {
            float: right;
            font-size: 20px;
            font-weight: 700;
            display: block;
            width: 50%;
            text-align: right; }
        .nama-display.simple .content .description p:nth-child(3), .nama-display.card .content .description p:nth-child(3), .nama-display.accordeon .content .description p:nth-child(3) {
          border-bottom: none; }
        .nama-display.simple .content .description p:nth-child(4), .nama-display.card .content .description p:nth-child(4), .nama-display.accordeon .content .description p:nth-child(4) {
          border-bottom: none; }
        .nama-display.simple .content .description p:last-child, .nama-display.card .content .description p:last-child, .nama-display.accordeon .content .description p:last-child {
          border-bottom: none; }
      .nama-display.simple .content .list, .nama-display.card .content .list, .nama-display.accordeon .content .list {
        padding: 8px 16px;
        box-sizing: border-box;
        width: 100%;
        font-size: 14px;
        height: auto;
        display: table;
        list-style: none;
        font-family: "Source Sans Pro", "Open Sans", sans-serif;
        color: #737b80;
        font-weight: 700; }
        .nama-display.simple .content .list li::before, .nama-display.card .content .list li::before, .nama-display.accordeon .content .list li::before {
          content: "• ";
          color: #2962FF;
          /* or whatever color you prefer */ }
        .nama-display.simple .content .list li, .nama-display.card .content .list li, .nama-display.accordeon .content .list li {
          line-height: 24px; }
          .nama-display.simple .content .list li span, .nama-display.card .content .list li span, .nama-display.accordeon .content .list li span {
            float: right;
            font-size: 12px;
            font-weight: 500;
            display: block;
            width: 50%;
            text-align: right; }
    .nama-display.simple .nama-table .content, .nama-display.card .nama-table .content, .nama-display.accordeon .nama-table .content {
      padding: 0px; }
  .nama-display.accordeon {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin: 8px 8px 8px 0px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3); }
    .nama-display.accordeon .title {
      border-radius: 8px 8px 0px 0px;
      background-color: #fff;
      cursor: pointer;
      user-select: none; }
      .nama-display.accordeon .title .nama-icon[icon="dropdown-down-single"] {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0;
        padding: 0;
        transform: rotate(-90deg);
        transition: 0.2s transform; }
        .nama-display.accordeon .title .nama-icon[icon="dropdown-down-single"] svg {
          fill: #A0AAAF; }
    .nama-display.accordeon .accordeon-content {
      overflow: hidden;
      height: auto;
      max-height: 0px; }
    .nama-display.accordeon.opened .accordeon-content {
      max-height: 100%; }
    .nama-display.accordeon.opened .nama-icon[icon="dropdown-down-single"] {
      transform: rotate(0deg); }
  .nama-display.simple .title {
    border-bottom: 1px solid #D2DADE; }
    .nama-display.simple .title h3 {
      font-size: 16px;
      text-transform: capitalize; }
  .nama-display.card {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin: 8px 8px 8px 0px;
    box-sizing: border-box;
    box-shadow: 0 8px 10px 0 rgba(87, 94, 98, 0.3); }
    .nama-display.card .title {
      border-radius: 8px 8px 0px 0px;
      background-color: #EEF0F3; }
      .nama-display.card .title h3 {
        text-transform: uppercase; }
    .nama-display.card .content {
      padding: 16px; }
    .nama-display.card.dark {
      background-color: #35383A;
      color: #E3E8EB; }
      .nama-display.card.dark .description p {
        color: #E3E8EB;
        border-bottom-color: #252729; }
      .nama-display.card.dark .title {
        background-color: #252729;
        border-bottom-color: #252729; }
