.nama-form {
  display: block;
  font-family: $product-font-family;
  font-size: 14px;
  width: 100%;

  &.inline {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    max-width: none !important;
    align-items: flex-end;

    input,
    select {
      margin-bottom: 0px;
    }

    .field-error-message {
      position: absolute;
      width: 163px;
      margin-top: 10px;
    }

    &.actions,
    .actions {
      flex: 2;
      margin: 0px 0px;
      max-width: 80px;
      text-align: center;
      display: block;
      width: auto;
      display: block;

      .nama-button-old {
        width: initial !important;
        float: left;
      }

      span {
        float: left;
      }
    }

    span {
      line-height: 44px;
      margin: 0px 8px;
    }

    fieldset {
      flex: 1;
      margin: 0px;
      width: auto;

      &.small {
        max-width: 88px;
      }

      input,
      select {
        // width: 175px;
        width: calc(100% - 8px);
      }

      .form-group {
        display: flex;
        flex-direction: row !important;
        padding: 0px;
        margin: 0px;
        align-items: flex-end;
      }
    }
  }

  &.actions,
  .actions {
    margin-top: 2 * $base-spacing;
    margin: 0px 4px;
    display: flex;

    .nama-button-old {
      width: 100% !important;
      margin-right: $base-spacing;
      width: auto;
      box-sizing: content-box;
    }

    .nama-button-old:last-child {
      margin-right: 0;
    }
  }

  p.form-group {
    display: flex;

    input {
      margin-right: $base-spacing;
    }
  }
}
