$depth-100: 0 2px 2px 0 rgba(87,94,98,0.25);
$depth-200: 0 4px 6px 0 rgba(87,94,98,0.30);
$depth-300: 0 8px 10px 0 rgba(87,94,98,0.30);
$depth-400: 0 16px 28px 0 rgba(87,94,98,0.30);
$depth-500: 0 24px 32px 0 rgba(87,94,98,0.30);

@mixin _depths(){

  .depth-100{ box-shadow: $depth-100;}
  .depth-200{ box-shadow: $depth-200;}
  .depth-300{ box-shadow: $depth-300;}
  .depth-400{ box-shadow: $depth-400;}
  .depth-500{ box-shadow: $depth-500;}

  // TODO: remove when has cards
  .depth-card{
    .content >div{
      font-size: 12px;
      color: #A0AAAF;
      width: 20%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 4px;
      float: left;
      margin: 16px;
      letter-spacing: 0;
      text-align: center;
      line-height: 16px;
    }
  }
}
