$base-bar-size: 64px;
// Fonts
$base-font-family: 'MarkPro', 'Open Sans', sans-serif;
$product-font-family: 'Source Sans Pro', 'Open Sans', sans-serif;
// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;
// Other Sizes
$base-border-radius: 3px;
$base-spacing: 8px;
$base-z-index: 0;
// Animations
$base-duration: 400ms;
$base-timing: ease;
$base-transition:all $base-duration $base-timing;

$header-height: 80px;
$header-padding: 10px;
