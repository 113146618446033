@import "../../styles/index.scss";
// COLOR MAPS
$nama-button-old: ".nama-button-old, nama-button-old";

$button-color-clear: (
  text: $grey-700,
  background: transparent,
  border: transparent,
  hover: transparent,
  hover-color: $grey-800,
  hover-border: transparent,
  active: transparent,
  active-color: $blue-500,
  focus: transparent,
  focus-color: $grey-700,
  selected: $grey-700,
  selected-color: $white
);

$button-color-default: (
  text: $grey-700,
  background: $grey-200,
  border: $grey-200,
  hover: $grey-200,
  hover-color: $grey-800,
  hover-border: $grey-600,
  active: $blue-500,
  active-color: $white,
  focus: $grey-200,
  focus-color: $grey-800,
  selected: $grey-700,
  selected-color: $white
);

$button-color-white: (
  text: $grey-700,
  background: $grey-100,
  border: $grey-100,
  hover: $grey-200,
  hover-color: $grey-800,
  hover-border: $grey-200,
  active: $grey-700,
  active-color: $white,
  focus: $grey-200,
  focus-color: $grey-800,
  selected: $grey-700,
  selected-color: $white
);

$button-color-primary: (
  text: $white,
  background: $primary,
  border: $primary,
  hover: $blue-200,
  hover-color: $white,
  hover-border: $blue-200,
  active: $blue-500,
  active-color: $white,
  focus: $blue-300,
  focus-color: $white,
  selected: $grey-700,
  selected-color: $white
);

$button-color-info: (
  text: $white,
  background: $info,
  border: $info,
  hover: $purple-200,
  hover-color: $white,
  hover-border: $purple-200,
  active: $purple-500,
  active-color: $white,
  focus: $purple-300,
  focus-color: $white,
  selected: $grey-700,
  selected-color: $white
);

$button-color-success: (
  text: $white,
  background: $success,
  border: $success,
  hover: $green-200,
  hover-color: $white,
  hover-border: $green-200,
  active: $green-500,
  active-color: $white,
  focus: $green-300,
  focus-color: $white,
  selected: $grey-700,
  selected-color: $white
);

$button-color-warning: (
  text: $white,
  background: $warning,
  border: $warning,
  hover: $yellow-200,
  hover-color: $white,
  hover-border: $yellow-200,
  active: $yellow-500,
  active-color: $white,
  focus: $yellow-300,
  focus-color: $white,
  selected: $grey-700,
  selected-color: $white
);

$button-color-danger: (
  text: $white,
  background: $danger,
  border: $danger,
  hover: $red-200,
  hover-color: $white,
  hover-border: $red-200,
  active: $red-500,
  active-color: $white,
  focus: $red-300,
  focus-color: $white,
  selected: $grey-700,
  selected-color: $white
);

// SIZE MAPS
$button-size-huge: (
  height: 56px,
  font-size: 16px,
  icon: 20px,
  line: 52px
);
$button-size-big: (
  height: 44px,
  font-size: 16px,
  icon: 20px,
  line: 44px
);
$button-size-medium: (
  height: 36px,
  font-size: 14px,
  icon: 20px,
  line: 36px
);
$button-size-small: (
  height: 32px,
  font-size: 12px,
  icon: 16px,
  line: 32px
);
$button-size-tiny: (
  height: 24px,
  font-size: 12px,
  icon: 16px,
  line: 24px
);

$button-sizes: (
  huge: $button-size-huge,
  big: $button-size-big,
  medium: $button-size-medium,
  small: $button-size-small,
  tiny: $button-size-tiny
);

$colors: (
  white: $button-color-white,
  clear: $button-color-clear,
  default: $button-color-default,
  primary: $button-color-primary,
  info: $button-color-info,
  success: $button-color-success,
  warning: $button-color-warning,
  danger: $button-color-danger
);

@import "./button.dropdown.atom.scss";

@mixin button {
  // text
  font-weight: 400;
  font-family: $product-font-family;
  text-transform: uppercase;

  // appearance
  // appearance: none;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  // user-select: none;
  vertical-align: middle;
  margin: $base-spacing/2;
  box-sizing: border-box;
  padding: $base-spacing;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  transition: $base-transition;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.avatar {
    padding-left: 8px !important;

    .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px;
    }

    span {
      float: left;
      width: auto;
    }

    .nama-icon {
      margin-right: 8px;
      float: left;
    }
  }

  &.sub-menu-item {
    @include title_typography(16px, 20px, 32px, -0.06px, $grey-800);
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 4 * $base-spacing;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;

    &:hover {
      border-bottom: 1px solid $blue-400;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: $grey-1000;
    }

    &.active {
      border-bottom: 1px solid $primary;
      color: $grey-800;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }

  &.expanded {
    width: calc(100% - 8px);
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.full {
    padding: 0px 4 * $base-spacing;
  }

  &.padded {
    padding: 0px 2 * $base-spacing;
  }

  &.compact {
    padding: 0px;
  }

  &.icon {
    .nama-icon {
      display: inherit;
      margin-left: 0px;
    }

    &.left {
      span {
        float: right;
      }

      .nama-icon {
        margin-right: 8px;
        float: left;
      }
    }

    &.right {
      span {
        float: left;
      }

      .nama-icon {
        margin-left: 8px;
        float: right;
      }
    }

    &.center {
      .nama-icon {
        margin-left: auto;
        float: none;
        margin-right: auto;
      }
    }
  }

  @include button_size($button-size-medium);

  &.nama-submit {
    box-sizing: border-box;
    font-size: map-get($button-size-medium, font-size)-2;
    line-height: map-get($button-size-medium, height)-2;
    height: map-get($button-size-medium, height)-2;
  }

  @each $size, $submap in $button-sizes {
    &.#{$size} {
      @include button_size($submap);
    }

    &.nama-submit.#{$size} {
      box-sizing: border-box;
      font-size: map-get($submap, font-size);
      line-height: map-get($submap, height);
      height: map-get($submap, height);
    }
  }

  @include button_color($button-color-default);

  @each $color, $submap in $colors {
    &.#{$color} {
      @include button_color($submap);
    }
  }

  @each $color, $submap in $colors {
    &.icon-#{$color} {
      @include icon_color($submap);
    }
  }

  &:focus,
  &.focus {
    border: 1px solid $blue-200;
  }

  &.clear,
  &.default {
    &:focus,
    &.focus {
      border: 1px solid $blue-200;
    }
  }

  &.clear {
    &:disabled,
    &.disabled {
      cursor: not-allowed;
      background-color: transparent;
      color: $grey-400;
      border: 1px solid transparent;
      outline: none;

      .nama-icon {
        fill: $grey-400 !important;
      }
    }
  }
}

@mixin button_size($size) {
  height: map-get($size, height);
  font-size: map-get($size, font-size);
  line-height: map-get($size, line);
  padding-top: 0px;
  padding-bottom: 0px;

  span {
    width: calc(100% - #{map-get($size, icon) + 20});
    font-size: map-get($size, font-size);
    line-height: map-get($size, height) - 2;
    display: table;
  }

  &.avatar {
    span {
      padding-right: 8px;
      width: calc(100% - #{map-get($size, icon) + 94});
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .nama-icon {
    width: map-get($size, icon);
    margin: 16px;
    margin-top: ((map-get($size, height) - map-get($size, icon)) /2) - 1px;
    height: map-get($size, icon);
  }
}

@mixin icon_color($color) {
  .nama-icon {
    fill: map-get($color, background);
  }

  &:hover,
  &.hover {
    .nama-icon {
      fill: map-get($color, background);
    }
  }

  &.selected {
    .nama-icon {
      fill: map-get($color, background) !important;
    }
  }

  &:focus,
  &.focus {
    .nama-icon {
      fill: map-get($color, background) !important;
    }
  }

  &:active,
  &.active {
    .nama-icon {
      fill: map-get($color, background) !important;
    }
  }

  &:disabled,
  &.disabled {
    .nama-icon {
      fill: background !important;
    }
  }
}

@mixin button_color($color) {
  background-color: map-get($color, background);
  border: 1px solid map-get($color, border);
  color: map-get($color, text);

  .nama-icon {
    fill: map-get($color, text);
  }

  &:hover,
  &.hover {
    background-color: map-get($color, hover);
    border: 1px solid map-get($color, hover-border);
    color: map-get($color, hover-color);

    .nama-icon {
      fill: map-get($color, hover-color);
    }
  }

  &.selected {
    background-color: map-get($color, selected);
    color: map-get($color, selected-color);
    border: 1px solid map-get($color, selected);
    outline: none;

    .nama-icon {
      fill: map-get($color, selected-color) !important;
    }
  }

  &:focus,
  &.focus {
    background-color: map-get($color, focus);
    color: map-get($color, focus-color);
    border: 1px solid map-get($color, focus);
    outline: none;

    .nama-icon {
      fill: map-get($color, focus-color) !important;
    }
  }

  &:active,
  &.active {
    background-color: map-get($color, active);
    color: map-get($color, active-color);
    border: 1px solid map-get($color, active);
    outline: none;

    .nama-icon {
      fill: map-get($color, active-color) !important;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed !important;
    background-color: $grey-400;
    color: $grey-600;
    border: 1px solid $grey-400;
    outline: none;

    .nama-icon {
      fill: $grey-600 !important;
    }
  }
}

nama-button-old,
.nama-button-old {
  @include button;
}
