@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600);
@charset "UTF-8";
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-form {
  display: block;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 14px;
  width: 100%; }
  .nama-form.inline {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    max-width: none !important;
    align-items: flex-end; }
    .nama-form.inline input,
    .nama-form.inline select {
      margin-bottom: 0px; }
    .nama-form.inline .field-error-message {
      position: absolute;
      width: 163px;
      margin-top: 10px; }
    .nama-form.inline.actions,
    .nama-form.inline .actions {
      flex: 2 1;
      margin: 0px 0px;
      max-width: 80px;
      text-align: center;
      display: block;
      width: auto;
      display: block; }
      .nama-form.inline.actions .nama-button-old,
      .nama-form.inline .actions .nama-button-old {
        width: auto !important;
        width: initial !important;
        float: left; }
      .nama-form.inline.actions span,
      .nama-form.inline .actions span {
        float: left; }
    .nama-form.inline span {
      line-height: 44px;
      margin: 0px 8px; }
    .nama-form.inline fieldset {
      flex: 1 1;
      margin: 0px;
      width: auto; }
      .nama-form.inline fieldset.small {
        max-width: 88px; }
      .nama-form.inline fieldset input,
      .nama-form.inline fieldset select {
        width: calc(100% - 8px); }
      .nama-form.inline fieldset .form-group {
        display: flex;
        flex-direction: row !important;
        padding: 0px;
        margin: 0px;
        align-items: flex-end; }
  .nama-form.actions,
  .nama-form .actions {
    margin-top: 16px;
    margin: 0px 4px;
    display: flex; }
    .nama-form.actions .nama-button-old,
    .nama-form .actions .nama-button-old {
      width: 100% !important;
      margin-right: 8px;
      width: auto;
      box-sizing: content-box; }
    .nama-form.actions .nama-button-old:last-child,
    .nama-form .actions .nama-button-old:last-child {
      margin-right: 0; }
  .nama-form p.form-group {
    display: flex; }
    .nama-form p.form-group input {
      margin-right: 8px; }

.nama-graph {
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  .nama-graph .nama-graph-y-tick {
    font-weight: 600;
    font-size: 12px;
    color: #737B80;
    letter-spacing: 0;
    text-align: right; }
  .nama-graph .nama-graph-x-tick {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.15px;
    text-align: center; }
  .nama-graph .recharts-default-tooltip {
    background: #FCFDFF;
    box-shadow: 0 24px 32px 0 rgba(87, 94, 98, 0.3);
    font-weight: 600;
    font-size: 11px;
    color: #737B80;
    letter-spacing: 0.06px;
    border-radius: 10px;
    line-height: 12px; }

div.nama-row,
section.nama-row {
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  box-sizing: border-box;
  display: table !important; }
  div.nama-row .exemple,
  section.nama-row .exemple {
    background-color: #E3E8EB;
    border-radius: 4px;
    height: 42px;
    width: 100%;
    display: block; }
  div.nama-row .col,
  section.nama-row .col {
    float: left;
    margin-left: 8px;
    margin-right: 8px;
    box-sizing: border-box; }
    div.nama-row .col.grey,
    section.nama-row .col.grey {
      background-color: #E3E8EB;
      border-radius: 8px;
      padding: 0px 8px; }
    div.nama-row .col:first-child,
    section.nama-row .col:first-child {
      margin-left: 0px; }
    div.nama-row .col:last-child,
    section.nama-row .col:last-child {
      margin-right: 0px; }
    div.nama-row .col:first-child.col-9, div.nama-row .col:last-child.col-9,
    section.nama-row .col:first-child.col-9,
    section.nama-row .col:last-child.col-9 {
      width: calc(75% - 8px); }
    div.nama-row .col:first-child.col-6, div.nama-row .col:last-child.col-6,
    section.nama-row .col:first-child.col-6,
    section.nama-row .col:last-child.col-6 {
      width: calc(50% - 8px); }
    div.nama-row .col:first-child.col-3, div.nama-row .col:last-child.col-3,
    section.nama-row .col:first-child.col-3,
    section.nama-row .col:last-child.col-3 {
      width: calc(25% - 8px); }
    div.nama-row .col:first-child.col-8, div.nama-row .col:last-child.col-8,
    section.nama-row .col:first-child.col-8,
    section.nama-row .col:last-child.col-8 {
      width: calc(66.66% - 8px); }
    div.nama-row .col:first-child.col-4, div.nama-row .col:last-child.col-4,
    section.nama-row .col:first-child.col-4,
    section.nama-row .col:last-child.col-4 {
      width: calc(33.33% - 8px); }
    div.nama-row .col.col-12,
    section.nama-row .col.col-12 {
      width: 100%; }
    div.nama-row .col.col-9,
    section.nama-row .col.col-9 {
      width: calc(75% - 16px); }
    div.nama-row .col.col-6,
    section.nama-row .col.col-6 {
      width: calc(50% - 16px); }
    div.nama-row .col.col-3,
    section.nama-row .col.col-3 {
      width: calc(25% - 16px); }
    div.nama-row .col.col-8,
    section.nama-row .col.col-8 {
      width: calc(66.66% - 16px); }
    div.nama-row .col.col-4,
    section.nama-row .col.col-4 {
      width: calc(33.33% - 16px); }
  div.nama-row.padded,
  section.nama-row.padded {
    padding: 8px calc(8.3% + 8px); }

section.nama-card-row {
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  box-sizing: border-box;
  display: table !important;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch; }
  section.nama-card-row .exemple {
    background-color: #E3E8EB;
    border-radius: 4px;
    height: 42px;
    width: 100%;
    display: block; }
  section.nama-card-row .col {
    float: left;
    margin-left: 8px;
    margin-right: 8px;
    box-sizing: border-box; }
    section.nama-card-row .col.grey {
      background-color: #E3E8EB;
      border-radius: 8px;
      padding: 0px 8px; }
    section.nama-card-row .col:first-child {
      margin-left: 0px; }
    section.nama-card-row .col:last-child {
      margin-right: 0px; }
    section.nama-card-row .col:first-child.col-9, section.nama-card-row .col:last-child.col-9 {
      width: calc(75% - 8px); }
    section.nama-card-row .col:first-child.col-6, section.nama-card-row .col:last-child.col-6 {
      width: calc(50% - 8px); }
    section.nama-card-row .col:first-child.col-3, section.nama-card-row .col:last-child.col-3 {
      width: calc(25% - 8px); }
    section.nama-card-row .col:first-child.col-8, section.nama-card-row .col:last-child.col-8 {
      width: calc(66.66% - 8px); }
    section.nama-card-row .col:first-child.col-4, section.nama-card-row .col:last-child.col-4 {
      width: calc(33.33% - 8px); }
    section.nama-card-row .col.col-12 {
      width: 100%; }
    section.nama-card-row .col.col-9 {
      width: calc(75% - 16px); }
    section.nama-card-row .col.col-6 {
      width: calc(50% - 16px); }
    section.nama-card-row .col.col-3 {
      width: calc(25% - 16px); }
    section.nama-card-row .col.col-8 {
      width: calc(66.66% - 16px); }
    section.nama-card-row .col.col-4 {
      width: calc(33.33% - 16px); }

section.nama-destacked-content {
  display: flex;
  flex-direction: column;
  clear: both;
  background: #EEF0F3;
  border-radius: 8px;
  padding: 10px;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  section.nama-destacked-content .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    section.nama-destacked-content .header .nama-icon {
      width: 20px; }

section.nama-content {
  width: 100%;
  min-height: calc(100vh - 127px);
  padding: 32px calc(8.3% + 8px);
  max-width: calc(1024px + 16.6%) !important;
  box-sizing: border-box;
  display: block; }
  section.nama-content.small {
    max-width: calc(724px + 16.6%) !important; }
  section.nama-content.micro {
    max-width: calc(524px + 16.6%) !important; }
  section.nama-content.center {
    padding-top: 64px;
    padding-bottom: 64px;
    margin: auto; }
  section.nama-content.right {
    margin-left: auto; }

.ul-list {
  padding: 8px 16px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  height: auto;
  display: table;
  list-style: none;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  color: #737b80;
  font-weight: 700; }
  .ul-list li::before {
    content: "\2022   ";
    color: #2962FF; }
  .ul-list li {
    line-height: 24px; }

.analytics-intents {
  margin: 64px 32px; }
  .analytics-intents .analytics-intents-header {
    box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25);
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    color: #A0AAAF;
    text-transform: uppercase;
    background-color: #F6F7F8;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 32px; }
  .analytics-intents .analytics-intents-title {
    padding: 16px 32px; }
    .analytics-intents .analytics-intents-title .nama-icon {
      width: 22px;
      float: left;
      padding-right: 16px; }
  .analytics-intents .analytics-intents-subheader {
    background-color: #EEF0F3;
    height: 22px;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 32px;
    display: flex;
    align-content: space-between; }
    .analytics-intents .analytics-intents-subheader .intent {
      width: 50%;
      display: block;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader .volume {
      width: 20%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader .percentage {
      width: 30%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader intent,
    .analytics-intents .analytics-intents-subheader volume,
    .analytics-intents .analytics-intents-subheader percentage {
      height: 22px;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      color: #737B80; }
  .analytics-intents .analytics-intents-item {
    margin: 16px 0px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px 32px;
    box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25);
    align-items: center;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    display: flex;
    align-content: space-between; }
    .analytics-intents .analytics-intents-item .intent {
      width: 50%;
      display: block;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item .volume {
      width: 20%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item .percentage {
      width: 30%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item intent,
    .analytics-intents .analytics-intents-item volume,
    .analytics-intents .analytics-intents-item percentage {
      min-height: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #575E62; }

.operators-or-team-selector {
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25) !important;
  margin-bottom: 10px; }
  .operators-or-team-selector .nama-button-old .nama-icon svg * {
    -webkit-transition: 0.2s fill;
    transition: 0.2s fill; }
  .operators-or-team-selector .nama-button-old:hover .nama-icon svg *,
  .operators-or-team-selector .nama-button-old.option-selected .nama-icon svg * {
    fill: #00BFA5 !important; }

.title-operators {
  display: block;
  background-image: linear-gradient(156deg, #41a2f0 0%, #91f2e6 100%);
  border: 1px solid #bbdcf7;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 16px;
  padding: 6px 14px;
  border-radius: 20px;
  margin-bottom: 25px;
  text-transform: uppercase; }
  .title-operators.orange-title {
    background-image: linear-gradient(143deg, #ff6c35 0%, #ff9e7c 100%);
    border: 1px solid #e3e8eb; }
  .title-operators .nama-icon {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
    .title-operators .nama-icon svg path {
      fill: #ffffff; }

.space-above {
  margin-top: 45px; }

.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -moz-flex;
  display: flex;
  -moz-flex: 0 1 auto;
  flex: 0 1 auto;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -moz-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: auto; }

.col-xs-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 0; }

.col-xs-offset-1 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  -moz-flex-grow: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -moz-flex-grow: 1;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-sm {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-md-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-md {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -moz-flex-grow: 0;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-lg {
    -moz-flex-grow: 1;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.infinite-calendar.automation-calendar {
  position: absolute;
  right: 0px;
  box-shadow: 0 24px 32px 0 rgba(87, 94, 98, 0.3); }

p, code, span, a {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6, h7, h8, hr {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0px; }
  h1.row, h2.row, h3.row, h4.row, h5.row, h6.row, h7.row, h8.row, hr.row {
    padding-top: 8px;
    border-top: 1px solid #D2DADE; }

h2, h3, h4, h7, h8 {
  font-weight: 300; }

h1, p {
  font-weight: 400; }

h5, h6 {
  font-weight: 500; }

h1 {
  font-size: 32px;
  line-height: 40px;
  margin-top: 48px;
  letter-spacing: -0.04px;
  color: #575E62; }

h2 {
  font-size: 24px;
  line-height: 28px;
  margin-top: 40px;
  letter-spacing: -0.04px;
  color: #575E62; }

h3 {
  font-size: 20px;
  line-height: 28px;
  margin-top: 36px;
  letter-spacing: -0.08px;
  color: #575E62; }

h4 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
  letter-spacing: -0.06px;
  color: #575E62; }

h5 {
  font-size: 14px;
  line-height: 16px;
  margin-top: 24px;
  letter-spacing: -0.04px;
  color: #575E62; }

h6 {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #A0AAAF; }

h7 {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #A0AAAF;
  display: block; }

h8 {
  font-size: 11px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0.06px;
  color: #A0AAAF;
  display: block; }

p {
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
  letter-spacing: -0.04px;
  color: #575E62;
  font-weight: 300; }

a {
  color: #41A2F0;
  cursor: pointer;
  -webkit-transition: color 400ms ease;
  transition: color 400ms ease; }
  a:hover {
    color: #2962FF; }
  a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #A0AAAF;
  margin: 0px;
  border-left: 0;
  border-right: 0;
  border-top: 0; }

.color-primary {
  color: #2962FF !important; }

.background-primary {
  background: #2962FF !important; }

.fill-primary * {
  fill: #2962FF !important; }

.color-info {
  color: #8D25AA !important; }

.background-info {
  background: #8D25AA !important; }

.fill-info * {
  fill: #8D25AA !important; }

.color-danger {
  color: #F50057 !important; }

.background-danger {
  background: #F50057 !important; }

.fill-danger * {
  fill: #F50057 !important; }

.color-warning {
  color: #FFB300 !important; }

.background-warning {
  background: #FFB300 !important; }

.fill-warning * {
  fill: #FFB300 !important; }

.color-success {
  color: #00BFA5 !important; }

.background-success {
  background: #00BFA5 !important; }

.fill-success * {
  fill: #00BFA5 !important; }

.color-white {
  color: #ffffff !important; }

.background-white {
  background: #ffffff !important; }

.fill-white * {
  fill: #ffffff !important; }

.color-black {
  color: #000000 !important; }

.background-black {
  background: #000000 !important; }

.fill-black * {
  fill: #000000 !important; }

.color-grey-100 {
  color: #FCFDFF !important; }

.background-grey-100 {
  background: #FCFDFF !important; }

.fill-grey-100 * {
  fill: #FCFDFF !important; }

.color-grey-200 {
  color: #F6F7F8 !important; }

.background-grey-200 {
  background: #F6F7F8 !important; }

.fill-grey-200 * {
  fill: #F6F7F8 !important; }

.color-grey-300 {
  color: #EEF0F3 !important; }

.background-grey-300 {
  background: #EEF0F3 !important; }

.fill-grey-300 * {
  fill: #EEF0F3 !important; }

.color-grey-400 {
  color: #E3E8EB !important; }

.background-grey-400 {
  background: #E3E8EB !important; }

.fill-grey-400 * {
  fill: #E3E8EB !important; }

.color-grey-500 {
  color: #D2DADE !important; }

.background-grey-500 {
  background: #D2DADE !important; }

.fill-grey-500 * {
  fill: #D2DADE !important; }

.color-grey-600 {
  color: #A0AAAF !important; }

.background-grey-600 {
  background: #A0AAAF !important; }

.fill-grey-600 * {
  fill: #A0AAAF !important; }

.color-grey-700 {
  color: #737B80 !important; }

.background-grey-700 {
  background: #737B80 !important; }

.fill-grey-700 * {
  fill: #737B80 !important; }

.color-grey-800 {
  color: #575E62 !important; }

.background-grey-800 {
  background: #575E62 !important; }

.fill-grey-800 * {
  fill: #575E62 !important; }

.color-grey-900 {
  color: #35383A !important; }

.background-grey-900 {
  background: #35383A !important; }

.fill-grey-900 * {
  fill: #35383A !important; }

.color-grey-1000 {
  color: #252729 !important; }

.background-grey-1000 {
  background: #252729 !important; }

.fill-grey-1000 * {
  fill: #252729 !important; }

.color-grey-1100 {
  color: #121213 !important; }

.background-grey-1100 {
  background: #121213 !important; }

.fill-grey-1100 * {
  fill: #121213 !important; }

.color-green-100 {
  color: #91F2E5 !important; }

.background-green-100 {
  background: #91F2E5 !important; }

.fill-green-100 * {
  fill: #91F2E5 !important; }

.color-green-200 {
  color: #2BD9C2 !important; }

.background-green-200 {
  background: #2BD9C2 !important; }

.fill-green-200 * {
  fill: #2BD9C2 !important; }

.color-green-300 {
  color: #00BFA5 !important; }

.background-green-300 {
  background: #00BFA5 !important; }

.fill-green-300 * {
  fill: #00BFA5 !important; }

.color-green-400 {
  color: #00A690 !important; }

.background-green-400 {
  background: #00A690 !important; }

.fill-green-400 * {
  fill: #00A690 !important; }

.color-green-500 {
  color: #00806E !important; }

.background-green-500 {
  background: #00806E !important; }

.fill-green-500 * {
  fill: #00806E !important; }

.color-yellow-100 {
  color: #FFF0CC !important; }

.background-yellow-100 {
  background: #FFF0CC !important; }

.fill-yellow-100 * {
  fill: #FFF0CC !important; }

.color-yellow-200 {
  color: #FFD166 !important; }

.background-yellow-200 {
  background: #FFD166 !important; }

.fill-yellow-200 * {
  fill: #FFD166 !important; }

.color-yellow-300 {
  color: #FFB300 !important; }

.background-yellow-300 {
  background: #FFB300 !important; }

.fill-yellow-300 * {
  fill: #FFB300 !important; }

.color-yellow-400 {
  color: #E6A100 !important; }

.background-yellow-400 {
  background: #E6A100 !important; }

.fill-yellow-400 * {
  fill: #E6A100 !important; }

.color-yellow-500 {
  color: #B37D00 !important; }

.background-yellow-500 {
  background: #B37D00 !important; }

.fill-yellow-500 * {
  fill: #B37D00 !important; }

.color-orange-100 {
  color: #FFCCBC !important; }

.background-orange-100 {
  background: #FFCCBC !important; }

.fill-orange-100 * {
  fill: #FFCCBC !important; }

.color-orange-200 {
  color: #FF8A65 !important; }

.background-orange-200 {
  background: #FF8A65 !important; }

.fill-orange-200 * {
  fill: #FF8A65 !important; }

.color-orange-300 {
  color: #FF7043 !important; }

.background-orange-300 {
  background: #FF7043 !important; }

.fill-orange-300 * {
  fill: #FF7043 !important; }

.color-orange-400 {
  color: #E64A19 !important; }

.background-orange-400 {
  background: #E64A19 !important; }

.fill-orange-400 * {
  fill: #E64A19 !important; }

.color-orange-500 {
  color: #BF360C !important; }

.background-orange-500 {
  background: #BF360C !important; }

.fill-orange-500 * {
  fill: #BF360C !important; }

.color-purple-100 {
  color: #E1BEE7 !important; }

.background-purple-100 {
  background: #E1BEE7 !important; }

.fill-purple-100 * {
  fill: #E1BEE7 !important; }

.color-purple-200 {
  color: #BA68C8 !important; }

.background-purple-200 {
  background: #BA68C8 !important; }

.fill-purple-200 * {
  fill: #BA68C8 !important; }

.color-purple-300 {
  color: #8D25AA !important; }

.background-purple-300 {
  background: #8D25AA !important; }

.fill-purple-300 * {
  fill: #8D25AA !important; }

.color-purple-400 {
  color: #6A1C9A !important; }

.background-purple-400 {
  background: #6A1C9A !important; }

.fill-purple-400 * {
  fill: #6A1C9A !important; }

.color-purple-500 {
  color: #49148B !important; }

.background-purple-500 {
  background: #49148B !important; }

.fill-purple-500 * {
  fill: #49148B !important; }

.color-blue-100 {
  color: #BBDCF7 !important; }

.background-blue-100 {
  background: #BBDCF7 !important; }

.fill-blue-100 * {
  fill: #BBDCF7 !important; }

.color-blue-200 {
  color: #41A2F0 !important; }

.background-blue-200 {
  background: #41A2F0 !important; }

.fill-blue-200 * {
  fill: #41A2F0 !important; }

.color-blue-300 {
  color: #2962FF !important; }

.background-blue-300 {
  background: #2962FF !important; }

.fill-blue-300 * {
  fill: #2962FF !important; }

.color-blue-400 {
  color: #2753CC !important; }

.background-blue-400 {
  background: #2753CC !important; }

.fill-blue-400 * {
  fill: #2753CC !important; }

.color-blue-500 {
  color: #183B99 !important; }

.background-blue-500 {
  background: #183B99 !important; }

.fill-blue-500 * {
  fill: #183B99 !important; }

.color-red-100 {
  color: #F8BBD0 !important; }

.background-red-100 {
  background: #F8BBD0 !important; }

.fill-red-100 * {
  fill: #F8BBD0 !important; }

.color-red-200 {
  color: #F06292 !important; }

.background-red-200 {
  background: #F06292 !important; }

.fill-red-200 * {
  fill: #F06292 !important; }

.color-red-300 {
  color: #F50057 !important; }

.background-red-300 {
  background: #F50057 !important; }

.fill-red-300 * {
  fill: #F50057 !important; }

.color-red-400 {
  color: #C2185B !important; }

.background-red-400 {
  background: #C2185B !important; }

.fill-red-400 * {
  fill: #C2185B !important; }

.color-red-500 {
  color: #870E4F !important; }

.background-red-500 {
  background: #870E4F !important; }

.fill-red-500 * {
  fill: #870E4F !important; }

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border: 1px solid #e3e7ea; }

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px; }

::-webkit-scrollbar-thumb {
  background: #d1dade;
  border: 3px solid #edf0f2;
  border-radius: 100px; }
  ::-webkit-scrollbar-thumb:hover {
    background: #d1dade; }
  ::-webkit-scrollbar-thumb:active {
    background: #d1dade; }

::-webkit-scrollbar-track {
  background: #edf0f2;
  border: 0px none #ffffff;
  border-radius: 0px;
  width: 14px; }
  ::-webkit-scrollbar-track:hover {
    background: #edf0f2; }
  ::-webkit-scrollbar-track:active {
    background: #edf0f2; }

::-webkit-scrollbar-corner {
  background: transparent; }

.conversable-selector-component {
  display: block;
  background: #FCFDFF;
  color: #A0AAAF;
  padding: 20px 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #E3E8EB; }
  .conversable-selector-component .conversable {
    display: flex;
    justify-content: space-between; }
    .conversable-selector-component .conversable h3 {
      margin-top: 0px;
      line-height: 42px; }
    .conversable-selector-component .conversable span {
      display: block;
      line-height: 40px;
      width: 100%;
      text-transform: capitalize; }
      .conversable-selector-component .conversable span .nama-icon {
        width: 24px;
        float: left;
        margin: 8px 8px 8px 0px;
        height: 24px; }

#nama-platform {
  display: flex;
  box-sizing: border-box;
  background-color: #FCFDFF;
  overflow: hidden;
  padding: 0px;
  margin: 0px; }

body.full-body-app {
  display: flex;
  box-sizing: border-box;
  background-color: #FCFDFF;
  overflow: hidden;
  padding: 0px;
  margin: 0px; }

.nama-button p {
  margin: 0;
  padding: 0; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

#dashboard {
  width: 100vw;
  display: flex;
  height: 100vh;
  flex-direction: row;
  flex-wrap: wrap; }
  #dashboard #nama-menu {
    height: 100%;
    position: absolute; }
  #dashboard #apps {
    background: #F6F7F8;
    max-width: 100%;
    width: 100%;
    left: 0px;
    max-height: calc(100% - 48px);
    top: 48px;
    height: 100%;
    display: block;
    position: absolute;
    overflow-x: hidden;
    overflow-y: hidden;
    min-width: 720px; }
    #dashboard #apps.white-bg {
      background-color: #fff !important; }
    #dashboard #apps .dashboard-animate {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100%;
      display: block;
      overflow-x: auto;
      overflow-y: auto; }
    #dashboard #apps.show-menu {
      max-width: calc(100% - 60px);
      width: 100%;
      left: 60px; }
      #dashboard #apps.show-menu .dashboard-animate {
        width: calc(100vw - 60px); }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.navigation-template {
  display: table;
  width: 100%;
  height: 100%;
  position: relative; }
  .navigation-template .nama-nav-cursor {
    display: block;
    height: 100%;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 15px;
    min-width: 15px;
    max-width: 30px;
    position: absolute;
    margin-left: -30px;
    left: 375px;
    margin-right: -10px;
    cursor: w-resize;
    z-index: 10000;
    opacity: 1;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    border-right: 1px solid #F6F7F8 !important; }
    .navigation-template .nama-nav-cursor::after {
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      content: "";
      display: block;
      width: 4px;
      height: 16px;
      top: 50%;
      position: absolute;
      margin-top: -60px;
      margin-left: 20px;
      border-left: 2px solid #EEF0F3;
      border-right: 2px solid #EEF0F3; }
    .navigation-template .nama-nav-cursor:hover {
      border-right: 1px solid #FF8A65 !important; }
      .navigation-template .nama-nav-cursor:hover::after {
        border-left: 2px solid #FF8A65;
        border-right: 2px solid #FF8A65; }
    .navigation-template .nama-nav-cursor.small {
      left: 115px;
      cursor: e-resize; }
  .navigation-template .nama-nav {
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    display: block;
    overflow-y: auto;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    width: 360px;
    background-color: #fff;
    border-right: 1px solid #e3e8eb;
    overflow-x: hidden; }
    .navigation-template .nama-nav.padded {
      padding: 20px; }
    .navigation-template .nama-nav .nama-button-old-list {
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease; }
    .navigation-template .nama-nav .nama-button-old-list nama-button-old nama-icon {
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    .navigation-template .nama-nav .nama-button-old-list .nama-button-old {
      width: 100%;
      margin: 8px 0px; }
      .navigation-template .nama-nav .nama-button-old-list .nama-button-old span {
        text-align: left; }
    .navigation-template .nama-nav .nama-button-old-list .nama-button-old.fill-info.icon .nama-icon {
      margin-left: 11px; }
    .navigation-template .nama-nav p {
      overflow: hidden;
      opacity: 1;
      height: auto;
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      -webkit-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out; }
    .navigation-template .nama-nav.hidden {
      width: 0px !important;
      min-width: 0px !important;
      margin-left: calc(-8.3% - 28px); }
    .navigation-template .nama-nav.small {
      width: 100px;
      height: 100%; }
      .navigation-template .nama-nav.small p {
        height: 0px;
        opacity: 0; }
      .navigation-template .nama-nav.small .nama-button-old-list {
        width: 60px; }
      .navigation-template .nama-nav.small .nama-button-old-list .nama-button-old .nama-icon:first-child {
        margin-left: 12px; }
      .navigation-template .nama-nav.small .nama-button-old-list .nama-button-old.fill-info.icon .nama-icon {
        margin-right: 22px; }
    .navigation-template .nama-nav.mouseover {
      border-right: 1px solid #FF8A65; }
  .navigation-template > .content {
    display: block;
    padding-bottom: 32px;
    margin-left: 360px !important;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    height: 100%; }
    .navigation-template > .content.small {
      margin-left: 100px !important; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.tabs-template {
  width: 100%;
  height: 100%;
  display: block; }
  .tabs-template #tabs {
    height: calc(100% - 46px); }
    .tabs-template #tabs > .content {
      display: block;
      width: 100%;
      height: 100%;
      margin-left: 0px !important; }
  .tabs-template .nama-sub-header {
    height: 44px;
    background-color: #EEF0F3;
    border-top: 1px solid #EEF0F3;
    border-bottom: 1px solid #EEF0F3;
    padding: 0px 40px;
    width: 100%;
    top: 0px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .tabs-template .nama-sub-header .links {
      width: 100%;
      height: 100%;
      display: flex; }
      .tabs-template .nama-sub-header .links right {
        flex: 1 1;
        text-align: right; }
      .tabs-template .nama-sub-header .links a,
      .tabs-template .nama-sub-header .links .nama-button-old {
        font-size: 16px;
        line-height: 20px;
        margin-top: 32px;
        letter-spacing: -0.06px;
        color: #575E62;
        text-transform: capitalize;
        text-decoration: none;
        display: table;
        border-radius: 0px;
        background-color: transparent;
        border-color: transparent;
        line-height: 41px;
        margin: 0px;
        margin-right: 32px; }
      .tabs-template .nama-sub-header .links a:hover,
      .tabs-template .nama-sub-header .links .nama-button-old:hover {
        background-color: transparent;
        border-color: transparent;
        border-bottom: 1px solid #2753CC;
        color: #252729; }
      .tabs-template .nama-sub-header .links a.active,
      .tabs-template .nama-sub-header .links .nama-button-old.active {
        background-color: transparent;
        border-color: transparent;
        border-bottom: 1px solid #2962FF;
        color: #575E62; }
      .tabs-template .nama-sub-header .links form {
        width: auto;
        flex: 2 1;
        display: flex;
        flex-direction: row-reverse;
        width: 80%; }
        .tabs-template .nama-sub-header .links form select {
          background: linear-gradient(45deg, transparent 50%, #252729 50%), linear-gradient(135deg, #252729 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
          background-position: calc(100% - 21px) calc(1em + 2px), calc(100% - 16px) calc(1em + 2px), 100% 0;
          background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
          background-repeat: no-repeat;
          background-color: transparent;
          border-color: transparent;
          text-transform: uppercase;
          width: 180px;
          float: right; }
    .tabs-template .nama-sub-header .filters {
      display: flex;
      align-items: center; }
      .tabs-template .nama-sub-header .filters > * {
        margin: 0 8px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.conversations-layout {
  display: flex;
  height: 100%; }
  .conversations-layout > .divider {
    background: #EEF0F3;
    border: 1px solid rgba(151, 151, 151, 0.153504);
    box-sizing: border-box;
    width: 20px;
    height: 100%; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.view-placeholder {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .view-placeholder h1 {
    color: #2962FF; }
  .view-placeholder h2 {
    margin-top: 16px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.insights-overview-layout {
  padding: 96px 48px 48px 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #ffffff;
  height: 100%;
  overflow: auto; }
  .insights-overview-layout .card {
    width: 320px;
    margin: 20px;
    flex-shrink: 0; }
    .insights-overview-layout .card h1 {
      margin: 0; }
    .insights-overview-layout .card .stats-radial-chart {
      display: flex;
      justify-content: center; }

.nama-button {
  padding: 12px 16px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: none;
  height: 44px;
  cursor: pointer; }
  .nama-button p {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%; }
  .nama-button .nama-icon {
    width: 20px;
    flex-shrink: 0; }
    .nama-button .nama-icon:first-child {
      margin-right: 12px; }
    .nama-button .nama-icon:last-child {
      margin-left: 12px; }
    .nama-button .nama-icon:first-child:last-child {
      margin: 0; }
  .nama-button:disabled {
    cursor: not-allowed; }
  .nama-button.primary {
    background-color: #2962ff;
    color: #ffffff;
    border: 1px solid transparent; }
    .nama-button.primary .nama-icon * {
      fill: #ffffff !important; }
    .nama-button.primary:hover {
      background-color: #2753cc; }
    .nama-button.primary:active {
      background-color: #183b99; }
    .nama-button.primary:disabled {
      background-color: #bac4df; }
  .nama-button.secondary {
    background-color: #f6f7f8;
    color: #575e62;
    border: 1px solid #e3e8eb; }
    .nama-button.secondary .nama-icon * {
      fill: #575e62 !important; }
    .nama-button.secondary:hover {
      border-color: #a0aaaf; }
    .nama-button.secondary:active {
      background-color: #41a2f0;
      color: #ffffff;
      border-color: #eef0f3; }
      .nama-button.secondary:active .nama-icon * {
        fill: #ffffff !important; }
    .nama-button.secondary:disabled {
      background-color: #d2dade;
      color: #a0aaaf;
      border-color: #f6f7f8; }
      .nama-button.secondary:disabled .nama-icon * {
        fill: #a0aaaf !important; }
  .nama-button.warning {
    background-color: #f50057;
    color: #ffffff;
    border: 1px solid #f50057; }
    .nama-button.warning .nama-icon * {
      fill: #ffffff !important; }
    .nama-button.warning:hover {
      background-color: #f06292; }
    .nama-button.warning:active {
      background-color: #c2185b; }
    .nama-button.warning:disabled {
      background-color: #d2dade;
      color: #a0aaaf;
      border-color: #f6f7f8; }
      .nama-button.warning:disabled .nama-icon * {
        fill: #a0aaaf !important; }
  .nama-button.flat {
    background-color: transparent;
    color: #35383a;
    border: 1px solid transparent; }
    .nama-button.flat .nama-icon * {
      fill: #35383a !important; }
    .nama-button.flat:hover {
      border: 1px solid #a0aaaf; }
    .nama-button.flat:active {
      background-color: #e3e8eb;
      border: 1px solid #a0aaaf; }
    .nama-button.flat:disabled {
      color: #a0aaaf;
      border: 1px solid transparent;
      background-color: transparent; }
      .nama-button.flat:disabled .nama-icon * {
        fill: #a0aaaf !important; }
  .nama-button.async {
    justify-items: center;
    position: relative; }
    .nama-button.async .nama-icon,
    .nama-button.async p {
      visibility: hidden; }
    .nama-button.async .nama-icon.loading {
      visibility: visible;
      position: absolute;
      left: calc(50% - 10px);
      margin: 0; }
    .nama-button.async .nama-icon.only-left:last-child {
      margin: 0; }

.nama-icon {
  display: block;
  width: 100%; }
  .nama-icon svg {
    width: 100%;
    display: block; }

.card-list-item {
  height: 64px;
  margin: 0 24px;
  padding: 24px 0;
  border-bottom: 1px solid #a0aaaf;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .card-list-item .value {
    font-size: 20px; }
  .card-list-item:last-child {
    border: none; }

.card-header {
  height: 64px;
  display: flex;
  align-items: center;
  background-color: #f6f7f8;
  padding: 24px;
  border-radius: 16px 16px 0 0; }
  .card-header h1 {
    text-transform: uppercase;
    color: #575e62;
    font-size: 16px;
    font-weight: 600; }
  .card-header .nama-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px; }

.card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 24px 32px 0 rgba(87, 94, 98, 0.3);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-bottom: 8px; }
  .card.dark {
    background-color: #35383a;
    color: #e3e8eb; }
    .card.dark .card-header {
      background-color: #252729; }
      .card.dark .card-header h1 {
        color: #e3e8eb; }
    .card.dark .card-list-item {
      color: #e3e8eb; }

.event-message {
  margin-left: 50px;
  display: flex;
  align-items: center; }
  .event-message .marker {
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border-radius: 50%;
    background-color: #8d25aa; }
  .event-message p {
    margin: 0;
    text-transform: uppercase;
    color: #737b80;
    font-weight: 600;
    font-size: 12px; }

.message-bubble {
  font-size: 16px;
  border: 1px solid #d2dade;
  padding: 8px 16px 8px 16px;
  max-width: 500px;
  margin: 2px 0;
  border-radius: 0 16px 16px 0; }
  .message-bubble.light {
    color: #575e62;
    background-color: #ffffff; }
  .message-bubble.dark {
    color: #ffffff;
    background-color: #575e62; }
  .message-bubble.primary {
    color: #ffffff;
    background-color: #2962ff; }
  .message-bubble:first-child {
    margin: 6px 0 2px 0;
    border-radius: 14px 16px 16px 4px; }
  .message-bubble:last-child {
    margin: 2px 0 6px 0;
    border-radius: 4px 16px 16px 14px; }
  .message-bubble:first-child:last-child {
    margin: 6px 0;
    border-radius: 16px 16px 16px 16px; }

.nama-input {
  border-radius: 4px;
  color: #575e62;
  border: 1px solid #d2dade;
  font-weight: 600;
  width: 100%; }
  .nama-input.big {
    font-size: 16px;
    padding: 12px; }
  .nama-input.medium {
    font-size: 14px;
    padding: 8px 12px; }
  .nama-input.small {
    font-size: 12px;
    padding: 6px 12px; }
  .nama-input.tiny {
    font-size: 12px;
    padding: 2px 12px; }
  .nama-input.no-border {
    border-color: transparent;
    background-color: transparent; }
  .nama-input.validate:not(.pristine):invalid {
    background-color: white;
    border-color: #f50057; }
  .nama-input.validate:not(.pristine):valid:not(.no-border) {
    background-color: white;
    border-color: #00bfa5; }
  .nama-input.validate:not(.pristine):focus {
    background-color: white;
    border-color: #2962ff;
    outline: none; }
  .nama-input:hover {
    border-color: #a0aaaf; }
  .nama-input:focus {
    background-color: white;
    border-color: #2962ff;
    outline: none; }
  .nama-input::-webkit-input-placeholder {
    color: #a0aaaf; }
  .nama-input::-moz-placeholder {
    color: #a0aaaf; }
  .nama-input:-ms-input-placeholder {
    color: #a0aaaf; }
  .nama-input::-ms-input-placeholder {
    color: #a0aaaf; }
  .nama-input::placeholder {
    color: #a0aaaf; }

.nama-dropdown {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .nama-dropdown.show {
    z-index: 10; }
    .nama-dropdown.show .nama-dropdown__list {
      display: flex; }
  .nama-dropdown .nama-dropdown__trigger {
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .nama-dropdown .nama-dropdown__list {
    display: none;
    position: absolute;
    cursor: pointer;
    background-color: #ffffff;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    overflow-x: auto;
    flex-flow: column;
    border-radius: 4px;
    border: solid 1px #eef0f3;
    overflow: hidden; }
    .nama-dropdown .nama-dropdown__list hr {
      border: solid 1px #e3e8eb; }
    .nama-dropdown .nama-dropdown__list .nama-dropdown__item {
      padding: 14px;
      font-size: 14px;
      font-weight: 600;
      color: #35383a;
      display: flex;
      align-items: center;
      white-space: nowrap; }
      .nama-dropdown .nama-dropdown__list .nama-dropdown__item:hover {
        background-color: #f6f7f8; }
      .nama-dropdown .nama-dropdown__list .nama-dropdown__item:active {
        background-color: #e3e8eb; }
      .nama-dropdown .nama-dropdown__list .nama-dropdown__item.disabled {
        background-color: #d2dade;
        color: #a0aaaf; }
      .nama-dropdown .nama-dropdown__list .nama-dropdown__item .item-label {
        flex: 1 1; }
      .nama-dropdown .nama-dropdown__list .nama-dropdown__item .nama-icon {
        width: 16px; }
        .nama-dropdown .nama-dropdown__list .nama-dropdown__item .nama-icon.selected-icon {
          margin-left: 14px;
          align-self: flex-end; }
        .nama-dropdown .nama-dropdown__list .nama-dropdown__item .nama-icon.optional-icon {
          margin-right: 14px;
          align-self: flex-start; }

.nama-avatar {
  width: 40px;
  height: 40px;
  background-color: #d2dade;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .nama-avatar .nama-icon {
    margin: 6px; }
  .nama-avatar.small {
    width: 32px;
    height: 32px; }
    .nama-avatar.small .nama-icon {
      margin: 5px; }
  .nama-avatar.large {
    width: 68px;
    height: 68px; }
    .nama-avatar.large .nama-icon {
      margin: 10px; }
  .nama-avatar img {
    width: 100%; }

.nama-date-range-picker {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0 8px; }
  .nama-date-range-picker > span {
    margin: 0 8px;
    text-transform: uppercase;
    color: #a0aaaf;
    font-weight: 600;
    font-size: 11px; }
  .nama-date-range-picker .nama-icon {
    width: 20px;
    margin-right: 8px; }
  .nama-date-range-picker .nama-input {
    width: 85px; }

.nama-dropdown.date-range-picker.show {
  background-color: #ffffff; }

.nama-dropdown.date-range-picker .nama-dropdown__list {
  border-radius: 0 0 4px 4px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

#nama-header {
  height: 48px;
  width: 100%;
  overflow: hidden;
  background: #F6F7F8;
  border-bottom: 1px solid #E3E8EB;
  box-sizing: border-box;
  padding-left: calc(80px + 8px);
  display: flex;
  flex-direction: row; }
  #nama-header .page-title {
    width: 100%;
    height: 48px; }
    #nama-header .page-title > .nama-icon {
      width: 20px;
      height: 20px;
      padding: 2px;
      margin: 12px;
      float: left; }
      #nama-header .page-title > .nama-icon svg {
        width: 20px;
        height: 20px; }
      #nama-header .page-title > .nama-icon * {
        fill: #2962FF; }
    #nama-header .page-title h3 {
      text-transform: capitalize;
      margin: 0px;
      font-weight: 300;
      line-height: 48px;
      font-size: 16px;
      width: 250px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

#nama-menu {
  z-index: 10;
  width: 60px;
  position: absolute;
  left: -60px;
  height: auto;
  background-color: #252729;
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: space-between;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  overflow: hidden; }
  #nama-menu .header {
    margin-bottom: 32px; }
    #nama-menu .header .item a,
    #nama-menu .header .item button {
      padding: 4px; }
      #nama-menu .header .item a .nama-icon,
      #nama-menu .header .item button .nama-icon {
        padding: 4px;
        width: 24px;
        height: 24px; }
        #nama-menu .header .item a .nama-icon svg,
        #nama-menu .header .item button .nama-icon svg {
          fill: #fff; }
  #nama-menu .slideInLeft {
    visibility: visible; }
    #nama-menu .slideInLeft .nama-icon {
      visibility: visible; }
  #nama-menu .content {
    flex: 1 1; }
  #nama-menu .footer {
    margin-top: 32px; }
  #nama-menu .item {
    width: 44px;
    height: 44px;
    margin: 8px;
    box-sizing: border-box;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease; }
    #nama-menu .item a.active,
    #nama-menu .item button.active {
      background-color: #41A2F0 !important; }
      #nama-menu .item a.active .nama-icon,
      #nama-menu .item button.active .nama-icon {
        fill: #252729; }
    #nama-menu .item a,
    #nama-menu .item button {
      background-color: transparent;
      border: none;
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      width: 44px;
      height: 44px;
      box-sizing: border-box;
      border-radius: 40px;
      padding: 10px;
      display: block;
      text-align: center; }
      #nama-menu .item a .nama-icon,
      #nama-menu .item button .nama-icon {
        fill: #E3E8EB;
        height: 24px;
        width: 24px;
        margin: auto;
        display: block; }
      #nama-menu .item a.hover, #nama-menu .item a:hover,
      #nama-menu .item button.hover,
      #nama-menu .item button:hover {
        background-color: #000000; }
  #nama-menu.show {
    left: 0px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-modal-courtin {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  visibility: hidden;
  background-image: radial-gradient(rgba(227, 232, 235, 0.4) 0%, rgba(160, 170, 175, 0.8) 100%);
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000; }
  .nama-modal-courtin.show {
    visibility: visible; }
  .nama-modal-courtin .out-click-handle {
    width: 100vw;
    height: 100vh;
    display: block;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1001; }
  .nama-modal-courtin .nama-modal {
    background: #FCFDFF;
    border: 1px solid #E3E8EB;
    border-radius: 8px;
    box-shadow: 0 8px 10px 0 rgba(87, 94, 98, 0.3);
    position: absolute;
    border: 1px solid #D2DADE;
    min-height: 140px;
    padding: 56px;
    box-sizing: border-box;
    display: block;
    top: 50%;
    -webkit-transform: translate3d(0px, -50%, 0px);
            transform: translate3d(0px, -50%, 0px);
    max-height: 100%;
    overflow-y: auto;
    z-index: 1100;
    max-width: 440px;
    width: calc(100% - 16px);
    left: calc(50% - 190px);
    min-width: 240px; }
    .nama-modal-courtin .nama-modal.large {
      max-width: 880px;
      width: calc(100% - 16px);
      left: calc(50% - 440px);
      min-width: 640px; }
    .nama-modal-courtin .nama-modal.small {
      border-radius: 4px; }
    .nama-modal-courtin .nama-modal h1, .nama-modal-courtin .nama-modal h2, .nama-modal-courtin .nama-modal h3, .nama-modal-courtin .nama-modal h4, .nama-modal-courtin .nama-modal h5, .nama-modal-courtin .nama-modal h6, .nama-modal-courtin .nama-modal h7, .nama-modal-courtin .nama-modal h8, .nama-modal-courtin .nama-modal hr {
      margin-top: 0px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-list {
  display: table;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px; }
  .nama-list .nama-list-item {
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 64px;
    text-decoration: none;
    color: #A0AAAF;
    justify-content: space-between;
    align-items: center;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease; }
    .nama-list .nama-list-item:nth-child(even) {
      background-color: #F6F7F8;
      border-top: 1px solid #F6F7F8;
      border-bottom: 1px solid #F6F7F8; }
    .nama-list .nama-list-item:nth-child(odd) {
      border-top: 1px solid #FCFDFF;
      border-bottom: 1px solid #FCFDFF;
      background-color: #FCFDFF; }
    .nama-list .nama-list-item .clicable {
      width: 100%;
      height: 100%;
      position: absolute; }
    .nama-list .nama-list-item .nama-avatar {
      margin-left: 24px;
      margin-right: 16px; }
    .nama-list .nama-list-item .description {
      flex: 1 1; }
      .nama-list .nama-list-item .description h1,
      .nama-list .nama-list-item .description h2,
      .nama-list .nama-list-item .description h3,
      .nama-list .nama-list-item .description h4,
      .nama-list .nama-list-item .description h5,
      .nama-list .nama-list-item .description h6,
      .nama-list .nama-list-item .description h7,
      .nama-list .nama-list-item .description h8 {
        margin-top: 0px;
        text-transform: capitalize; }
    .nama-list .nama-list-item:hover {
      border-top: 1px solid #EEF0F3;
      border-bottom: 1px solid #EEF0F3; }
      .nama-list .nama-list-item:hover .actions {
        opacity: 1; }
    .nama-list .nama-list-item .actions {
      -webkit-transition: all 400ms ease;
      transition: all 400ms ease;
      opacity: 0;
      margin-right: 24px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-accordeon {
  display: block;
  background: #FCFDFF;
  color: #A0AAAF;
  padding: 20px 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25);
  -webkit-transition: .2s box-shadow;
  transition: .2s box-shadow; }
  .nama-accordeon:hover {
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3); }
    .nama-accordeon:hover .button {
      opacity: 1;
      visibility: visible; }
  .nama-accordeon.circle_green .circle {
    background: #2BD9C2; }
  .nama-accordeon.circle_red .circle {
    background: #F06292; }
  .nama-accordeon.tag_in_production .tags .tag-content span {
    background: #FFCCBC;
    color: #BF360C; }
  .nama-accordeon.tag_test .tags .tag-content span {
    background: #E1BEE7;
    color: #49148B; }
  .nama-accordeon .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .nama-accordeon .flex-wrapper .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 10px;
      display: none; }
    .nama-accordeon .flex-wrapper .nama-avatar {
      margin-right: 16px; }
    .nama-accordeon .flex-wrapper .name {
      font-size: 40px;
      flex-grow: 1;
      display: flex;
      align-items: center; }
      .nama-accordeon .flex-wrapper .name .title {
        display: inline-block;
        vertical-align: middle;
        color: #737B80;
        font-weight: 600;
        line-height: 1em; }
      .nama-accordeon .flex-wrapper .name .subtitle {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        line-height: 1em; }
      .nama-accordeon .flex-wrapper .name .accordeon-icon nama-icon {
        -webkit-transition: .2s transform;
        transition: .2s transform;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
    .nama-accordeon .flex-wrapper .tags {
      margin-left: 10px;
      width: 120px; }
      .nama-accordeon .flex-wrapper .tags .tags-title {
        display: block;
        font-size: 12px;
        margin-bottom: 5px; }
      .nama-accordeon .flex-wrapper .tags .tag-content span {
        display: inline-block;
        background: #E1BEE7;
        color: #49148B;
        border-radius: 16px;
        font-weight: 600;
        font-size: 13px;
        padding: 5px 15px;
        margin-right: 5px;
        margin-bottom: 5px; }
    .nama-accordeon .flex-wrapper .timestamp {
      font-size: 12px;
      flex-shrink: 0; }
    .nama-accordeon .flex-wrapper .button {
      margin: 0px 0px 0px 10px;
      flex-shrink: 0;
      opacity: 0;
      visibility: hidden; }
  .nama-accordeon .description {
    margin-top: 24px;
    display: none; }
    .nama-accordeon .description .list-publish-details {
      color: #A0AAAF; }
    .nama-accordeon .description .list-publish-buttons {
      display: flex;
      justify-content: space-between; }
  .nama-accordeon.accordeon {
    cursor: pointer; }
  .nama-accordeon.opened .description {
    display: block; }
  .nama-accordeon.opened .flex-wrapper .name .accordeon-icon nama-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .nama-accordeon.opened.accordeon {
    cursor: auto; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-conversable {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding-top: 32px !important;
  margin-bottom: 32px; }
  .nama-conversable .conversable-description {
    display: block;
    width: 100%;
    float: left; }
    .nama-conversable .conversable-description .nama-avatar {
      float: left;
      margin-right: 12px; }
    .nama-conversable .conversable-description h1 {
      float: left;
      width: calc(100% - 144px);
      margin-top: 4px;
      margin-bottom: 8px; }
    .nama-conversable .conversable-description p {
      float: right;
      width: 100%; }
    .nama-conversable .conversable-description .nama-copy-value {
      margin-top: 32px; }
  .nama-conversable .dropdown {
    float: left; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-icon {
  fill: #D2DADE;
  display: block;
  width: 100%; }
  .nama-icon svg {
    width: 100%;
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-table {
  width: 100%;
  overflow-x: auto; }
  .nama-table .table-header {
    width: 100%;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#41A2F0), to(#BBDCF7));
    background: linear-gradient(to right, #41A2F0 0%, #BBDCF7 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .nama-table .table-header .item {
      padding-bottom: 0px;
      padding-top: 0px; }
      .nama-table .table-header .item .actions {
        height: 5px; }
        .nama-table .table-header .item .actions .nama-button-old {
          visibility: hidden !important; }
    .nama-table .table-header:hover .actions {
      visibility: hidden; }
    .nama-table .table-header.green-blue {
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(#2BD9C2), to(#41A2F0));
      background: linear-gradient(to right, #2BD9C2 0%, #41A2F0 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .nama-table .table-header.light-blue {
      /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, right top, from(#41A2F0), to(#BBDCF7));
      background: linear-gradient(to right, #41A2F0 0%, #BBDCF7 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
    .nama-table .table-header.clear {
      background: transparent;
      color: #A0AAAF; }
  .nama-table .content {
    border: 1px solid #F6F7F8;
    border-radius: 8px; }
    .nama-table .content .nama-table-loading {
      padding: 20px 10px;
      text-align: center;
      color: #575E62; }
      .nama-table .content .nama-table-loading .nama-icon {
        width: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        -webkit-animation: 0.3s tossing infinite;
                animation: 0.3s tossing infinite; }
        .nama-table .content .nama-table-loading .nama-icon svg {
          fill: #575E62; }
  .nama-table .item {
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    align-items: center;
    padding-right: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row; }
    .nama-table .item:hover .actions {
      visibility: visible; }
    .nama-table .item:nth-child(even) {
      background-color: #F6F7F8; }
    .nama-table .item .actions {
      display: flex;
      flex-direction: row;
      margin: 0px;
      padding: 0px;
      visibility: hidden; }
    .nama-table .item .col {
      font-family: "Source Sans Pro", "Open Sans", sans-serif;
      width: 100%;
      min-width: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 4px;
      text-align: center; }
      .nama-table .item .col.left {
        text-align: left; }
      .nama-table .item .col.right {
        text-align: right; }
      .nama-table .item .col.title {
        text-align: left;
        min-width: 180px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-dashboard-header-menu {
  display: flex; }
  .nama-dashboard-header-menu .nama-dashboard-header-user-menu {
    height: 40px;
    min-width: 40px;
    padding: 4px 16px;
    position: relative;
    display: flex;
    align-items: center; }
    .nama-dashboard-header-menu .nama-dashboard-header-user-menu .nama-avatar {
      flex-shrink: 0;
      margin-right: 5px; }
    .nama-dashboard-header-menu .nama-dashboard-header-user-menu .avatar-options {
      display: flex;
      position: absolute;
      z-index: 1000;
      top: 0px;
      right: 0px;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: 0.2s opacity, 0.1s -webkit-transform;
      transition: 0.2s opacity, 0.1s -webkit-transform;
      transition: 0.1s transform, 0.2s opacity;
      transition: 0.1s transform, 0.2s opacity, 0.1s -webkit-transform;
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 0px;
              transform-origin: 100% 0px; }
    .nama-dashboard-header-menu .nama-dashboard-header-user-menu:hover .avatar-options {
      visibility: visible;
      opacity: 1;
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
  .nama-dashboard-header-menu .nama-dashboard-header-organization {
    border-left: 1px #d2dade solid; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.chatlist .chat-item {
  font-family: "Source Sans Pro", "Open Sans", sans-serif !important;
  display: flex;
  height: 84px;
  overflow: hidden;
  background-color: #FCFDFF;
  cursor: pointer;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  position: relative;
  width: 312px; }
  .chatlist .chat-item .nama-avatar {
    margin: 8px 16px;
    flex-shrink: 0; }
    .chatlist .chat-item .nama-avatar .initials {
      background-color: #D2DADE; }
  .chatlist .chat-item:hover {
    background-color: #E3E8EB; }
  .chatlist .chat-item.selected {
    background-color: #A0AAAF; }
    .chatlist .chat-item.selected .content h1 {
      color: #F6F7F8; }
    .chatlist .chat-item.selected .content p {
      color: #F6F7F8; }
    .chatlist .chat-item.selected .content footer {
      color: #F6F7F8; }
    .chatlist .chat-item.selected .timestamp {
      color: #F6F7F8; }
  .chatlist .chat-item .content {
    flex-grow: 1;
    display: flex;
    flex-flow: column; }
    .chatlist .chat-item .content h1 {
      padding-top: 8px;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: #35383A;
      display: block;
      line-height: 16px; }
    .chatlist .chat-item .content p {
      flex-grow: 1;
      font-size: 14px;
      font-weight: normal;
      color: #575E62;
      text-overflow: ellipsis;
      padding: 0;
      margin: 0;
      display: block;
      overflow: hidden;
      white-space: pre-line;
      word-break: break-word;
      line-height: 16px; }
    .chatlist .chat-item .content footer {
      font-size: 12px;
      color: #737B80;
      padding-top: 4px;
      display: block;
      line-height: 14px;
      padding-bottom: 8px; }
  .chatlist .chat-item .timestamp {
    font-size: 14px;
    line-height: 14px;
    padding: 8px 8px 0 0;
    width: 52px;
    color: #575E62;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: right; }

.chatlist {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  flex-shrink: 0; }
  .chatlist > * {
    flex-shrink: 0; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-button-old.dropdown,
nama-button-old.dropdown {
  overflow: visible;
  position: relative; }
  .nama-button-old.dropdown .nama-icon,
  .nama-button-old.dropdown span,
  nama-button-old.dropdown .nama-icon,
  nama-button-old.dropdown span {
    float: left; }
  .nama-button-old.dropdown:hover .subactions,
  nama-button-old.dropdown:hover .subactions {
    display: flex;
    justify-content: center; }
  .nama-button-old.dropdown.huge .subactions,
  nama-button-old.dropdown.huge .subactions {
    margin-top: 52px; }
  .nama-button-old.dropdown.big .subactions,
  nama-button-old.dropdown.big .subactions {
    margin-top: 40px; }
  .nama-button-old.dropdown.medium .subactions,
  nama-button-old.dropdown.medium .subactions {
    margin-top: 32px; }
  .nama-button-old.dropdown.small .subactions,
  nama-button-old.dropdown.small .subactions {
    margin-top: 28px; }
  .nama-button-old.dropdown.tiny .subactions,
  nama-button-old.dropdown.tiny .subactions {
    margin-top: 20px; }
  .nama-button-old.dropdown .subactions,
  nama-button-old.dropdown .subactions {
    background-color: #FCFDFF;
    border: 1px solid #F6F7F8;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3);
    margin-top: 32px;
    display: none;
    position: absolute;
    flex-flow: column;
    z-index: 10000000;
    right: 0px;
    top: 3px; }
    .nama-button-old.dropdown .subactions .nama-button-old,
    nama-button-old.dropdown .subactions .nama-button-old {
      margin: 0px !important; }

nama-button-old,
.nama-button-old {
  font-weight: 400;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 4px;
  box-sizing: border-box;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F6F7F8;
  border: 1px solid #F6F7F8;
  color: #737B80; }
  nama-button-old span,
  .nama-button-old span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  nama-button-old.avatar,
  .nama-button-old.avatar {
    padding-left: 8px !important; }
    nama-button-old.avatar .nama-avatar,
    .nama-button-old.avatar .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px; }
    nama-button-old.avatar span,
    .nama-button-old.avatar span {
      float: left;
      width: auto; }
    nama-button-old.avatar .nama-icon,
    .nama-button-old.avatar .nama-icon {
      margin-right: 8px;
      float: left; }
  nama-button-old.sub-menu-item,
  .nama-button-old.sub-menu-item {
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    letter-spacing: -0.06px;
    color: #575E62;
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 32px;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent; }
    nama-button-old.sub-menu-item:hover,
    .nama-button-old.sub-menu-item:hover {
      border-bottom: 1px solid #2753CC;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: #252729; }
    nama-button-old.sub-menu-item.active,
    .nama-button-old.sub-menu-item.active {
      border-bottom: 1px solid #2962FF;
      color: #575E62;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent; }
  nama-button-old.expanded,
  .nama-button-old.expanded {
    width: calc(100% - 8px); }
  nama-button-old.capitalize,
  .nama-button-old.capitalize {
    text-transform: capitalize; }
  nama-button-old.full,
  .nama-button-old.full {
    padding: 0px 32px; }
  nama-button-old.padded,
  .nama-button-old.padded {
    padding: 0px 16px; }
  nama-button-old.compact,
  .nama-button-old.compact {
    padding: 0px; }
  nama-button-old.icon .nama-icon,
  .nama-button-old.icon .nama-icon {
    display: inherit;
    margin-left: 0px; }
  nama-button-old.icon.left span,
  .nama-button-old.icon.left span {
    float: right; }
  nama-button-old.icon.left .nama-icon,
  .nama-button-old.icon.left .nama-icon {
    margin-right: 8px;
    float: left; }
  nama-button-old.icon.right span,
  .nama-button-old.icon.right span {
    float: left; }
  nama-button-old.icon.right .nama-icon,
  .nama-button-old.icon.right .nama-icon {
    margin-left: 8px;
    float: right; }
  nama-button-old.icon.center .nama-icon,
  .nama-button-old.icon.center .nama-icon {
    margin-left: auto;
    float: none;
    margin-right: auto; }
  nama-button-old span,
  .nama-button-old span {
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 34px;
    display: table; }
  nama-button-old.avatar span,
  .nama-button-old.avatar span {
    padding-right: 8px;
    width: calc(100% - 114px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    width: 20px;
    margin: 16px;
    margin-top: 7px;
    height: 20px; }
  nama-button-old.nama-submit,
  .nama-button-old.nama-submit {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 34px;
    height: 34px; }
  nama-button-old.huge,
  .nama-button-old.huge {
    height: 56px;
    font-size: 16px;
    line-height: 52px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.huge span,
    .nama-button-old.huge span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 54px;
      display: table; }
    nama-button-old.huge.avatar span,
    .nama-button-old.huge.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.huge .nama-icon,
    .nama-button-old.huge .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 17px;
      height: 20px; }
  nama-button-old.nama-submit.huge,
  .nama-button-old.nama-submit.huge {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 56px;
    height: 56px; }
  nama-button-old.big,
  .nama-button-old.big {
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.big span,
    .nama-button-old.big span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 42px;
      display: table; }
    nama-button-old.big.avatar span,
    .nama-button-old.big.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.big .nama-icon,
    .nama-button-old.big .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 11px;
      height: 20px; }
  nama-button-old.nama-submit.big,
  .nama-button-old.nama-submit.big {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 44px;
    height: 44px; }
  nama-button-old.medium,
  .nama-button-old.medium {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.medium span,
    .nama-button-old.medium span {
      width: calc(100% - 40px);
      font-size: 14px;
      line-height: 34px;
      display: table; }
    nama-button-old.medium.avatar span,
    .nama-button-old.medium.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.medium .nama-icon,
    .nama-button-old.medium .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 7px;
      height: 20px; }
  nama-button-old.nama-submit.medium,
  .nama-button-old.nama-submit.medium {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 36px;
    height: 36px; }
  nama-button-old.small,
  .nama-button-old.small {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.small span,
    .nama-button-old.small span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 30px;
      display: table; }
    nama-button-old.small.avatar span,
    .nama-button-old.small.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.small .nama-icon,
    .nama-button-old.small .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 7px;
      height: 16px; }
  nama-button-old.nama-submit.small,
  .nama-button-old.nama-submit.small {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    height: 32px; }
  nama-button-old.tiny,
  .nama-button-old.tiny {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.tiny span,
    .nama-button-old.tiny span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 22px;
      display: table; }
    nama-button-old.tiny.avatar span,
    .nama-button-old.tiny.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.tiny .nama-icon,
    .nama-button-old.tiny .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 3px;
      height: 16px; }
  nama-button-old.nama-submit.tiny,
  .nama-button-old.nama-submit.tiny {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    height: 24px; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    fill: #737B80; }
  nama-button-old:hover, nama-button-old.hover,
  .nama-button-old:hover,
  .nama-button-old.hover {
    background-color: #F6F7F8;
    border: 1px solid #A0AAAF;
    color: #575E62; }
    nama-button-old:hover .nama-icon, nama-button-old.hover .nama-icon,
    .nama-button-old:hover .nama-icon,
    .nama-button-old.hover .nama-icon {
      fill: #575E62; }
  nama-button-old.selected,
  .nama-button-old.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    nama-button-old.selected .nama-icon,
    .nama-button-old.selected .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    background-color: #F6F7F8;
    color: #575E62;
    border: 1px solid #F6F7F8;
    outline: none; }
    nama-button-old:focus .nama-icon, nama-button-old.focus .nama-icon,
    .nama-button-old:focus .nama-icon,
    .nama-button-old.focus .nama-icon {
      fill: #575E62 !important; }
  nama-button-old:active, nama-button-old.active,
  .nama-button-old:active,
  .nama-button-old.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    nama-button-old:active .nama-icon, nama-button-old.active .nama-icon,
    .nama-button-old:active .nama-icon,
    .nama-button-old.active .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:disabled, nama-button-old.disabled,
  .nama-button-old:disabled,
  .nama-button-old.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    nama-button-old:disabled .nama-icon, nama-button-old.disabled .nama-icon,
    .nama-button-old:disabled .nama-icon,
    .nama-button-old.disabled .nama-icon {
      fill: #A0AAAF !important; }
  nama-button-old.white,
  .nama-button-old.white {
    background-color: #FCFDFF;
    border: 1px solid #FCFDFF;
    color: #737B80; }
    nama-button-old.white .nama-icon,
    .nama-button-old.white .nama-icon {
      fill: #737B80; }
    nama-button-old.white:hover, nama-button-old.white.hover,
    .nama-button-old.white:hover,
    .nama-button-old.white.hover {
      background-color: #F6F7F8;
      border: 1px solid #F6F7F8;
      color: #575E62; }
      nama-button-old.white:hover .nama-icon, nama-button-old.white.hover .nama-icon,
      .nama-button-old.white:hover .nama-icon,
      .nama-button-old.white.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.white.selected,
    .nama-button-old.white.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white.selected .nama-icon,
      .nama-button-old.white.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:focus, nama-button-old.white.focus,
    .nama-button-old.white:focus,
    .nama-button-old.white.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.white:focus .nama-icon, nama-button-old.white.focus .nama-icon,
      .nama-button-old.white:focus .nama-icon,
      .nama-button-old.white.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.white:active, nama-button-old.white.active,
    .nama-button-old.white:active,
    .nama-button-old.white.active {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white:active .nama-icon, nama-button-old.white.active .nama-icon,
      .nama-button-old.white:active .nama-icon,
      .nama-button-old.white.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:disabled, nama-button-old.white.disabled,
    .nama-button-old.white:disabled,
    .nama-button-old.white.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.white:disabled .nama-icon, nama-button-old.white.disabled .nama-icon,
      .nama-button-old.white:disabled .nama-icon,
      .nama-button-old.white.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.clear,
  .nama-button-old.clear {
    background-color: transparent;
    border: 1px solid transparent;
    color: #737B80; }
    nama-button-old.clear .nama-icon,
    .nama-button-old.clear .nama-icon {
      fill: #737B80; }
    nama-button-old.clear:hover, nama-button-old.clear.hover,
    .nama-button-old.clear:hover,
    .nama-button-old.clear.hover {
      background-color: transparent;
      border: 1px solid transparent;
      color: #575E62; }
      nama-button-old.clear:hover .nama-icon, nama-button-old.clear.hover .nama-icon,
      .nama-button-old.clear:hover .nama-icon,
      .nama-button-old.clear.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.clear.selected,
    .nama-button-old.clear.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.clear.selected .nama-icon,
      .nama-button-old.clear.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.clear:focus, nama-button-old.clear.focus,
    .nama-button-old.clear:focus,
    .nama-button-old.clear.focus {
      background-color: transparent;
      color: #737B80;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:focus .nama-icon, nama-button-old.clear.focus .nama-icon,
      .nama-button-old.clear:focus .nama-icon,
      .nama-button-old.clear.focus .nama-icon {
        fill: #737B80 !important; }
    nama-button-old.clear:active, nama-button-old.clear.active,
    .nama-button-old.clear:active,
    .nama-button-old.clear.active {
      background-color: transparent;
      color: #183B99;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:active .nama-icon, nama-button-old.clear.active .nama-icon,
      .nama-button-old.clear:active .nama-icon,
      .nama-button-old.clear.active .nama-icon {
        fill: #183B99 !important; }
    nama-button-old.clear:disabled, nama-button-old.clear.disabled,
    .nama-button-old.clear:disabled,
    .nama-button-old.clear.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
      .nama-button-old.clear:disabled .nama-icon,
      .nama-button-old.clear.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.default,
  .nama-button-old.default {
    background-color: #F6F7F8;
    border: 1px solid #F6F7F8;
    color: #737B80; }
    nama-button-old.default .nama-icon,
    .nama-button-old.default .nama-icon {
      fill: #737B80; }
    nama-button-old.default:hover, nama-button-old.default.hover,
    .nama-button-old.default:hover,
    .nama-button-old.default.hover {
      background-color: #F6F7F8;
      border: 1px solid #A0AAAF;
      color: #575E62; }
      nama-button-old.default:hover .nama-icon, nama-button-old.default.hover .nama-icon,
      .nama-button-old.default:hover .nama-icon,
      .nama-button-old.default.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.default.selected,
    .nama-button-old.default.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.default.selected .nama-icon,
      .nama-button-old.default.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:focus, nama-button-old.default.focus,
    .nama-button-old.default:focus,
    .nama-button-old.default.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.default:focus .nama-icon, nama-button-old.default.focus .nama-icon,
      .nama-button-old.default:focus .nama-icon,
      .nama-button-old.default.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.default:active, nama-button-old.default.active,
    .nama-button-old.default:active,
    .nama-button-old.default.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.default:active .nama-icon, nama-button-old.default.active .nama-icon,
      .nama-button-old.default:active .nama-icon,
      .nama-button-old.default.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:disabled, nama-button-old.default.disabled,
    .nama-button-old.default:disabled,
    .nama-button-old.default.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.default:disabled .nama-icon, nama-button-old.default.disabled .nama-icon,
      .nama-button-old.default:disabled .nama-icon,
      .nama-button-old.default.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.primary,
  .nama-button-old.primary {
    background-color: #2962FF;
    border: 1px solid #2962FF;
    color: #ffffff; }
    nama-button-old.primary .nama-icon,
    .nama-button-old.primary .nama-icon {
      fill: #ffffff; }
    nama-button-old.primary:hover, nama-button-old.primary.hover,
    .nama-button-old.primary:hover,
    .nama-button-old.primary.hover {
      background-color: #41A2F0;
      border: 1px solid #41A2F0;
      color: #ffffff; }
      nama-button-old.primary:hover .nama-icon, nama-button-old.primary.hover .nama-icon,
      .nama-button-old.primary:hover .nama-icon,
      .nama-button-old.primary.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.primary.selected,
    .nama-button-old.primary.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.primary.selected .nama-icon,
      .nama-button-old.primary.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:focus, nama-button-old.primary.focus,
    .nama-button-old.primary:focus,
    .nama-button-old.primary.focus {
      background-color: #2962FF;
      color: #ffffff;
      border: 1px solid #2962FF;
      outline: none; }
      nama-button-old.primary:focus .nama-icon, nama-button-old.primary.focus .nama-icon,
      .nama-button-old.primary:focus .nama-icon,
      .nama-button-old.primary.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:active, nama-button-old.primary.active,
    .nama-button-old.primary:active,
    .nama-button-old.primary.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.primary:active .nama-icon, nama-button-old.primary.active .nama-icon,
      .nama-button-old.primary:active .nama-icon,
      .nama-button-old.primary.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:disabled, nama-button-old.primary.disabled,
    .nama-button-old.primary:disabled,
    .nama-button-old.primary.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.primary:disabled .nama-icon, nama-button-old.primary.disabled .nama-icon,
      .nama-button-old.primary:disabled .nama-icon,
      .nama-button-old.primary.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.info,
  .nama-button-old.info {
    background-color: #8D25AA;
    border: 1px solid #8D25AA;
    color: #ffffff; }
    nama-button-old.info .nama-icon,
    .nama-button-old.info .nama-icon {
      fill: #ffffff; }
    nama-button-old.info:hover, nama-button-old.info.hover,
    .nama-button-old.info:hover,
    .nama-button-old.info.hover {
      background-color: #BA68C8;
      border: 1px solid #BA68C8;
      color: #ffffff; }
      nama-button-old.info:hover .nama-icon, nama-button-old.info.hover .nama-icon,
      .nama-button-old.info:hover .nama-icon,
      .nama-button-old.info.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.info.selected,
    .nama-button-old.info.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.info.selected .nama-icon,
      .nama-button-old.info.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:focus, nama-button-old.info.focus,
    .nama-button-old.info:focus,
    .nama-button-old.info.focus {
      background-color: #8D25AA;
      color: #ffffff;
      border: 1px solid #8D25AA;
      outline: none; }
      nama-button-old.info:focus .nama-icon, nama-button-old.info.focus .nama-icon,
      .nama-button-old.info:focus .nama-icon,
      .nama-button-old.info.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:active, nama-button-old.info.active,
    .nama-button-old.info:active,
    .nama-button-old.info.active {
      background-color: #49148B;
      color: #ffffff;
      border: 1px solid #49148B;
      outline: none; }
      nama-button-old.info:active .nama-icon, nama-button-old.info.active .nama-icon,
      .nama-button-old.info:active .nama-icon,
      .nama-button-old.info.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:disabled, nama-button-old.info.disabled,
    .nama-button-old.info:disabled,
    .nama-button-old.info.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.info:disabled .nama-icon, nama-button-old.info.disabled .nama-icon,
      .nama-button-old.info:disabled .nama-icon,
      .nama-button-old.info.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.success,
  .nama-button-old.success {
    background-color: #00BFA5;
    border: 1px solid #00BFA5;
    color: #ffffff; }
    nama-button-old.success .nama-icon,
    .nama-button-old.success .nama-icon {
      fill: #ffffff; }
    nama-button-old.success:hover, nama-button-old.success.hover,
    .nama-button-old.success:hover,
    .nama-button-old.success.hover {
      background-color: #2BD9C2;
      border: 1px solid #2BD9C2;
      color: #ffffff; }
      nama-button-old.success:hover .nama-icon, nama-button-old.success.hover .nama-icon,
      .nama-button-old.success:hover .nama-icon,
      .nama-button-old.success.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.success.selected,
    .nama-button-old.success.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.success.selected .nama-icon,
      .nama-button-old.success.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:focus, nama-button-old.success.focus,
    .nama-button-old.success:focus,
    .nama-button-old.success.focus {
      background-color: #00BFA5;
      color: #ffffff;
      border: 1px solid #00BFA5;
      outline: none; }
      nama-button-old.success:focus .nama-icon, nama-button-old.success.focus .nama-icon,
      .nama-button-old.success:focus .nama-icon,
      .nama-button-old.success.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:active, nama-button-old.success.active,
    .nama-button-old.success:active,
    .nama-button-old.success.active {
      background-color: #00806E;
      color: #ffffff;
      border: 1px solid #00806E;
      outline: none; }
      nama-button-old.success:active .nama-icon, nama-button-old.success.active .nama-icon,
      .nama-button-old.success:active .nama-icon,
      .nama-button-old.success.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:disabled, nama-button-old.success.disabled,
    .nama-button-old.success:disabled,
    .nama-button-old.success.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.success:disabled .nama-icon, nama-button-old.success.disabled .nama-icon,
      .nama-button-old.success:disabled .nama-icon,
      .nama-button-old.success.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.warning,
  .nama-button-old.warning {
    background-color: #FFB300;
    border: 1px solid #FFB300;
    color: #ffffff; }
    nama-button-old.warning .nama-icon,
    .nama-button-old.warning .nama-icon {
      fill: #ffffff; }
    nama-button-old.warning:hover, nama-button-old.warning.hover,
    .nama-button-old.warning:hover,
    .nama-button-old.warning.hover {
      background-color: #FFD166;
      border: 1px solid #FFD166;
      color: #ffffff; }
      nama-button-old.warning:hover .nama-icon, nama-button-old.warning.hover .nama-icon,
      .nama-button-old.warning:hover .nama-icon,
      .nama-button-old.warning.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.warning.selected,
    .nama-button-old.warning.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.warning.selected .nama-icon,
      .nama-button-old.warning.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:focus, nama-button-old.warning.focus,
    .nama-button-old.warning:focus,
    .nama-button-old.warning.focus {
      background-color: #FFB300;
      color: #ffffff;
      border: 1px solid #FFB300;
      outline: none; }
      nama-button-old.warning:focus .nama-icon, nama-button-old.warning.focus .nama-icon,
      .nama-button-old.warning:focus .nama-icon,
      .nama-button-old.warning.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:active, nama-button-old.warning.active,
    .nama-button-old.warning:active,
    .nama-button-old.warning.active {
      background-color: #B37D00;
      color: #ffffff;
      border: 1px solid #B37D00;
      outline: none; }
      nama-button-old.warning:active .nama-icon, nama-button-old.warning.active .nama-icon,
      .nama-button-old.warning:active .nama-icon,
      .nama-button-old.warning.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:disabled, nama-button-old.warning.disabled,
    .nama-button-old.warning:disabled,
    .nama-button-old.warning.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.warning:disabled .nama-icon, nama-button-old.warning.disabled .nama-icon,
      .nama-button-old.warning:disabled .nama-icon,
      .nama-button-old.warning.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.danger,
  .nama-button-old.danger {
    background-color: #F50057;
    border: 1px solid #F50057;
    color: #ffffff; }
    nama-button-old.danger .nama-icon,
    .nama-button-old.danger .nama-icon {
      fill: #ffffff; }
    nama-button-old.danger:hover, nama-button-old.danger.hover,
    .nama-button-old.danger:hover,
    .nama-button-old.danger.hover {
      background-color: #F06292;
      border: 1px solid #F06292;
      color: #ffffff; }
      nama-button-old.danger:hover .nama-icon, nama-button-old.danger.hover .nama-icon,
      .nama-button-old.danger:hover .nama-icon,
      .nama-button-old.danger.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.danger.selected,
    .nama-button-old.danger.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.danger.selected .nama-icon,
      .nama-button-old.danger.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:focus, nama-button-old.danger.focus,
    .nama-button-old.danger:focus,
    .nama-button-old.danger.focus {
      background-color: #F50057;
      color: #ffffff;
      border: 1px solid #F50057;
      outline: none; }
      nama-button-old.danger:focus .nama-icon, nama-button-old.danger.focus .nama-icon,
      .nama-button-old.danger:focus .nama-icon,
      .nama-button-old.danger.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:active, nama-button-old.danger.active,
    .nama-button-old.danger:active,
    .nama-button-old.danger.active {
      background-color: #870E4F;
      color: #ffffff;
      border: 1px solid #870E4F;
      outline: none; }
      nama-button-old.danger:active .nama-icon, nama-button-old.danger.active .nama-icon,
      .nama-button-old.danger:active .nama-icon,
      .nama-button-old.danger.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:disabled, nama-button-old.danger.disabled,
    .nama-button-old.danger:disabled,
    .nama-button-old.danger.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.danger:disabled .nama-icon, nama-button-old.danger.disabled .nama-icon,
      .nama-button-old.danger:disabled .nama-icon,
      .nama-button-old.danger.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.icon-white .nama-icon,
  .nama-button-old.icon-white .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white:hover .nama-icon, nama-button-old.icon-white.hover .nama-icon,
  .nama-button-old.icon-white:hover .nama-icon,
  .nama-button-old.icon-white.hover .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white.selected .nama-icon,
  .nama-button-old.icon-white.selected .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:focus .nama-icon, nama-button-old.icon-white.focus .nama-icon,
  .nama-button-old.icon-white:focus .nama-icon,
  .nama-button-old.icon-white.focus .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:active .nama-icon, nama-button-old.icon-white.active .nama-icon,
  .nama-button-old.icon-white:active .nama-icon,
  .nama-button-old.icon-white.active .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:disabled .nama-icon, nama-button-old.icon-white.disabled .nama-icon,
  .nama-button-old.icon-white:disabled .nama-icon,
  .nama-button-old.icon-white.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-clear .nama-icon,
  .nama-button-old.icon-clear .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear:hover .nama-icon, nama-button-old.icon-clear.hover .nama-icon,
  .nama-button-old.icon-clear:hover .nama-icon,
  .nama-button-old.icon-clear.hover .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear.selected .nama-icon,
  .nama-button-old.icon-clear.selected .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:focus .nama-icon, nama-button-old.icon-clear.focus .nama-icon,
  .nama-button-old.icon-clear:focus .nama-icon,
  .nama-button-old.icon-clear.focus .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:active .nama-icon, nama-button-old.icon-clear.active .nama-icon,
  .nama-button-old.icon-clear:active .nama-icon,
  .nama-button-old.icon-clear.active .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:disabled .nama-icon, nama-button-old.icon-clear.disabled .nama-icon,
  .nama-button-old.icon-clear:disabled .nama-icon,
  .nama-button-old.icon-clear.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-default .nama-icon,
  .nama-button-old.icon-default .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default:hover .nama-icon, nama-button-old.icon-default.hover .nama-icon,
  .nama-button-old.icon-default:hover .nama-icon,
  .nama-button-old.icon-default.hover .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default.selected .nama-icon,
  .nama-button-old.icon-default.selected .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:focus .nama-icon, nama-button-old.icon-default.focus .nama-icon,
  .nama-button-old.icon-default:focus .nama-icon,
  .nama-button-old.icon-default.focus .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:active .nama-icon, nama-button-old.icon-default.active .nama-icon,
  .nama-button-old.icon-default:active .nama-icon,
  .nama-button-old.icon-default.active .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:disabled .nama-icon, nama-button-old.icon-default.disabled .nama-icon,
  .nama-button-old.icon-default:disabled .nama-icon,
  .nama-button-old.icon-default.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-primary .nama-icon,
  .nama-button-old.icon-primary .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary:hover .nama-icon, nama-button-old.icon-primary.hover .nama-icon,
  .nama-button-old.icon-primary:hover .nama-icon,
  .nama-button-old.icon-primary.hover .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary.selected .nama-icon,
  .nama-button-old.icon-primary.selected .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:focus .nama-icon, nama-button-old.icon-primary.focus .nama-icon,
  .nama-button-old.icon-primary:focus .nama-icon,
  .nama-button-old.icon-primary.focus .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:active .nama-icon, nama-button-old.icon-primary.active .nama-icon,
  .nama-button-old.icon-primary:active .nama-icon,
  .nama-button-old.icon-primary.active .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:disabled .nama-icon, nama-button-old.icon-primary.disabled .nama-icon,
  .nama-button-old.icon-primary:disabled .nama-icon,
  .nama-button-old.icon-primary.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-info .nama-icon,
  .nama-button-old.icon-info .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info:hover .nama-icon, nama-button-old.icon-info.hover .nama-icon,
  .nama-button-old.icon-info:hover .nama-icon,
  .nama-button-old.icon-info.hover .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info.selected .nama-icon,
  .nama-button-old.icon-info.selected .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:focus .nama-icon, nama-button-old.icon-info.focus .nama-icon,
  .nama-button-old.icon-info:focus .nama-icon,
  .nama-button-old.icon-info.focus .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:active .nama-icon, nama-button-old.icon-info.active .nama-icon,
  .nama-button-old.icon-info:active .nama-icon,
  .nama-button-old.icon-info.active .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:disabled .nama-icon, nama-button-old.icon-info.disabled .nama-icon,
  .nama-button-old.icon-info:disabled .nama-icon,
  .nama-button-old.icon-info.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-success .nama-icon,
  .nama-button-old.icon-success .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success:hover .nama-icon, nama-button-old.icon-success.hover .nama-icon,
  .nama-button-old.icon-success:hover .nama-icon,
  .nama-button-old.icon-success.hover .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success.selected .nama-icon,
  .nama-button-old.icon-success.selected .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:focus .nama-icon, nama-button-old.icon-success.focus .nama-icon,
  .nama-button-old.icon-success:focus .nama-icon,
  .nama-button-old.icon-success.focus .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:active .nama-icon, nama-button-old.icon-success.active .nama-icon,
  .nama-button-old.icon-success:active .nama-icon,
  .nama-button-old.icon-success.active .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:disabled .nama-icon, nama-button-old.icon-success.disabled .nama-icon,
  .nama-button-old.icon-success:disabled .nama-icon,
  .nama-button-old.icon-success.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-warning .nama-icon,
  .nama-button-old.icon-warning .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning:hover .nama-icon, nama-button-old.icon-warning.hover .nama-icon,
  .nama-button-old.icon-warning:hover .nama-icon,
  .nama-button-old.icon-warning.hover .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning.selected .nama-icon,
  .nama-button-old.icon-warning.selected .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:focus .nama-icon, nama-button-old.icon-warning.focus .nama-icon,
  .nama-button-old.icon-warning:focus .nama-icon,
  .nama-button-old.icon-warning.focus .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:active .nama-icon, nama-button-old.icon-warning.active .nama-icon,
  .nama-button-old.icon-warning:active .nama-icon,
  .nama-button-old.icon-warning.active .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:disabled .nama-icon, nama-button-old.icon-warning.disabled .nama-icon,
  .nama-button-old.icon-warning:disabled .nama-icon,
  .nama-button-old.icon-warning.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-danger .nama-icon,
  .nama-button-old.icon-danger .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger:hover .nama-icon, nama-button-old.icon-danger.hover .nama-icon,
  .nama-button-old.icon-danger:hover .nama-icon,
  .nama-button-old.icon-danger.hover .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger.selected .nama-icon,
  .nama-button-old.icon-danger.selected .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:focus .nama-icon, nama-button-old.icon-danger.focus .nama-icon,
  .nama-button-old.icon-danger:focus .nama-icon,
  .nama-button-old.icon-danger.focus .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:active .nama-icon, nama-button-old.icon-danger.active .nama-icon,
  .nama-button-old.icon-danger:active .nama-icon,
  .nama-button-old.icon-danger.active .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:disabled .nama-icon, nama-button-old.icon-danger.disabled .nama-icon,
  .nama-button-old.icon-danger:disabled .nama-icon,
  .nama-button-old.icon-danger.disabled .nama-icon {
    fill: background !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:focus, nama-button-old.clear.focus, nama-button-old.default:focus, nama-button-old.default.focus,
  .nama-button-old.clear:focus,
  .nama-button-old.clear.focus,
  .nama-button-old.default:focus,
  .nama-button-old.default.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:disabled, nama-button-old.clear.disabled,
  .nama-button-old.clear:disabled,
  .nama-button-old.clear.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #E3E8EB;
    border: 1px solid transparent;
    outline: none; }
    nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
    .nama-button-old.clear:disabled .nama-icon,
    .nama-button-old.clear.disabled .nama-icon {
      fill: #E3E8EB !important; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.chat-view {
  height: 100%;
  width: 100%;
  background-color: #ffffff; }
  .chat-view .content .fill-top {
    flex: 1 1; }
  .chat-view .content .nama-button-old {
    flex-shrink: 0; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.message-group {
  display: flex;
  align-items: baseline; }
  .message-group .timestamp {
    padding-top: 12px;
    margin-right: 16px;
    font-size: 13px;
    color: #737B80; }
  .message-group .messages {
    display: flex;
    flex-flow: column;
    align-items: flex-start; }

.filters .nama-button {
  height: 32px;
  padding: 4px 16px; }
  .filters .nama-button p {
    font-weight: 600;
    font-size: 12px; }
  .filters .nama-button .nama-icon {
    width: 16px; }

.filters .nama-dropdown__list {
  right: 0; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-dropfile-success {
  text-align: center;
  overflow: hidden; }
  .nama-dropfile-success .nama-icon {
    width: 64px;
    height: 64px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 8px;
    outline: none; }
  .nama-dropfile-success p {
    margin-bottom: 5px; }
  .nama-dropfile-success h3,
  .nama-dropfile-success p {
    text-align: center; }

.nama-dropfile {
  outline: none; }
  .nama-dropfile .nama-icon {
    width: 64px;
    height: 64px;
    margin-bottom: 32px;
    margin: auto;
    outline: none; }
  .nama-dropfile .nama-input {
    -moz-appearance: none;
         appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-transition: border-color 400ms ease;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    .nama-dropfile .nama-input::-webkit-inner-spin-button, .nama-dropfile .nama-input::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    .nama-dropfile .nama-input:focus, .nama-dropfile .nama-input.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      .nama-dropfile .nama-input:focus nama-icon svg, .nama-dropfile .nama-input.focus nama-icon svg {
        fill: #41A2F0 !important; }
        .nama-dropfile .nama-input:focus nama-icon svg *, .nama-dropfile .nama-input.focus nama-icon svg * {
          fill: #41A2F0 !important; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-avatar {
  display: block;
  border-radius: 50%;
  margin: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden; }
  .nama-avatar .initials {
    border-radius: 90px;
    float: right;
    width: 100%;
    height: 100%;
    font-weight: 400;
    background-color: #A0AAAF;
    color: #35383A;
    text-align: center;
    font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  .nama-avatar.big {
    width: 68px;
    height: 68px; }
    .nama-avatar.big .initials {
      line-height: 68px; }
  .nama-avatar.small {
    width: 32px;
    height: 32px; }
    .nama-avatar.small .initials {
      line-height: 32px; }
  .nama-avatar.default {
    width: 40px;
    height: 40px; }
    .nama-avatar.default .initials {
      line-height: 40px; }
  .nama-avatar > img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .nama-avatar .nama-icon {
    padding: 8px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

fieldset {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  fieldset textarea {
    height: 80px !important; }
  fieldset label {
    color: #737B80;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    display: block;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    -moz-appearance: none;
         appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-transition: border-color 400ms ease;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    fieldset input::-webkit-inner-spin-button, fieldset input::-webkit-clear-button,
    fieldset select::-webkit-inner-spin-button,
    fieldset select::-webkit-clear-button,
    fieldset textarea::-webkit-inner-spin-button,
    fieldset textarea::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    fieldset input::-webkit-input-placeholder, fieldset select::-webkit-input-placeholder, fieldset textarea::-webkit-input-placeholder {
      color: #A0AAAF; }
    fieldset input::-moz-placeholder, fieldset select::-moz-placeholder, fieldset textarea::-moz-placeholder {
      color: #A0AAAF; }
    fieldset input::-ms-input-placeholder, fieldset select::-ms-input-placeholder, fieldset textarea::-ms-input-placeholder {
      color: #A0AAAF; }
    fieldset input::placeholder,
    fieldset select::placeholder,
    fieldset textarea::placeholder {
      color: #A0AAAF; }
    fieldset input.compact,
    fieldset select.compact,
    fieldset textarea.compact {
      font-size: 13px;
      height: 36px;
      padding: 4px 16px; }
    fieldset input.transparent,
    fieldset select.transparent,
    fieldset textarea.transparent {
      border-color: transparent;
      background-color: transparent; }
    fieldset input:hover, fieldset input.hover,
    fieldset select:hover,
    fieldset select.hover,
    fieldset textarea:hover,
    fieldset textarea.hover {
      border-color: #737B80; }
      fieldset input:hover .nama-icon *, fieldset input.hover .nama-icon *,
      fieldset select:hover .nama-icon *,
      fieldset select.hover .nama-icon *,
      fieldset textarea:hover .nama-icon *,
      fieldset textarea.hover .nama-icon * {
        fill: #737B80; }
    fieldset input.ng-dirty.ng-touched.ng-invalid, fieldset input.error, fieldset input:invalid, fieldset input.invalid,
    fieldset select.ng-dirty.ng-touched.ng-invalid,
    fieldset select.error,
    fieldset select:invalid,
    fieldset select.invalid,
    fieldset textarea.ng-dirty.ng-touched.ng-invalid,
    fieldset textarea.error,
    fieldset textarea:invalid,
    fieldset textarea.invalid {
      box-shadow: none;
      border: 1px solid #F50057; }
      fieldset input.ng-dirty.ng-touched.ng-invalid .nama-icon *, fieldset input.error .nama-icon *, fieldset input:invalid .nama-icon *, fieldset input.invalid .nama-icon *,
      fieldset select.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset select.error .nama-icon *,
      fieldset select:invalid .nama-icon *,
      fieldset select.invalid .nama-icon *,
      fieldset textarea.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset textarea.error .nama-icon *,
      fieldset textarea:invalid .nama-icon *,
      fieldset textarea.invalid .nama-icon * {
        fill: #F50057; }
    fieldset input.success,
    fieldset select.success,
    fieldset textarea.success {
      border: 1px solid #00BFA5; }
      fieldset input.success .nama-icon *,
      fieldset select.success .nama-icon *,
      fieldset textarea.success .nama-icon * {
        fill: #00BFA5; }
    fieldset input.warning,
    fieldset select.warning,
    fieldset textarea.warning {
      border: 1px solid #FFB300; }
      fieldset input.warning .nama-icon *,
      fieldset select.warning .nama-icon *,
      fieldset textarea.warning .nama-icon * {
        fill: #FFB300; }
    fieldset input:focus, fieldset input.focus,
    fieldset select:focus,
    fieldset select.focus,
    fieldset textarea:focus,
    fieldset textarea.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      fieldset input:focus .nama-icon svg, fieldset input.focus .nama-icon svg,
      fieldset select:focus .nama-icon svg,
      fieldset select.focus .nama-icon svg,
      fieldset textarea:focus .nama-icon svg,
      fieldset textarea.focus .nama-icon svg {
        fill: #41A2F0 !important; }
        fieldset input:focus .nama-icon svg *, fieldset input.focus .nama-icon svg *,
        fieldset select:focus .nama-icon svg *,
        fieldset select.focus .nama-icon svg *,
        fieldset textarea:focus .nama-icon svg *,
        fieldset textarea.focus .nama-icon svg * {
          fill: #41A2F0 !important; }
    fieldset input:disabled,
    fieldset select:disabled,
    fieldset textarea:disabled {
      background-color: #D2DADE;
      border-color: #D2DADE;
      color: #A0AAAF;
      cursor: not-allowed; }
      fieldset input:disabled .nama-icon *,
      fieldset select:disabled .nama-icon *,
      fieldset textarea:disabled .nama-icon * {
        fill: #A0AAAF; }
  fieldset .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: #F8BBD0;
    border: 1px solid #F06292;
    border-radius: 4px;
    -webkit-transition: 0.2s height;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #C2185B;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block; }
  fieldset .nama-hint,
  fieldset .hint {
    display: block;
    font-size: 12px;
    color: #A0AAAF;
    margin: 0px 4px;
    margin-top: -4px; }
    fieldset .nama-hint a,
    fieldset .hint a {
      font-size: 1em; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-mini-donut-chart {
  display: block;
  width: 45px;
  height: 45px;
  position: relative; }
  .nama-mini-donut-chart svg {
    width: 45px;
    height: 45px; }
    .nama-mini-donut-chart svg .nama-mini-donut-chart-inner-circle {
      fill: none; }
    .nama-mini-donut-chart svg .nama-mini-donut-chart-outer-circle {
      stroke-dasharray: 130px;
      -webkit-transform: rotate(-180deg);
              transform: rotate(-180deg);
      -webkit-transform-origin: center;
              transform-origin: center;
      fill: none;
      -webkit-transition: 0.3 stroke-dashoffset ease-in-out;
      transition: 0.3 stroke-dashoffset ease-in-out; }
  .nama-mini-donut-chart .nama-mini-donut-chart-current-value {
    display: block;
    position: absolute;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    top: 0px;
    left: 0px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-weight: 600;
    font-size: 14px;
    color: #35383A;
    letter-spacing: 0;
    text-align: center; }

.dark.card .nama-mini-donut-chart .nama-mini-donut-chart-current-value {
  color: #A0AAAF; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-percentage-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: relative;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  .nama-percentage-bar .nama-percentage-bar-positive-number,
  .nama-percentage-bar .nama-percentage-bar-negative-number {
    font-weight: 600;
    font-size: 16px;
    color: #575E62; }
  .nama-percentage-bar .nama-percentage-bar-positive-number {
    padding-left: 10px; }
  .nama-percentage-bar .nama-percentage-bar-negative-number {
    padding-right: 10px; }
  .nama-percentage-bar .nama-percentage-bar-base {
    display: block;
    position: relative;
    width: 100%;
    height: 4px;
    background: #EEF0F3;
    position: relative;
    border-radius: 2px; }
    .nama-percentage-bar .nama-percentage-bar-base .nama-percentage-bar-separator {
      display: block;
      position: absolute;
      top: -13px;
      left: 50%;
      width: 3px;
      margin-left: -2px;
      height: 30px;
      background: #EEF0F3;
      z-index: 2; }
    .nama-percentage-bar .nama-percentage-bar-base .nama-percentage-bar-positive-bar,
    .nama-percentage-bar .nama-percentage-bar-base .nama-percentage-bar-negative-bar {
      display: block;
      position: absolute;
      top: 0px;
      width: 0px;
      height: 100%;
      border-radius: 2px;
      -webkit-transition: 0.2s width ease-in;
      transition: 0.2s width ease-in;
      max-width: 50%; }
    .nama-percentage-bar .nama-percentage-bar-base .nama-percentage-bar-positive-bar {
      background: -webkit-gradient(linear, right top, left top, from(#00BFA5), to(#91F2E5));
      background: linear-gradient(-90deg, #00BFA5, #91F2E5);
      left: 50%; }
    .nama-percentage-bar .nama-percentage-bar-base .nama-percentage-bar-negative-bar {
      background: -webkit-gradient(linear, left top, right top, from(#F50057), to(#F8BBD0));
      background: linear-gradient(90deg, #F50057, #F8BBD0);
      right: 50%; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-tag {
  display: inline;
  padding: 4px 16px;
  margin-right: 8px;
  background-color: #41A2F0;
  color: #183B99;
  border-radius: 32px; }

@charset "UTF-8";
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-section-button {
  width: 100%;
  margin: 0; }

.nama-display {
  display: block; }
  .nama-display.simple, .nama-display.card, .nama-display.accordeon {
    overflow: hidden; }
    .nama-display.simple .title, .nama-display.card .title, .nama-display.accordeon .title {
      font-family: "Source Sans Pro", "Open Sans", sans-serif;
      min-height: 64px; }
      .nama-display.simple .title h3, .nama-display.card .title h3, .nama-display.accordeon .title h3 {
        font-size: 14px;
        padding: 16px;
        font-weight: 600;
        color: #A0AAAF;
        margin-top: 0px;
        float: left; }
      .nama-display.simple .title .nama-icon, .nama-display.card .title .nama-icon, .nama-display.accordeon .title .nama-icon {
        width: 18px;
        float: left;
        padding-top: 4px;
        padding-right: 16px; }
        .nama-display.simple .title .nama-icon svg, .nama-display.card .title .nama-icon svg, .nama-display.accordeon .title .nama-icon svg {
          fill: #2962FF; }
      .nama-display.simple .title .nama-mini-donut-chart, .nama-display.card .title .nama-mini-donut-chart, .nama-display.accordeon .title .nama-mini-donut-chart {
        float: right;
        margin: 10px 10px 0px 0px; }
    .nama-display.simple .content, .nama-display.card .content, .nama-display.accordeon .content {
      padding: 16px 0px;
      display: table;
      box-sizing: border-box;
      width: 100%; }
      .nama-display.simple .content .nama-row, .nama-display.card .content .nama-row, .nama-display.accordeon .content .nama-row {
        margin-top: 0px !important;
        margin-bottom: 0px !important; }
      .nama-display.simple .content .cicle, .nama-display.card .content .cicle, .nama-display.accordeon .content .cicle {
        width: 80px;
        height: 80px;
        margin: 22px 16px 16px 22px;
        float: left;
        border-radius: 80px;
        background: #91F2E5;
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: -webkit-gradient(linear, left top, left bottom, from(#91F2E5), to(#2BD9C2));
        background: linear-gradient(to bottom, #91F2E5 0%, #2BD9C2 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
        .nama-display.simple .content .cicle p, .nama-display.card .content .cicle p, .nama-display.accordeon .content .cicle p {
          background-color: #fff;
          display: inline-block;
          width: 72px;
          margin: 4px;
          font-size: 22px;
          font-weight: 400;
          text-align: center;
          height: 60px;
          padding-top: 12px;
          border-radius: 72px;
          overflow: hidden; }
          .nama-display.simple .content .cicle p span, .nama-display.card .content .cicle p span, .nama-display.accordeon .content .cicle p span {
            display: block;
            font-size: 12px; }
      .nama-display.simple .content .description, .nama-display.card .content .description, .nama-display.accordeon .content .description {
        padding: 8px 16px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        display: table; }
        .nama-display.simple .content .description.cicled, .nama-display.card .content .description.cicled, .nama-display.accordeon .content .description.cicled {
          float: right;
          width: calc(100% - 118px); }
        .nama-display.simple .content .description p, .nama-display.card .content .description p, .nama-display.accordeon .content .description p {
          border-bottom: 1px solid #D2DADE;
          display: block;
          font-size: 14px;
          width: calc(100% - 16px);
          margin: 0px 8px;
          height: 48px;
          line-height: 48px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
          .nama-display.simple .content .description p.col-2, .nama-display.card .content .description p.col-2, .nama-display.accordeon .content .description p.col-2 {
            width: calc(50% - 16px);
            float: left; }
          .nama-display.simple .content .description p span, .nama-display.card .content .description p span, .nama-display.accordeon .content .description p span {
            float: right;
            font-size: 20px;
            font-weight: 700;
            display: block;
            width: 50%;
            text-align: right; }
        .nama-display.simple .content .description p:nth-child(3), .nama-display.card .content .description p:nth-child(3), .nama-display.accordeon .content .description p:nth-child(3) {
          border-bottom: none; }
        .nama-display.simple .content .description p:nth-child(4), .nama-display.card .content .description p:nth-child(4), .nama-display.accordeon .content .description p:nth-child(4) {
          border-bottom: none; }
        .nama-display.simple .content .description p:last-child, .nama-display.card .content .description p:last-child, .nama-display.accordeon .content .description p:last-child {
          border-bottom: none; }
      .nama-display.simple .content .list, .nama-display.card .content .list, .nama-display.accordeon .content .list {
        padding: 8px 16px;
        box-sizing: border-box;
        width: 100%;
        font-size: 14px;
        height: auto;
        display: table;
        list-style: none;
        font-family: "Source Sans Pro", "Open Sans", sans-serif;
        color: #737b80;
        font-weight: 700; }
        .nama-display.simple .content .list li::before, .nama-display.card .content .list li::before, .nama-display.accordeon .content .list li::before {
          content: "\2022   ";
          color: #2962FF;
          /* or whatever color you prefer */ }
        .nama-display.simple .content .list li, .nama-display.card .content .list li, .nama-display.accordeon .content .list li {
          line-height: 24px; }
          .nama-display.simple .content .list li span, .nama-display.card .content .list li span, .nama-display.accordeon .content .list li span {
            float: right;
            font-size: 12px;
            font-weight: 500;
            display: block;
            width: 50%;
            text-align: right; }
    .nama-display.simple .nama-table .content, .nama-display.card .nama-table .content, .nama-display.accordeon .nama-table .content {
      padding: 0px; }
  .nama-display.accordeon {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin: 8px 8px 8px 0px;
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3); }
    .nama-display.accordeon .title {
      border-radius: 8px 8px 0px 0px;
      background-color: #fff;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .nama-display.accordeon .title .nama-icon[icon="dropdown-down-single"] {
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin: 0;
        padding: 0;
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
        -webkit-transition: 0.2s -webkit-transform;
        transition: 0.2s -webkit-transform;
        transition: 0.2s transform;
        transition: 0.2s transform, 0.2s -webkit-transform; }
        .nama-display.accordeon .title .nama-icon[icon="dropdown-down-single"] svg {
          fill: #A0AAAF; }
    .nama-display.accordeon .accordeon-content {
      overflow: hidden;
      height: auto;
      max-height: 0px; }
    .nama-display.accordeon.opened .accordeon-content {
      max-height: 100%; }
    .nama-display.accordeon.opened .nama-icon[icon="dropdown-down-single"] {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
  .nama-display.simple .title {
    border-bottom: 1px solid #D2DADE; }
    .nama-display.simple .title h3 {
      font-size: 16px;
      text-transform: capitalize; }
  .nama-display.card {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    margin: 8px 8px 8px 0px;
    box-sizing: border-box;
    box-shadow: 0 8px 10px 0 rgba(87, 94, 98, 0.3); }
    .nama-display.card .title {
      border-radius: 8px 8px 0px 0px;
      background-color: #EEF0F3; }
      .nama-display.card .title h3 {
        text-transform: uppercase; }
    .nama-display.card .content {
      padding: 16px; }
    .nama-display.card.dark {
      background-color: #35383A;
      color: #E3E8EB; }
      .nama-display.card.dark .description p {
        color: #E3E8EB;
        border-bottom-color: #252729; }
      .nama-display.card.dark .title {
        background-color: #252729;
        border-bottom-color: #252729; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-upload-avatar .nama-upload-avatar-wrapper {
  -moz-appearance: none;
       appearance: none;
  background-color: #ffffff;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border: 1px solid #D2DADE;
  color: #737B80;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  width: 120px;
  height: 120px;
  -webkit-appearance: none;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  padding-top: 8px; }
  .nama-upload-avatar .nama-upload-avatar-wrapper .nama-avatar {
    margin: auto !important; }

.nama-upload-avatar input {
  display: none; }

@charset "UTF-8";
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

fieldset {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  fieldset textarea {
    height: 80px !important; }
  fieldset label {
    color: #737B80;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    display: block;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    -moz-appearance: none;
         appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-transition: border-color 400ms ease;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    fieldset input::-webkit-inner-spin-button, fieldset input::-webkit-clear-button,
    fieldset select::-webkit-inner-spin-button,
    fieldset select::-webkit-clear-button,
    fieldset textarea::-webkit-inner-spin-button,
    fieldset textarea::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    fieldset input::-webkit-input-placeholder, fieldset select::-webkit-input-placeholder, fieldset textarea::-webkit-input-placeholder {
      color: #A0AAAF; }
    fieldset input::-moz-placeholder, fieldset select::-moz-placeholder, fieldset textarea::-moz-placeholder {
      color: #A0AAAF; }
    fieldset input::-ms-input-placeholder, fieldset select::-ms-input-placeholder, fieldset textarea::-ms-input-placeholder {
      color: #A0AAAF; }
    fieldset input::placeholder,
    fieldset select::placeholder,
    fieldset textarea::placeholder {
      color: #A0AAAF; }
    fieldset input.compact,
    fieldset select.compact,
    fieldset textarea.compact {
      font-size: 13px;
      height: 36px;
      padding: 4px 16px; }
    fieldset input.transparent,
    fieldset select.transparent,
    fieldset textarea.transparent {
      border-color: transparent;
      background-color: transparent; }
    fieldset input:hover, fieldset input.hover,
    fieldset select:hover,
    fieldset select.hover,
    fieldset textarea:hover,
    fieldset textarea.hover {
      border-color: #737B80; }
      fieldset input:hover .nama-icon *, fieldset input.hover .nama-icon *,
      fieldset select:hover .nama-icon *,
      fieldset select.hover .nama-icon *,
      fieldset textarea:hover .nama-icon *,
      fieldset textarea.hover .nama-icon * {
        fill: #737B80; }
    fieldset input.ng-dirty.ng-touched.ng-invalid, fieldset input.error, fieldset input:invalid, fieldset input.invalid,
    fieldset select.ng-dirty.ng-touched.ng-invalid,
    fieldset select.error,
    fieldset select:invalid,
    fieldset select.invalid,
    fieldset textarea.ng-dirty.ng-touched.ng-invalid,
    fieldset textarea.error,
    fieldset textarea:invalid,
    fieldset textarea.invalid {
      box-shadow: none;
      border: 1px solid #F50057; }
      fieldset input.ng-dirty.ng-touched.ng-invalid .nama-icon *, fieldset input.error .nama-icon *, fieldset input:invalid .nama-icon *, fieldset input.invalid .nama-icon *,
      fieldset select.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset select.error .nama-icon *,
      fieldset select:invalid .nama-icon *,
      fieldset select.invalid .nama-icon *,
      fieldset textarea.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset textarea.error .nama-icon *,
      fieldset textarea:invalid .nama-icon *,
      fieldset textarea.invalid .nama-icon * {
        fill: #F50057; }
    fieldset input.success,
    fieldset select.success,
    fieldset textarea.success {
      border: 1px solid #00BFA5; }
      fieldset input.success .nama-icon *,
      fieldset select.success .nama-icon *,
      fieldset textarea.success .nama-icon * {
        fill: #00BFA5; }
    fieldset input.warning,
    fieldset select.warning,
    fieldset textarea.warning {
      border: 1px solid #FFB300; }
      fieldset input.warning .nama-icon *,
      fieldset select.warning .nama-icon *,
      fieldset textarea.warning .nama-icon * {
        fill: #FFB300; }
    fieldset input:focus, fieldset input.focus,
    fieldset select:focus,
    fieldset select.focus,
    fieldset textarea:focus,
    fieldset textarea.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      fieldset input:focus .nama-icon svg, fieldset input.focus .nama-icon svg,
      fieldset select:focus .nama-icon svg,
      fieldset select.focus .nama-icon svg,
      fieldset textarea:focus .nama-icon svg,
      fieldset textarea.focus .nama-icon svg {
        fill: #41A2F0 !important; }
        fieldset input:focus .nama-icon svg *, fieldset input.focus .nama-icon svg *,
        fieldset select:focus .nama-icon svg *,
        fieldset select.focus .nama-icon svg *,
        fieldset textarea:focus .nama-icon svg *,
        fieldset textarea.focus .nama-icon svg * {
          fill: #41A2F0 !important; }
    fieldset input:disabled,
    fieldset select:disabled,
    fieldset textarea:disabled {
      background-color: #D2DADE;
      border-color: #D2DADE;
      color: #A0AAAF;
      cursor: not-allowed; }
      fieldset input:disabled .nama-icon *,
      fieldset select:disabled .nama-icon *,
      fieldset textarea:disabled .nama-icon * {
        fill: #A0AAAF; }
  fieldset .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: #F8BBD0;
    border: 1px solid #F06292;
    border-radius: 4px;
    -webkit-transition: 0.2s height;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #C2185B;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block; }
  fieldset .nama-hint,
  fieldset .hint {
    display: block;
    font-size: 12px;
    color: #A0AAAF;
    margin: 0px 4px;
    margin-top: -4px; }
    fieldset .nama-hint a,
    fieldset .hint a {
      font-size: 1em; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-button-old.dropdown,
nama-button-old.dropdown {
  overflow: visible;
  position: relative; }
  .nama-button-old.dropdown .nama-icon,
  .nama-button-old.dropdown span,
  nama-button-old.dropdown .nama-icon,
  nama-button-old.dropdown span {
    float: left; }
  .nama-button-old.dropdown:hover .subactions,
  nama-button-old.dropdown:hover .subactions {
    display: flex;
    justify-content: center; }
  .nama-button-old.dropdown.huge .subactions,
  nama-button-old.dropdown.huge .subactions {
    margin-top: 52px; }
  .nama-button-old.dropdown.big .subactions,
  nama-button-old.dropdown.big .subactions {
    margin-top: 40px; }
  .nama-button-old.dropdown.medium .subactions,
  nama-button-old.dropdown.medium .subactions {
    margin-top: 32px; }
  .nama-button-old.dropdown.small .subactions,
  nama-button-old.dropdown.small .subactions {
    margin-top: 28px; }
  .nama-button-old.dropdown.tiny .subactions,
  nama-button-old.dropdown.tiny .subactions {
    margin-top: 20px; }
  .nama-button-old.dropdown .subactions,
  nama-button-old.dropdown .subactions {
    background-color: #FCFDFF;
    border: 1px solid #F6F7F8;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3);
    margin-top: 32px;
    display: none;
    position: absolute;
    flex-flow: column;
    z-index: 10000000;
    right: 0px;
    top: 3px; }
    .nama-button-old.dropdown .subactions .nama-button-old,
    nama-button-old.dropdown .subactions .nama-button-old {
      margin: 0px !important; }

nama-button-old,
.nama-button-old {
  font-weight: 400;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 4px;
  box-sizing: border-box;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F6F7F8;
  border: 1px solid #F6F7F8;
  color: #737B80; }
  nama-button-old span,
  .nama-button-old span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  nama-button-old.avatar,
  .nama-button-old.avatar {
    padding-left: 8px !important; }
    nama-button-old.avatar .nama-avatar,
    .nama-button-old.avatar .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px; }
    nama-button-old.avatar span,
    .nama-button-old.avatar span {
      float: left;
      width: auto; }
    nama-button-old.avatar .nama-icon,
    .nama-button-old.avatar .nama-icon {
      margin-right: 8px;
      float: left; }
  nama-button-old.sub-menu-item,
  .nama-button-old.sub-menu-item {
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    letter-spacing: -0.06px;
    color: #575E62;
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 32px;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent; }
    nama-button-old.sub-menu-item:hover,
    .nama-button-old.sub-menu-item:hover {
      border-bottom: 1px solid #2753CC;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: #252729; }
    nama-button-old.sub-menu-item.active,
    .nama-button-old.sub-menu-item.active {
      border-bottom: 1px solid #2962FF;
      color: #575E62;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent; }
  nama-button-old.expanded,
  .nama-button-old.expanded {
    width: calc(100% - 8px); }
  nama-button-old.capitalize,
  .nama-button-old.capitalize {
    text-transform: capitalize; }
  nama-button-old.full,
  .nama-button-old.full {
    padding: 0px 32px; }
  nama-button-old.padded,
  .nama-button-old.padded {
    padding: 0px 16px; }
  nama-button-old.compact,
  .nama-button-old.compact {
    padding: 0px; }
  nama-button-old.icon .nama-icon,
  .nama-button-old.icon .nama-icon {
    display: inherit;
    margin-left: 0px; }
  nama-button-old.icon.left span,
  .nama-button-old.icon.left span {
    float: right; }
  nama-button-old.icon.left .nama-icon,
  .nama-button-old.icon.left .nama-icon {
    margin-right: 8px;
    float: left; }
  nama-button-old.icon.right span,
  .nama-button-old.icon.right span {
    float: left; }
  nama-button-old.icon.right .nama-icon,
  .nama-button-old.icon.right .nama-icon {
    margin-left: 8px;
    float: right; }
  nama-button-old.icon.center .nama-icon,
  .nama-button-old.icon.center .nama-icon {
    margin-left: auto;
    float: none;
    margin-right: auto; }
  nama-button-old span,
  .nama-button-old span {
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 34px;
    display: table; }
  nama-button-old.avatar span,
  .nama-button-old.avatar span {
    padding-right: 8px;
    width: calc(100% - 114px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    width: 20px;
    margin: 16px;
    margin-top: 7px;
    height: 20px; }
  nama-button-old.nama-submit,
  .nama-button-old.nama-submit {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 34px;
    height: 34px; }
  nama-button-old.huge,
  .nama-button-old.huge {
    height: 56px;
    font-size: 16px;
    line-height: 52px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.huge span,
    .nama-button-old.huge span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 54px;
      display: table; }
    nama-button-old.huge.avatar span,
    .nama-button-old.huge.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.huge .nama-icon,
    .nama-button-old.huge .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 17px;
      height: 20px; }
  nama-button-old.nama-submit.huge,
  .nama-button-old.nama-submit.huge {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 56px;
    height: 56px; }
  nama-button-old.big,
  .nama-button-old.big {
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.big span,
    .nama-button-old.big span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 42px;
      display: table; }
    nama-button-old.big.avatar span,
    .nama-button-old.big.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.big .nama-icon,
    .nama-button-old.big .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 11px;
      height: 20px; }
  nama-button-old.nama-submit.big,
  .nama-button-old.nama-submit.big {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 44px;
    height: 44px; }
  nama-button-old.medium,
  .nama-button-old.medium {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.medium span,
    .nama-button-old.medium span {
      width: calc(100% - 40px);
      font-size: 14px;
      line-height: 34px;
      display: table; }
    nama-button-old.medium.avatar span,
    .nama-button-old.medium.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.medium .nama-icon,
    .nama-button-old.medium .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 7px;
      height: 20px; }
  nama-button-old.nama-submit.medium,
  .nama-button-old.nama-submit.medium {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 36px;
    height: 36px; }
  nama-button-old.small,
  .nama-button-old.small {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.small span,
    .nama-button-old.small span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 30px;
      display: table; }
    nama-button-old.small.avatar span,
    .nama-button-old.small.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.small .nama-icon,
    .nama-button-old.small .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 7px;
      height: 16px; }
  nama-button-old.nama-submit.small,
  .nama-button-old.nama-submit.small {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    height: 32px; }
  nama-button-old.tiny,
  .nama-button-old.tiny {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.tiny span,
    .nama-button-old.tiny span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 22px;
      display: table; }
    nama-button-old.tiny.avatar span,
    .nama-button-old.tiny.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.tiny .nama-icon,
    .nama-button-old.tiny .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 3px;
      height: 16px; }
  nama-button-old.nama-submit.tiny,
  .nama-button-old.nama-submit.tiny {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    height: 24px; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    fill: #737B80; }
  nama-button-old:hover, nama-button-old.hover,
  .nama-button-old:hover,
  .nama-button-old.hover {
    background-color: #F6F7F8;
    border: 1px solid #A0AAAF;
    color: #575E62; }
    nama-button-old:hover .nama-icon, nama-button-old.hover .nama-icon,
    .nama-button-old:hover .nama-icon,
    .nama-button-old.hover .nama-icon {
      fill: #575E62; }
  nama-button-old.selected,
  .nama-button-old.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    nama-button-old.selected .nama-icon,
    .nama-button-old.selected .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    background-color: #F6F7F8;
    color: #575E62;
    border: 1px solid #F6F7F8;
    outline: none; }
    nama-button-old:focus .nama-icon, nama-button-old.focus .nama-icon,
    .nama-button-old:focus .nama-icon,
    .nama-button-old.focus .nama-icon {
      fill: #575E62 !important; }
  nama-button-old:active, nama-button-old.active,
  .nama-button-old:active,
  .nama-button-old.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    nama-button-old:active .nama-icon, nama-button-old.active .nama-icon,
    .nama-button-old:active .nama-icon,
    .nama-button-old.active .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:disabled, nama-button-old.disabled,
  .nama-button-old:disabled,
  .nama-button-old.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    nama-button-old:disabled .nama-icon, nama-button-old.disabled .nama-icon,
    .nama-button-old:disabled .nama-icon,
    .nama-button-old.disabled .nama-icon {
      fill: #A0AAAF !important; }
  nama-button-old.white,
  .nama-button-old.white {
    background-color: #FCFDFF;
    border: 1px solid #FCFDFF;
    color: #737B80; }
    nama-button-old.white .nama-icon,
    .nama-button-old.white .nama-icon {
      fill: #737B80; }
    nama-button-old.white:hover, nama-button-old.white.hover,
    .nama-button-old.white:hover,
    .nama-button-old.white.hover {
      background-color: #F6F7F8;
      border: 1px solid #F6F7F8;
      color: #575E62; }
      nama-button-old.white:hover .nama-icon, nama-button-old.white.hover .nama-icon,
      .nama-button-old.white:hover .nama-icon,
      .nama-button-old.white.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.white.selected,
    .nama-button-old.white.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white.selected .nama-icon,
      .nama-button-old.white.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:focus, nama-button-old.white.focus,
    .nama-button-old.white:focus,
    .nama-button-old.white.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.white:focus .nama-icon, nama-button-old.white.focus .nama-icon,
      .nama-button-old.white:focus .nama-icon,
      .nama-button-old.white.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.white:active, nama-button-old.white.active,
    .nama-button-old.white:active,
    .nama-button-old.white.active {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white:active .nama-icon, nama-button-old.white.active .nama-icon,
      .nama-button-old.white:active .nama-icon,
      .nama-button-old.white.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:disabled, nama-button-old.white.disabled,
    .nama-button-old.white:disabled,
    .nama-button-old.white.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.white:disabled .nama-icon, nama-button-old.white.disabled .nama-icon,
      .nama-button-old.white:disabled .nama-icon,
      .nama-button-old.white.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.clear,
  .nama-button-old.clear {
    background-color: transparent;
    border: 1px solid transparent;
    color: #737B80; }
    nama-button-old.clear .nama-icon,
    .nama-button-old.clear .nama-icon {
      fill: #737B80; }
    nama-button-old.clear:hover, nama-button-old.clear.hover,
    .nama-button-old.clear:hover,
    .nama-button-old.clear.hover {
      background-color: transparent;
      border: 1px solid transparent;
      color: #575E62; }
      nama-button-old.clear:hover .nama-icon, nama-button-old.clear.hover .nama-icon,
      .nama-button-old.clear:hover .nama-icon,
      .nama-button-old.clear.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.clear.selected,
    .nama-button-old.clear.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.clear.selected .nama-icon,
      .nama-button-old.clear.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.clear:focus, nama-button-old.clear.focus,
    .nama-button-old.clear:focus,
    .nama-button-old.clear.focus {
      background-color: transparent;
      color: #737B80;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:focus .nama-icon, nama-button-old.clear.focus .nama-icon,
      .nama-button-old.clear:focus .nama-icon,
      .nama-button-old.clear.focus .nama-icon {
        fill: #737B80 !important; }
    nama-button-old.clear:active, nama-button-old.clear.active,
    .nama-button-old.clear:active,
    .nama-button-old.clear.active {
      background-color: transparent;
      color: #183B99;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:active .nama-icon, nama-button-old.clear.active .nama-icon,
      .nama-button-old.clear:active .nama-icon,
      .nama-button-old.clear.active .nama-icon {
        fill: #183B99 !important; }
    nama-button-old.clear:disabled, nama-button-old.clear.disabled,
    .nama-button-old.clear:disabled,
    .nama-button-old.clear.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
      .nama-button-old.clear:disabled .nama-icon,
      .nama-button-old.clear.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.default,
  .nama-button-old.default {
    background-color: #F6F7F8;
    border: 1px solid #F6F7F8;
    color: #737B80; }
    nama-button-old.default .nama-icon,
    .nama-button-old.default .nama-icon {
      fill: #737B80; }
    nama-button-old.default:hover, nama-button-old.default.hover,
    .nama-button-old.default:hover,
    .nama-button-old.default.hover {
      background-color: #F6F7F8;
      border: 1px solid #A0AAAF;
      color: #575E62; }
      nama-button-old.default:hover .nama-icon, nama-button-old.default.hover .nama-icon,
      .nama-button-old.default:hover .nama-icon,
      .nama-button-old.default.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.default.selected,
    .nama-button-old.default.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.default.selected .nama-icon,
      .nama-button-old.default.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:focus, nama-button-old.default.focus,
    .nama-button-old.default:focus,
    .nama-button-old.default.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.default:focus .nama-icon, nama-button-old.default.focus .nama-icon,
      .nama-button-old.default:focus .nama-icon,
      .nama-button-old.default.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.default:active, nama-button-old.default.active,
    .nama-button-old.default:active,
    .nama-button-old.default.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.default:active .nama-icon, nama-button-old.default.active .nama-icon,
      .nama-button-old.default:active .nama-icon,
      .nama-button-old.default.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:disabled, nama-button-old.default.disabled,
    .nama-button-old.default:disabled,
    .nama-button-old.default.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.default:disabled .nama-icon, nama-button-old.default.disabled .nama-icon,
      .nama-button-old.default:disabled .nama-icon,
      .nama-button-old.default.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.primary,
  .nama-button-old.primary {
    background-color: #2962FF;
    border: 1px solid #2962FF;
    color: #ffffff; }
    nama-button-old.primary .nama-icon,
    .nama-button-old.primary .nama-icon {
      fill: #ffffff; }
    nama-button-old.primary:hover, nama-button-old.primary.hover,
    .nama-button-old.primary:hover,
    .nama-button-old.primary.hover {
      background-color: #41A2F0;
      border: 1px solid #41A2F0;
      color: #ffffff; }
      nama-button-old.primary:hover .nama-icon, nama-button-old.primary.hover .nama-icon,
      .nama-button-old.primary:hover .nama-icon,
      .nama-button-old.primary.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.primary.selected,
    .nama-button-old.primary.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.primary.selected .nama-icon,
      .nama-button-old.primary.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:focus, nama-button-old.primary.focus,
    .nama-button-old.primary:focus,
    .nama-button-old.primary.focus {
      background-color: #2962FF;
      color: #ffffff;
      border: 1px solid #2962FF;
      outline: none; }
      nama-button-old.primary:focus .nama-icon, nama-button-old.primary.focus .nama-icon,
      .nama-button-old.primary:focus .nama-icon,
      .nama-button-old.primary.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:active, nama-button-old.primary.active,
    .nama-button-old.primary:active,
    .nama-button-old.primary.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.primary:active .nama-icon, nama-button-old.primary.active .nama-icon,
      .nama-button-old.primary:active .nama-icon,
      .nama-button-old.primary.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:disabled, nama-button-old.primary.disabled,
    .nama-button-old.primary:disabled,
    .nama-button-old.primary.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.primary:disabled .nama-icon, nama-button-old.primary.disabled .nama-icon,
      .nama-button-old.primary:disabled .nama-icon,
      .nama-button-old.primary.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.info,
  .nama-button-old.info {
    background-color: #8D25AA;
    border: 1px solid #8D25AA;
    color: #ffffff; }
    nama-button-old.info .nama-icon,
    .nama-button-old.info .nama-icon {
      fill: #ffffff; }
    nama-button-old.info:hover, nama-button-old.info.hover,
    .nama-button-old.info:hover,
    .nama-button-old.info.hover {
      background-color: #BA68C8;
      border: 1px solid #BA68C8;
      color: #ffffff; }
      nama-button-old.info:hover .nama-icon, nama-button-old.info.hover .nama-icon,
      .nama-button-old.info:hover .nama-icon,
      .nama-button-old.info.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.info.selected,
    .nama-button-old.info.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.info.selected .nama-icon,
      .nama-button-old.info.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:focus, nama-button-old.info.focus,
    .nama-button-old.info:focus,
    .nama-button-old.info.focus {
      background-color: #8D25AA;
      color: #ffffff;
      border: 1px solid #8D25AA;
      outline: none; }
      nama-button-old.info:focus .nama-icon, nama-button-old.info.focus .nama-icon,
      .nama-button-old.info:focus .nama-icon,
      .nama-button-old.info.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:active, nama-button-old.info.active,
    .nama-button-old.info:active,
    .nama-button-old.info.active {
      background-color: #49148B;
      color: #ffffff;
      border: 1px solid #49148B;
      outline: none; }
      nama-button-old.info:active .nama-icon, nama-button-old.info.active .nama-icon,
      .nama-button-old.info:active .nama-icon,
      .nama-button-old.info.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:disabled, nama-button-old.info.disabled,
    .nama-button-old.info:disabled,
    .nama-button-old.info.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.info:disabled .nama-icon, nama-button-old.info.disabled .nama-icon,
      .nama-button-old.info:disabled .nama-icon,
      .nama-button-old.info.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.success,
  .nama-button-old.success {
    background-color: #00BFA5;
    border: 1px solid #00BFA5;
    color: #ffffff; }
    nama-button-old.success .nama-icon,
    .nama-button-old.success .nama-icon {
      fill: #ffffff; }
    nama-button-old.success:hover, nama-button-old.success.hover,
    .nama-button-old.success:hover,
    .nama-button-old.success.hover {
      background-color: #2BD9C2;
      border: 1px solid #2BD9C2;
      color: #ffffff; }
      nama-button-old.success:hover .nama-icon, nama-button-old.success.hover .nama-icon,
      .nama-button-old.success:hover .nama-icon,
      .nama-button-old.success.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.success.selected,
    .nama-button-old.success.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.success.selected .nama-icon,
      .nama-button-old.success.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:focus, nama-button-old.success.focus,
    .nama-button-old.success:focus,
    .nama-button-old.success.focus {
      background-color: #00BFA5;
      color: #ffffff;
      border: 1px solid #00BFA5;
      outline: none; }
      nama-button-old.success:focus .nama-icon, nama-button-old.success.focus .nama-icon,
      .nama-button-old.success:focus .nama-icon,
      .nama-button-old.success.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:active, nama-button-old.success.active,
    .nama-button-old.success:active,
    .nama-button-old.success.active {
      background-color: #00806E;
      color: #ffffff;
      border: 1px solid #00806E;
      outline: none; }
      nama-button-old.success:active .nama-icon, nama-button-old.success.active .nama-icon,
      .nama-button-old.success:active .nama-icon,
      .nama-button-old.success.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:disabled, nama-button-old.success.disabled,
    .nama-button-old.success:disabled,
    .nama-button-old.success.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.success:disabled .nama-icon, nama-button-old.success.disabled .nama-icon,
      .nama-button-old.success:disabled .nama-icon,
      .nama-button-old.success.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.warning,
  .nama-button-old.warning {
    background-color: #FFB300;
    border: 1px solid #FFB300;
    color: #ffffff; }
    nama-button-old.warning .nama-icon,
    .nama-button-old.warning .nama-icon {
      fill: #ffffff; }
    nama-button-old.warning:hover, nama-button-old.warning.hover,
    .nama-button-old.warning:hover,
    .nama-button-old.warning.hover {
      background-color: #FFD166;
      border: 1px solid #FFD166;
      color: #ffffff; }
      nama-button-old.warning:hover .nama-icon, nama-button-old.warning.hover .nama-icon,
      .nama-button-old.warning:hover .nama-icon,
      .nama-button-old.warning.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.warning.selected,
    .nama-button-old.warning.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.warning.selected .nama-icon,
      .nama-button-old.warning.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:focus, nama-button-old.warning.focus,
    .nama-button-old.warning:focus,
    .nama-button-old.warning.focus {
      background-color: #FFB300;
      color: #ffffff;
      border: 1px solid #FFB300;
      outline: none; }
      nama-button-old.warning:focus .nama-icon, nama-button-old.warning.focus .nama-icon,
      .nama-button-old.warning:focus .nama-icon,
      .nama-button-old.warning.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:active, nama-button-old.warning.active,
    .nama-button-old.warning:active,
    .nama-button-old.warning.active {
      background-color: #B37D00;
      color: #ffffff;
      border: 1px solid #B37D00;
      outline: none; }
      nama-button-old.warning:active .nama-icon, nama-button-old.warning.active .nama-icon,
      .nama-button-old.warning:active .nama-icon,
      .nama-button-old.warning.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:disabled, nama-button-old.warning.disabled,
    .nama-button-old.warning:disabled,
    .nama-button-old.warning.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.warning:disabled .nama-icon, nama-button-old.warning.disabled .nama-icon,
      .nama-button-old.warning:disabled .nama-icon,
      .nama-button-old.warning.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.danger,
  .nama-button-old.danger {
    background-color: #F50057;
    border: 1px solid #F50057;
    color: #ffffff; }
    nama-button-old.danger .nama-icon,
    .nama-button-old.danger .nama-icon {
      fill: #ffffff; }
    nama-button-old.danger:hover, nama-button-old.danger.hover,
    .nama-button-old.danger:hover,
    .nama-button-old.danger.hover {
      background-color: #F06292;
      border: 1px solid #F06292;
      color: #ffffff; }
      nama-button-old.danger:hover .nama-icon, nama-button-old.danger.hover .nama-icon,
      .nama-button-old.danger:hover .nama-icon,
      .nama-button-old.danger.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.danger.selected,
    .nama-button-old.danger.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.danger.selected .nama-icon,
      .nama-button-old.danger.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:focus, nama-button-old.danger.focus,
    .nama-button-old.danger:focus,
    .nama-button-old.danger.focus {
      background-color: #F50057;
      color: #ffffff;
      border: 1px solid #F50057;
      outline: none; }
      nama-button-old.danger:focus .nama-icon, nama-button-old.danger.focus .nama-icon,
      .nama-button-old.danger:focus .nama-icon,
      .nama-button-old.danger.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:active, nama-button-old.danger.active,
    .nama-button-old.danger:active,
    .nama-button-old.danger.active {
      background-color: #870E4F;
      color: #ffffff;
      border: 1px solid #870E4F;
      outline: none; }
      nama-button-old.danger:active .nama-icon, nama-button-old.danger.active .nama-icon,
      .nama-button-old.danger:active .nama-icon,
      .nama-button-old.danger.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:disabled, nama-button-old.danger.disabled,
    .nama-button-old.danger:disabled,
    .nama-button-old.danger.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.danger:disabled .nama-icon, nama-button-old.danger.disabled .nama-icon,
      .nama-button-old.danger:disabled .nama-icon,
      .nama-button-old.danger.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.icon-white .nama-icon,
  .nama-button-old.icon-white .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white:hover .nama-icon, nama-button-old.icon-white.hover .nama-icon,
  .nama-button-old.icon-white:hover .nama-icon,
  .nama-button-old.icon-white.hover .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white.selected .nama-icon,
  .nama-button-old.icon-white.selected .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:focus .nama-icon, nama-button-old.icon-white.focus .nama-icon,
  .nama-button-old.icon-white:focus .nama-icon,
  .nama-button-old.icon-white.focus .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:active .nama-icon, nama-button-old.icon-white.active .nama-icon,
  .nama-button-old.icon-white:active .nama-icon,
  .nama-button-old.icon-white.active .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:disabled .nama-icon, nama-button-old.icon-white.disabled .nama-icon,
  .nama-button-old.icon-white:disabled .nama-icon,
  .nama-button-old.icon-white.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-clear .nama-icon,
  .nama-button-old.icon-clear .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear:hover .nama-icon, nama-button-old.icon-clear.hover .nama-icon,
  .nama-button-old.icon-clear:hover .nama-icon,
  .nama-button-old.icon-clear.hover .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear.selected .nama-icon,
  .nama-button-old.icon-clear.selected .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:focus .nama-icon, nama-button-old.icon-clear.focus .nama-icon,
  .nama-button-old.icon-clear:focus .nama-icon,
  .nama-button-old.icon-clear.focus .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:active .nama-icon, nama-button-old.icon-clear.active .nama-icon,
  .nama-button-old.icon-clear:active .nama-icon,
  .nama-button-old.icon-clear.active .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:disabled .nama-icon, nama-button-old.icon-clear.disabled .nama-icon,
  .nama-button-old.icon-clear:disabled .nama-icon,
  .nama-button-old.icon-clear.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-default .nama-icon,
  .nama-button-old.icon-default .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default:hover .nama-icon, nama-button-old.icon-default.hover .nama-icon,
  .nama-button-old.icon-default:hover .nama-icon,
  .nama-button-old.icon-default.hover .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default.selected .nama-icon,
  .nama-button-old.icon-default.selected .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:focus .nama-icon, nama-button-old.icon-default.focus .nama-icon,
  .nama-button-old.icon-default:focus .nama-icon,
  .nama-button-old.icon-default.focus .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:active .nama-icon, nama-button-old.icon-default.active .nama-icon,
  .nama-button-old.icon-default:active .nama-icon,
  .nama-button-old.icon-default.active .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:disabled .nama-icon, nama-button-old.icon-default.disabled .nama-icon,
  .nama-button-old.icon-default:disabled .nama-icon,
  .nama-button-old.icon-default.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-primary .nama-icon,
  .nama-button-old.icon-primary .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary:hover .nama-icon, nama-button-old.icon-primary.hover .nama-icon,
  .nama-button-old.icon-primary:hover .nama-icon,
  .nama-button-old.icon-primary.hover .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary.selected .nama-icon,
  .nama-button-old.icon-primary.selected .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:focus .nama-icon, nama-button-old.icon-primary.focus .nama-icon,
  .nama-button-old.icon-primary:focus .nama-icon,
  .nama-button-old.icon-primary.focus .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:active .nama-icon, nama-button-old.icon-primary.active .nama-icon,
  .nama-button-old.icon-primary:active .nama-icon,
  .nama-button-old.icon-primary.active .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:disabled .nama-icon, nama-button-old.icon-primary.disabled .nama-icon,
  .nama-button-old.icon-primary:disabled .nama-icon,
  .nama-button-old.icon-primary.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-info .nama-icon,
  .nama-button-old.icon-info .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info:hover .nama-icon, nama-button-old.icon-info.hover .nama-icon,
  .nama-button-old.icon-info:hover .nama-icon,
  .nama-button-old.icon-info.hover .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info.selected .nama-icon,
  .nama-button-old.icon-info.selected .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:focus .nama-icon, nama-button-old.icon-info.focus .nama-icon,
  .nama-button-old.icon-info:focus .nama-icon,
  .nama-button-old.icon-info.focus .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:active .nama-icon, nama-button-old.icon-info.active .nama-icon,
  .nama-button-old.icon-info:active .nama-icon,
  .nama-button-old.icon-info.active .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:disabled .nama-icon, nama-button-old.icon-info.disabled .nama-icon,
  .nama-button-old.icon-info:disabled .nama-icon,
  .nama-button-old.icon-info.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-success .nama-icon,
  .nama-button-old.icon-success .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success:hover .nama-icon, nama-button-old.icon-success.hover .nama-icon,
  .nama-button-old.icon-success:hover .nama-icon,
  .nama-button-old.icon-success.hover .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success.selected .nama-icon,
  .nama-button-old.icon-success.selected .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:focus .nama-icon, nama-button-old.icon-success.focus .nama-icon,
  .nama-button-old.icon-success:focus .nama-icon,
  .nama-button-old.icon-success.focus .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:active .nama-icon, nama-button-old.icon-success.active .nama-icon,
  .nama-button-old.icon-success:active .nama-icon,
  .nama-button-old.icon-success.active .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:disabled .nama-icon, nama-button-old.icon-success.disabled .nama-icon,
  .nama-button-old.icon-success:disabled .nama-icon,
  .nama-button-old.icon-success.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-warning .nama-icon,
  .nama-button-old.icon-warning .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning:hover .nama-icon, nama-button-old.icon-warning.hover .nama-icon,
  .nama-button-old.icon-warning:hover .nama-icon,
  .nama-button-old.icon-warning.hover .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning.selected .nama-icon,
  .nama-button-old.icon-warning.selected .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:focus .nama-icon, nama-button-old.icon-warning.focus .nama-icon,
  .nama-button-old.icon-warning:focus .nama-icon,
  .nama-button-old.icon-warning.focus .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:active .nama-icon, nama-button-old.icon-warning.active .nama-icon,
  .nama-button-old.icon-warning:active .nama-icon,
  .nama-button-old.icon-warning.active .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:disabled .nama-icon, nama-button-old.icon-warning.disabled .nama-icon,
  .nama-button-old.icon-warning:disabled .nama-icon,
  .nama-button-old.icon-warning.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-danger .nama-icon,
  .nama-button-old.icon-danger .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger:hover .nama-icon, nama-button-old.icon-danger.hover .nama-icon,
  .nama-button-old.icon-danger:hover .nama-icon,
  .nama-button-old.icon-danger.hover .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger.selected .nama-icon,
  .nama-button-old.icon-danger.selected .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:focus .nama-icon, nama-button-old.icon-danger.focus .nama-icon,
  .nama-button-old.icon-danger:focus .nama-icon,
  .nama-button-old.icon-danger.focus .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:active .nama-icon, nama-button-old.icon-danger.active .nama-icon,
  .nama-button-old.icon-danger:active .nama-icon,
  .nama-button-old.icon-danger.active .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:disabled .nama-icon, nama-button-old.icon-danger.disabled .nama-icon,
  .nama-button-old.icon-danger:disabled .nama-icon,
  .nama-button-old.icon-danger.disabled .nama-icon {
    fill: background !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:focus, nama-button-old.clear.focus, nama-button-old.default:focus, nama-button-old.default.focus,
  .nama-button-old.clear:focus,
  .nama-button-old.clear.focus,
  .nama-button-old.default:focus,
  .nama-button-old.default.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:disabled, nama-button-old.clear.disabled,
  .nama-button-old.clear:disabled,
  .nama-button-old.clear.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #E3E8EB;
    border: 1px solid transparent;
    outline: none; }
    nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
    .nama-button-old.clear:disabled .nama-icon,
    .nama-button-old.clear.disabled .nama-icon {
      fill: #E3E8EB !important; }

.react-tagsinput {
  -moz-appearance: none;
       appearance: none;
  background-color: #ffffff;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border: 1px solid #D2DADE;
  color: #737B80;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  width: calc(100% - 8px);
  -webkit-appearance: none;
  outline: none;
  height: 44px;
  font-size: 14px;
  padding: 8px 16px;
  height: auto; }
  .react-tagsinput::-webkit-inner-spin-button, .react-tagsinput::-webkit-clear-button {
    display: none;
    -webkit-appearance: none; }
  .react-tagsinput::-webkit-input-placeholder {
    color: #A0AAAF; }
  .react-tagsinput::-moz-placeholder {
    color: #A0AAAF; }
  .react-tagsinput::-ms-input-placeholder {
    color: #A0AAAF; }
  .react-tagsinput::placeholder {
    color: #A0AAAF; }
  .react-tagsinput.compact {
    font-size: 13px;
    height: 36px;
    padding: 4px 16px; }
  .react-tagsinput.transparent {
    border-color: transparent;
    background-color: transparent; }
  .react-tagsinput:hover, .react-tagsinput.hover {
    border-color: #737B80; }
    .react-tagsinput:hover .nama-icon *, .react-tagsinput.hover .nama-icon * {
      fill: #737B80; }
  .react-tagsinput.ng-dirty.ng-touched.ng-invalid, .react-tagsinput.error, .react-tagsinput:invalid, .react-tagsinput.invalid {
    box-shadow: none;
    border: 1px solid #F50057; }
    .react-tagsinput.ng-dirty.ng-touched.ng-invalid .nama-icon *, .react-tagsinput.error .nama-icon *, .react-tagsinput:invalid .nama-icon *, .react-tagsinput.invalid .nama-icon * {
      fill: #F50057; }
  .react-tagsinput.success {
    border: 1px solid #00BFA5; }
    .react-tagsinput.success .nama-icon * {
      fill: #00BFA5; }
  .react-tagsinput.warning {
    border: 1px solid #FFB300; }
    .react-tagsinput.warning .nama-icon * {
      fill: #FFB300; }
  .react-tagsinput:focus, .react-tagsinput.focus {
    border-color: #41A2F0 !important;
    outline: none; }
    .react-tagsinput:focus .nama-icon svg, .react-tagsinput.focus .nama-icon svg {
      fill: #41A2F0 !important; }
      .react-tagsinput:focus .nama-icon svg *, .react-tagsinput.focus .nama-icon svg * {
        fill: #41A2F0 !important; }
  .react-tagsinput:disabled {
    background-color: #D2DADE;
    border-color: #D2DADE;
    color: #A0AAAF;
    cursor: not-allowed; }
    .react-tagsinput:disabled .nama-icon * {
      fill: #A0AAAF; }

.react-tagsinput--focused {
  border-color: #41A2F0 !important;
  outline: none; }

.react-tagsinput-tag {
  font-weight: 400;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 4px;
  box-sizing: border-box;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F6F7F8;
  border: 1px solid #F6F7F8;
  color: #737B80;
  background-color: #2962FF;
  border: 1px solid #2962FF;
  color: #ffffff; }
  .react-tagsinput-tag span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .react-tagsinput-tag.avatar {
    padding-left: 8px !important; }
    .react-tagsinput-tag.avatar .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px; }
    .react-tagsinput-tag.avatar span {
      float: left;
      width: auto; }
    .react-tagsinput-tag.avatar .nama-icon {
      margin-right: 8px;
      float: left; }
  .react-tagsinput-tag.sub-menu-item {
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    letter-spacing: -0.06px;
    color: #575E62;
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 32px;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent; }
    .react-tagsinput-tag.sub-menu-item:hover {
      border-bottom: 1px solid #2753CC;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: #252729; }
    .react-tagsinput-tag.sub-menu-item.active {
      border-bottom: 1px solid #2962FF;
      color: #575E62;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent; }
  .react-tagsinput-tag.expanded {
    width: calc(100% - 8px); }
  .react-tagsinput-tag.capitalize {
    text-transform: capitalize; }
  .react-tagsinput-tag.full {
    padding: 0px 32px; }
  .react-tagsinput-tag.padded {
    padding: 0px 16px; }
  .react-tagsinput-tag.compact {
    padding: 0px; }
  .react-tagsinput-tag.icon .nama-icon {
    display: inherit;
    margin-left: 0px; }
  .react-tagsinput-tag.icon.left span {
    float: right; }
  .react-tagsinput-tag.icon.left .nama-icon {
    margin-right: 8px;
    float: left; }
  .react-tagsinput-tag.icon.right span {
    float: left; }
  .react-tagsinput-tag.icon.right .nama-icon {
    margin-left: 8px;
    float: right; }
  .react-tagsinput-tag.icon.center .nama-icon {
    margin-left: auto;
    float: none;
    margin-right: auto; }
  .react-tagsinput-tag span {
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 34px;
    display: table; }
  .react-tagsinput-tag.avatar span {
    padding-right: 8px;
    width: calc(100% - 114px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  .react-tagsinput-tag .nama-icon {
    width: 20px;
    margin: 16px;
    margin-top: 7px;
    height: 20px; }
  .react-tagsinput-tag.nama-submit {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 34px;
    height: 34px; }
  .react-tagsinput-tag.huge {
    height: 56px;
    font-size: 16px;
    line-height: 52px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.huge span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 54px;
      display: table; }
    .react-tagsinput-tag.huge.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.huge .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 17px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.huge {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 56px;
    height: 56px; }
  .react-tagsinput-tag.big {
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.big span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 42px;
      display: table; }
    .react-tagsinput-tag.big.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.big .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 11px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.big {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 44px;
    height: 44px; }
  .react-tagsinput-tag.medium {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.medium span {
      width: calc(100% - 40px);
      font-size: 14px;
      line-height: 34px;
      display: table; }
    .react-tagsinput-tag.medium.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.medium .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 7px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.medium {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 36px;
    height: 36px; }
  .react-tagsinput-tag.small {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.small span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 30px;
      display: table; }
    .react-tagsinput-tag.small.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.small .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 7px;
      height: 16px; }
  .react-tagsinput-tag.nama-submit.small {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    height: 32px; }
  .react-tagsinput-tag.tiny {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.tiny span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 22px;
      display: table; }
    .react-tagsinput-tag.tiny.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.tiny .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 3px;
      height: 16px; }
  .react-tagsinput-tag.nama-submit.tiny {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    height: 24px; }
  .react-tagsinput-tag .nama-icon {
    fill: #737B80; }
  .react-tagsinput-tag:hover, .react-tagsinput-tag.hover {
    background-color: #F6F7F8;
    border: 1px solid #A0AAAF;
    color: #575E62; }
    .react-tagsinput-tag:hover .nama-icon, .react-tagsinput-tag.hover .nama-icon {
      fill: #575E62; }
  .react-tagsinput-tag.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    .react-tagsinput-tag.selected .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    background-color: #F6F7F8;
    color: #575E62;
    border: 1px solid #F6F7F8;
    outline: none; }
    .react-tagsinput-tag:focus .nama-icon, .react-tagsinput-tag.focus .nama-icon {
      fill: #575E62 !important; }
  .react-tagsinput-tag:active, .react-tagsinput-tag.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    .react-tagsinput-tag:active .nama-icon, .react-tagsinput-tag.active .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:disabled, .react-tagsinput-tag.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    .react-tagsinput-tag:disabled .nama-icon, .react-tagsinput-tag.disabled .nama-icon {
      fill: #A0AAAF !important; }
  .react-tagsinput-tag.white {
    background-color: #FCFDFF;
    border: 1px solid #FCFDFF;
    color: #737B80; }
    .react-tagsinput-tag.white .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.white:hover, .react-tagsinput-tag.white.hover {
      background-color: #F6F7F8;
      border: 1px solid #F6F7F8;
      color: #575E62; }
      .react-tagsinput-tag.white:hover .nama-icon, .react-tagsinput-tag.white.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.white.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.white.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.white:focus, .react-tagsinput-tag.white.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      .react-tagsinput-tag.white:focus .nama-icon, .react-tagsinput-tag.white.focus .nama-icon {
        fill: #575E62 !important; }
    .react-tagsinput-tag.white:active, .react-tagsinput-tag.white.active {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.white:active .nama-icon, .react-tagsinput-tag.white.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.white:disabled, .react-tagsinput-tag.white.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.white:disabled .nama-icon, .react-tagsinput-tag.white.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.clear {
    background-color: transparent;
    border: 1px solid transparent;
    color: #737B80; }
    .react-tagsinput-tag.clear .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.clear:hover, .react-tagsinput-tag.clear.hover {
      background-color: transparent;
      border: 1px solid transparent;
      color: #575E62; }
      .react-tagsinput-tag.clear:hover .nama-icon, .react-tagsinput-tag.clear.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.clear.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.clear.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.clear:focus, .react-tagsinput-tag.clear.focus {
      background-color: transparent;
      color: #737B80;
      border: 1px solid transparent;
      outline: none; }
      .react-tagsinput-tag.clear:focus .nama-icon, .react-tagsinput-tag.clear.focus .nama-icon {
        fill: #737B80 !important; }
    .react-tagsinput-tag.clear:active, .react-tagsinput-tag.clear.active {
      background-color: transparent;
      color: #183B99;
      border: 1px solid transparent;
      outline: none; }
      .react-tagsinput-tag.clear:active .nama-icon, .react-tagsinput-tag.clear.active .nama-icon {
        fill: #183B99 !important; }
    .react-tagsinput-tag.clear:disabled, .react-tagsinput-tag.clear.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.clear:disabled .nama-icon, .react-tagsinput-tag.clear.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.default {
    background-color: #F6F7F8;
    border: 1px solid #F6F7F8;
    color: #737B80; }
    .react-tagsinput-tag.default .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.default:hover, .react-tagsinput-tag.default.hover {
      background-color: #F6F7F8;
      border: 1px solid #A0AAAF;
      color: #575E62; }
      .react-tagsinput-tag.default:hover .nama-icon, .react-tagsinput-tag.default.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.default.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.default.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.default:focus, .react-tagsinput-tag.default.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      .react-tagsinput-tag.default:focus .nama-icon, .react-tagsinput-tag.default.focus .nama-icon {
        fill: #575E62 !important; }
    .react-tagsinput-tag.default:active, .react-tagsinput-tag.default.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      .react-tagsinput-tag.default:active .nama-icon, .react-tagsinput-tag.default.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.default:disabled, .react-tagsinput-tag.default.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.default:disabled .nama-icon, .react-tagsinput-tag.default.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.primary {
    background-color: #2962FF;
    border: 1px solid #2962FF;
    color: #ffffff; }
    .react-tagsinput-tag.primary .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.primary:hover, .react-tagsinput-tag.primary.hover {
      background-color: #41A2F0;
      border: 1px solid #41A2F0;
      color: #ffffff; }
      .react-tagsinput-tag.primary:hover .nama-icon, .react-tagsinput-tag.primary.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.primary.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.primary.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:focus, .react-tagsinput-tag.primary.focus {
      background-color: #2962FF;
      color: #ffffff;
      border: 1px solid #2962FF;
      outline: none; }
      .react-tagsinput-tag.primary:focus .nama-icon, .react-tagsinput-tag.primary.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:active, .react-tagsinput-tag.primary.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      .react-tagsinput-tag.primary:active .nama-icon, .react-tagsinput-tag.primary.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:disabled, .react-tagsinput-tag.primary.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.primary:disabled .nama-icon, .react-tagsinput-tag.primary.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.info {
    background-color: #8D25AA;
    border: 1px solid #8D25AA;
    color: #ffffff; }
    .react-tagsinput-tag.info .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.info:hover, .react-tagsinput-tag.info.hover {
      background-color: #BA68C8;
      border: 1px solid #BA68C8;
      color: #ffffff; }
      .react-tagsinput-tag.info:hover .nama-icon, .react-tagsinput-tag.info.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.info.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.info.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:focus, .react-tagsinput-tag.info.focus {
      background-color: #8D25AA;
      color: #ffffff;
      border: 1px solid #8D25AA;
      outline: none; }
      .react-tagsinput-tag.info:focus .nama-icon, .react-tagsinput-tag.info.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:active, .react-tagsinput-tag.info.active {
      background-color: #49148B;
      color: #ffffff;
      border: 1px solid #49148B;
      outline: none; }
      .react-tagsinput-tag.info:active .nama-icon, .react-tagsinput-tag.info.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:disabled, .react-tagsinput-tag.info.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.info:disabled .nama-icon, .react-tagsinput-tag.info.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.success {
    background-color: #00BFA5;
    border: 1px solid #00BFA5;
    color: #ffffff; }
    .react-tagsinput-tag.success .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.success:hover, .react-tagsinput-tag.success.hover {
      background-color: #2BD9C2;
      border: 1px solid #2BD9C2;
      color: #ffffff; }
      .react-tagsinput-tag.success:hover .nama-icon, .react-tagsinput-tag.success.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.success.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.success.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:focus, .react-tagsinput-tag.success.focus {
      background-color: #00BFA5;
      color: #ffffff;
      border: 1px solid #00BFA5;
      outline: none; }
      .react-tagsinput-tag.success:focus .nama-icon, .react-tagsinput-tag.success.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:active, .react-tagsinput-tag.success.active {
      background-color: #00806E;
      color: #ffffff;
      border: 1px solid #00806E;
      outline: none; }
      .react-tagsinput-tag.success:active .nama-icon, .react-tagsinput-tag.success.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:disabled, .react-tagsinput-tag.success.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.success:disabled .nama-icon, .react-tagsinput-tag.success.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.warning {
    background-color: #FFB300;
    border: 1px solid #FFB300;
    color: #ffffff; }
    .react-tagsinput-tag.warning .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.warning:hover, .react-tagsinput-tag.warning.hover {
      background-color: #FFD166;
      border: 1px solid #FFD166;
      color: #ffffff; }
      .react-tagsinput-tag.warning:hover .nama-icon, .react-tagsinput-tag.warning.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.warning.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.warning.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:focus, .react-tagsinput-tag.warning.focus {
      background-color: #FFB300;
      color: #ffffff;
      border: 1px solid #FFB300;
      outline: none; }
      .react-tagsinput-tag.warning:focus .nama-icon, .react-tagsinput-tag.warning.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:active, .react-tagsinput-tag.warning.active {
      background-color: #B37D00;
      color: #ffffff;
      border: 1px solid #B37D00;
      outline: none; }
      .react-tagsinput-tag.warning:active .nama-icon, .react-tagsinput-tag.warning.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:disabled, .react-tagsinput-tag.warning.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.warning:disabled .nama-icon, .react-tagsinput-tag.warning.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.danger {
    background-color: #F50057;
    border: 1px solid #F50057;
    color: #ffffff; }
    .react-tagsinput-tag.danger .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.danger:hover, .react-tagsinput-tag.danger.hover {
      background-color: #F06292;
      border: 1px solid #F06292;
      color: #ffffff; }
      .react-tagsinput-tag.danger:hover .nama-icon, .react-tagsinput-tag.danger.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.danger.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.danger.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:focus, .react-tagsinput-tag.danger.focus {
      background-color: #F50057;
      color: #ffffff;
      border: 1px solid #F50057;
      outline: none; }
      .react-tagsinput-tag.danger:focus .nama-icon, .react-tagsinput-tag.danger.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:active, .react-tagsinput-tag.danger.active {
      background-color: #870E4F;
      color: #ffffff;
      border: 1px solid #870E4F;
      outline: none; }
      .react-tagsinput-tag.danger:active .nama-icon, .react-tagsinput-tag.danger.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:disabled, .react-tagsinput-tag.danger.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.danger:disabled .nama-icon, .react-tagsinput-tag.danger.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.icon-white .nama-icon {
    fill: #FCFDFF; }
  .react-tagsinput-tag.icon-white:hover .nama-icon, .react-tagsinput-tag.icon-white.hover .nama-icon {
    fill: #FCFDFF; }
  .react-tagsinput-tag.icon-white.selected .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:focus .nama-icon, .react-tagsinput-tag.icon-white.focus .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:active .nama-icon, .react-tagsinput-tag.icon-white.active .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:disabled .nama-icon, .react-tagsinput-tag.icon-white.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-clear .nama-icon {
    fill: transparent; }
  .react-tagsinput-tag.icon-clear:hover .nama-icon, .react-tagsinput-tag.icon-clear.hover .nama-icon {
    fill: transparent; }
  .react-tagsinput-tag.icon-clear.selected .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:focus .nama-icon, .react-tagsinput-tag.icon-clear.focus .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:active .nama-icon, .react-tagsinput-tag.icon-clear.active .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:disabled .nama-icon, .react-tagsinput-tag.icon-clear.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-default .nama-icon {
    fill: #F6F7F8; }
  .react-tagsinput-tag.icon-default:hover .nama-icon, .react-tagsinput-tag.icon-default.hover .nama-icon {
    fill: #F6F7F8; }
  .react-tagsinput-tag.icon-default.selected .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:focus .nama-icon, .react-tagsinput-tag.icon-default.focus .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:active .nama-icon, .react-tagsinput-tag.icon-default.active .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:disabled .nama-icon, .react-tagsinput-tag.icon-default.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-primary .nama-icon {
    fill: #2962FF; }
  .react-tagsinput-tag.icon-primary:hover .nama-icon, .react-tagsinput-tag.icon-primary.hover .nama-icon {
    fill: #2962FF; }
  .react-tagsinput-tag.icon-primary.selected .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:focus .nama-icon, .react-tagsinput-tag.icon-primary.focus .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:active .nama-icon, .react-tagsinput-tag.icon-primary.active .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:disabled .nama-icon, .react-tagsinput-tag.icon-primary.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-info .nama-icon {
    fill: #8D25AA; }
  .react-tagsinput-tag.icon-info:hover .nama-icon, .react-tagsinput-tag.icon-info.hover .nama-icon {
    fill: #8D25AA; }
  .react-tagsinput-tag.icon-info.selected .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:focus .nama-icon, .react-tagsinput-tag.icon-info.focus .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:active .nama-icon, .react-tagsinput-tag.icon-info.active .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:disabled .nama-icon, .react-tagsinput-tag.icon-info.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-success .nama-icon {
    fill: #00BFA5; }
  .react-tagsinput-tag.icon-success:hover .nama-icon, .react-tagsinput-tag.icon-success.hover .nama-icon {
    fill: #00BFA5; }
  .react-tagsinput-tag.icon-success.selected .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:focus .nama-icon, .react-tagsinput-tag.icon-success.focus .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:active .nama-icon, .react-tagsinput-tag.icon-success.active .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:disabled .nama-icon, .react-tagsinput-tag.icon-success.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-warning .nama-icon {
    fill: #FFB300; }
  .react-tagsinput-tag.icon-warning:hover .nama-icon, .react-tagsinput-tag.icon-warning.hover .nama-icon {
    fill: #FFB300; }
  .react-tagsinput-tag.icon-warning.selected .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:focus .nama-icon, .react-tagsinput-tag.icon-warning.focus .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:active .nama-icon, .react-tagsinput-tag.icon-warning.active .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:disabled .nama-icon, .react-tagsinput-tag.icon-warning.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-danger .nama-icon {
    fill: #F50057; }
  .react-tagsinput-tag.icon-danger:hover .nama-icon, .react-tagsinput-tag.icon-danger.hover .nama-icon {
    fill: #F50057; }
  .react-tagsinput-tag.icon-danger.selected .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:focus .nama-icon, .react-tagsinput-tag.icon-danger.focus .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:active .nama-icon, .react-tagsinput-tag.icon-danger.active .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:disabled .nama-icon, .react-tagsinput-tag.icon-danger.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    border: 1px solid #41A2F0; }
  .react-tagsinput-tag.clear:focus, .react-tagsinput-tag.clear.focus, .react-tagsinput-tag.default:focus, .react-tagsinput-tag.default.focus {
    border: 1px solid #41A2F0; }
  .react-tagsinput-tag.clear:disabled, .react-tagsinput-tag.clear.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #E3E8EB;
    border: 1px solid transparent;
    outline: none; }
    .react-tagsinput-tag.clear:disabled .nama-icon, .react-tagsinput-tag.clear.disabled .nama-icon {
      fill: #E3E8EB !important; }
  .react-tagsinput-tag .nama-icon {
    fill: #ffffff; }
  .react-tagsinput-tag:hover, .react-tagsinput-tag.hover {
    background-color: #41A2F0;
    border: 1px solid #41A2F0;
    color: #ffffff; }
    .react-tagsinput-tag:hover .nama-icon, .react-tagsinput-tag.hover .nama-icon {
      fill: #ffffff; }
  .react-tagsinput-tag.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    .react-tagsinput-tag.selected .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    background-color: #2962FF;
    color: #ffffff;
    border: 1px solid #2962FF;
    outline: none; }
    .react-tagsinput-tag:focus .nama-icon, .react-tagsinput-tag.focus .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:active, .react-tagsinput-tag.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    .react-tagsinput-tag:active .nama-icon, .react-tagsinput-tag.active .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:disabled, .react-tagsinput-tag.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    .react-tagsinput-tag:disabled .nama-icon, .react-tagsinput-tag.disabled .nama-icon {
      fill: #A0AAAF !important; }

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold; }

.react-tagsinput-tag a::before {
  content: " \D7";
  color: #FFF;
  margin: 8px; }

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #333;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 8px;
  outline: none;
  padding: 5px;
  width: 100%; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

fieldset {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  fieldset textarea {
    height: 80px !important; }
  fieldset label {
    color: #737B80;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    display: block;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    -moz-appearance: none;
         appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    -webkit-transition: border-color 400ms ease;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    fieldset input::-webkit-inner-spin-button, fieldset input::-webkit-clear-button,
    fieldset select::-webkit-inner-spin-button,
    fieldset select::-webkit-clear-button,
    fieldset textarea::-webkit-inner-spin-button,
    fieldset textarea::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    fieldset input::-webkit-input-placeholder, fieldset select::-webkit-input-placeholder, fieldset textarea::-webkit-input-placeholder {
      color: #A0AAAF; }
    fieldset input::-moz-placeholder, fieldset select::-moz-placeholder, fieldset textarea::-moz-placeholder {
      color: #A0AAAF; }
    fieldset input::-ms-input-placeholder, fieldset select::-ms-input-placeholder, fieldset textarea::-ms-input-placeholder {
      color: #A0AAAF; }
    fieldset input::placeholder,
    fieldset select::placeholder,
    fieldset textarea::placeholder {
      color: #A0AAAF; }
    fieldset input.compact,
    fieldset select.compact,
    fieldset textarea.compact {
      font-size: 13px;
      height: 36px;
      padding: 4px 16px; }
    fieldset input.transparent,
    fieldset select.transparent,
    fieldset textarea.transparent {
      border-color: transparent;
      background-color: transparent; }
    fieldset input:hover, fieldset input.hover,
    fieldset select:hover,
    fieldset select.hover,
    fieldset textarea:hover,
    fieldset textarea.hover {
      border-color: #737B80; }
      fieldset input:hover .nama-icon *, fieldset input.hover .nama-icon *,
      fieldset select:hover .nama-icon *,
      fieldset select.hover .nama-icon *,
      fieldset textarea:hover .nama-icon *,
      fieldset textarea.hover .nama-icon * {
        fill: #737B80; }
    fieldset input.ng-dirty.ng-touched.ng-invalid, fieldset input.error, fieldset input:invalid, fieldset input.invalid,
    fieldset select.ng-dirty.ng-touched.ng-invalid,
    fieldset select.error,
    fieldset select:invalid,
    fieldset select.invalid,
    fieldset textarea.ng-dirty.ng-touched.ng-invalid,
    fieldset textarea.error,
    fieldset textarea:invalid,
    fieldset textarea.invalid {
      box-shadow: none;
      border: 1px solid #F50057; }
      fieldset input.ng-dirty.ng-touched.ng-invalid .nama-icon *, fieldset input.error .nama-icon *, fieldset input:invalid .nama-icon *, fieldset input.invalid .nama-icon *,
      fieldset select.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset select.error .nama-icon *,
      fieldset select:invalid .nama-icon *,
      fieldset select.invalid .nama-icon *,
      fieldset textarea.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset textarea.error .nama-icon *,
      fieldset textarea:invalid .nama-icon *,
      fieldset textarea.invalid .nama-icon * {
        fill: #F50057; }
    fieldset input.success,
    fieldset select.success,
    fieldset textarea.success {
      border: 1px solid #00BFA5; }
      fieldset input.success .nama-icon *,
      fieldset select.success .nama-icon *,
      fieldset textarea.success .nama-icon * {
        fill: #00BFA5; }
    fieldset input.warning,
    fieldset select.warning,
    fieldset textarea.warning {
      border: 1px solid #FFB300; }
      fieldset input.warning .nama-icon *,
      fieldset select.warning .nama-icon *,
      fieldset textarea.warning .nama-icon * {
        fill: #FFB300; }
    fieldset input:focus, fieldset input.focus,
    fieldset select:focus,
    fieldset select.focus,
    fieldset textarea:focus,
    fieldset textarea.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      fieldset input:focus .nama-icon svg, fieldset input.focus .nama-icon svg,
      fieldset select:focus .nama-icon svg,
      fieldset select.focus .nama-icon svg,
      fieldset textarea:focus .nama-icon svg,
      fieldset textarea.focus .nama-icon svg {
        fill: #41A2F0 !important; }
        fieldset input:focus .nama-icon svg *, fieldset input.focus .nama-icon svg *,
        fieldset select:focus .nama-icon svg *,
        fieldset select.focus .nama-icon svg *,
        fieldset textarea:focus .nama-icon svg *,
        fieldset textarea.focus .nama-icon svg * {
          fill: #41A2F0 !important; }
    fieldset input:disabled,
    fieldset select:disabled,
    fieldset textarea:disabled {
      background-color: #D2DADE;
      border-color: #D2DADE;
      color: #A0AAAF;
      cursor: not-allowed; }
      fieldset input:disabled .nama-icon *,
      fieldset select:disabled .nama-icon *,
      fieldset textarea:disabled .nama-icon * {
        fill: #A0AAAF; }
  fieldset .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: #F8BBD0;
    border: 1px solid #F06292;
    border-radius: 4px;
    -webkit-transition: 0.2s height;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #C2185B;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block; }
  fieldset .nama-hint,
  fieldset .hint {
    display: block;
    font-size: 12px;
    color: #A0AAAF;
    margin: 0px 4px;
    margin-top: -4px; }
    fieldset .nama-hint a,
    fieldset .hint a {
      font-size: 1em; }

.nama-color-picker {
  -moz-appearance: none;
       appearance: none;
  background-color: #ffffff;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border: 1px solid #D2DADE;
  color: #737B80;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  -webkit-transition: border-color 400ms ease;
  transition: border-color 400ms ease;
  width: calc(100% - 8px);
  -webkit-appearance: none;
  outline: none;
  height: 44px;
  font-size: 14px;
  padding: 8px 16px;
  height: auto;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  border-radius: 8px; }
  .nama-color-picker::-webkit-inner-spin-button, .nama-color-picker::-webkit-clear-button {
    display: none;
    -webkit-appearance: none; }
  .nama-color-picker::-webkit-input-placeholder {
    color: #A0AAAF; }
  .nama-color-picker::-moz-placeholder {
    color: #A0AAAF; }
  .nama-color-picker::-ms-input-placeholder {
    color: #A0AAAF; }
  .nama-color-picker::placeholder {
    color: #A0AAAF; }
  .nama-color-picker.compact {
    font-size: 13px;
    height: 36px;
    padding: 4px 16px; }
  .nama-color-picker.transparent {
    border-color: transparent;
    background-color: transparent; }
  .nama-color-picker:hover, .nama-color-picker.hover {
    border-color: #737B80; }
    .nama-color-picker:hover .nama-icon *, .nama-color-picker.hover .nama-icon * {
      fill: #737B80; }
  .nama-color-picker.ng-dirty.ng-touched.ng-invalid, .nama-color-picker.error, .nama-color-picker:invalid, .nama-color-picker.invalid {
    box-shadow: none;
    border: 1px solid #F50057; }
    .nama-color-picker.ng-dirty.ng-touched.ng-invalid .nama-icon *, .nama-color-picker.error .nama-icon *, .nama-color-picker:invalid .nama-icon *, .nama-color-picker.invalid .nama-icon * {
      fill: #F50057; }
  .nama-color-picker.success {
    border: 1px solid #00BFA5; }
    .nama-color-picker.success .nama-icon * {
      fill: #00BFA5; }
  .nama-color-picker.warning {
    border: 1px solid #FFB300; }
    .nama-color-picker.warning .nama-icon * {
      fill: #FFB300; }
  .nama-color-picker:focus, .nama-color-picker.focus {
    border-color: #41A2F0 !important;
    outline: none; }
    .nama-color-picker:focus .nama-icon svg, .nama-color-picker.focus .nama-icon svg {
      fill: #41A2F0 !important; }
      .nama-color-picker:focus .nama-icon svg *, .nama-color-picker.focus .nama-icon svg * {
        fill: #41A2F0 !important; }
  .nama-color-picker:disabled {
    background-color: #D2DADE;
    border-color: #D2DADE;
    color: #A0AAAF;
    cursor: not-allowed; }
    .nama-color-picker:disabled .nama-icon * {
      fill: #A0AAAF; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.message-bubble {
  font-family: "Source Sans Pro", "Open Sans", sans-serif !important;
  font-size: 16px;
  color: #575E62;
  border: 1px solid #D2DADE;
  padding: 8px 16px 8px 16px;
  background-color: #ffffff;
  max-width: 500px;
  margin: 2px 0;
  border-radius: 0 16px 16px 0; }
  .message-bubble.operator {
    color: #ffffff;
    background-color: #575E62; }
  .message-bubble.bot {
    color: #ffffff;
    background-color: #2962FF; }
  .message-bubble:first-child {
    margin: 6px 0 2px 0;
    border-radius: 14px 16px 16px 4px; }
  .message-bubble:last-child {
    margin: 2px 0 6px 0;
    border-radius: 4px 16px 16px 14px; }
  .message-bubble:first-child:last-child {
    margin: 6px 0;
    border-radius: 16px 16px 16px 16px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.day-divider {
  margin-top: 16px;
  border-top: 1px solid #D2DADE;
  width: 100%; }
  .day-divider p {
    display: block;
    font-size: 13px;
    color: #35383A;
    position: relative;
    top: -50%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    padding: 0 4px;
    background-color: #ffffff;
    font-weight: normal; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.event-message {
  margin-left: 50px;
  display: flex;
  align-items: center; }
  .event-message .marker {
    width: 8px;
    height: 8px;
    margin: 0 8px;
    border-radius: 50%;
    background-color: #8D25AA; }
  .event-message p {
    margin: 0;
    text-transform: uppercase;
    color: #737B80;
    font-weight: 600;
    font-size: 12px; }

.content.infinite-scroll {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding: 32px 8px; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fade-in-up {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  -webkit-animation-name: bounceOutRight;
          animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-right {
  -webkit-animation-name: bounceInRight;
          animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  -webkit-animation-name: bounceInUp;
          animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }
@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  -webkit-animation-name: slideOutUp;
          animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }
@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  -webkit-animation-name: bounceOutUp;
          animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

.bounce-in-left {
  -webkit-animation-name: bounceInLeft;
          animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02);
            transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98);
            transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01);
            transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%);
            transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%);
            transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%);
            transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%);
            transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%);
            transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%);
            transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    -webkit-perspective: 1000;
            perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  nama-chat-avatar .back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.chat-beggining-message {
  padding-left: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .chat-beggining-message .nama-icon {
    margin-top: 5px;
    width: 32px;
    height: 32px; }
  .chat-beggining-message p {
    margin: 0;
    text-transform: uppercase;
    color: #A0AAAF;
    font-weight: 600;
    font-size: 18px; }

