@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

fieldset {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  fieldset textarea {
    height: 80px !important; }
  fieldset label {
    color: #737B80;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    display: block;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    fieldset input::-webkit-inner-spin-button, fieldset input::-webkit-clear-button,
    fieldset select::-webkit-inner-spin-button,
    fieldset select::-webkit-clear-button,
    fieldset textarea::-webkit-inner-spin-button,
    fieldset textarea::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    fieldset input::placeholder,
    fieldset select::placeholder,
    fieldset textarea::placeholder {
      color: #A0AAAF; }
    fieldset input.compact,
    fieldset select.compact,
    fieldset textarea.compact {
      font-size: 13px;
      height: 36px;
      padding: 4px 16px; }
    fieldset input.transparent,
    fieldset select.transparent,
    fieldset textarea.transparent {
      border-color: transparent;
      background-color: transparent; }
    fieldset input:hover, fieldset input.hover,
    fieldset select:hover,
    fieldset select.hover,
    fieldset textarea:hover,
    fieldset textarea.hover {
      border-color: #737B80; }
      fieldset input:hover .nama-icon *, fieldset input.hover .nama-icon *,
      fieldset select:hover .nama-icon *,
      fieldset select.hover .nama-icon *,
      fieldset textarea:hover .nama-icon *,
      fieldset textarea.hover .nama-icon * {
        fill: #737B80; }
    fieldset input.ng-dirty.ng-touched.ng-invalid, fieldset input.error, fieldset input:invalid, fieldset input.invalid,
    fieldset select.ng-dirty.ng-touched.ng-invalid,
    fieldset select.error,
    fieldset select:invalid,
    fieldset select.invalid,
    fieldset textarea.ng-dirty.ng-touched.ng-invalid,
    fieldset textarea.error,
    fieldset textarea:invalid,
    fieldset textarea.invalid {
      box-shadow: none;
      border: 1px solid #F50057; }
      fieldset input.ng-dirty.ng-touched.ng-invalid .nama-icon *, fieldset input.error .nama-icon *, fieldset input:invalid .nama-icon *, fieldset input.invalid .nama-icon *,
      fieldset select.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset select.error .nama-icon *,
      fieldset select:invalid .nama-icon *,
      fieldset select.invalid .nama-icon *,
      fieldset textarea.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset textarea.error .nama-icon *,
      fieldset textarea:invalid .nama-icon *,
      fieldset textarea.invalid .nama-icon * {
        fill: #F50057; }
    fieldset input.success,
    fieldset select.success,
    fieldset textarea.success {
      border: 1px solid #00BFA5; }
      fieldset input.success .nama-icon *,
      fieldset select.success .nama-icon *,
      fieldset textarea.success .nama-icon * {
        fill: #00BFA5; }
    fieldset input.warning,
    fieldset select.warning,
    fieldset textarea.warning {
      border: 1px solid #FFB300; }
      fieldset input.warning .nama-icon *,
      fieldset select.warning .nama-icon *,
      fieldset textarea.warning .nama-icon * {
        fill: #FFB300; }
    fieldset input:focus, fieldset input.focus,
    fieldset select:focus,
    fieldset select.focus,
    fieldset textarea:focus,
    fieldset textarea.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      fieldset input:focus .nama-icon svg, fieldset input.focus .nama-icon svg,
      fieldset select:focus .nama-icon svg,
      fieldset select.focus .nama-icon svg,
      fieldset textarea:focus .nama-icon svg,
      fieldset textarea.focus .nama-icon svg {
        fill: #41A2F0 !important; }
        fieldset input:focus .nama-icon svg *, fieldset input.focus .nama-icon svg *,
        fieldset select:focus .nama-icon svg *,
        fieldset select.focus .nama-icon svg *,
        fieldset textarea:focus .nama-icon svg *,
        fieldset textarea.focus .nama-icon svg * {
          fill: #41A2F0 !important; }
    fieldset input:disabled,
    fieldset select:disabled,
    fieldset textarea:disabled {
      background-color: #D2DADE;
      border-color: #D2DADE;
      color: #A0AAAF;
      cursor: not-allowed; }
      fieldset input:disabled .nama-icon *,
      fieldset select:disabled .nama-icon *,
      fieldset textarea:disabled .nama-icon * {
        fill: #A0AAAF; }
  fieldset .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: #F8BBD0;
    border: 1px solid #F06292;
    border-radius: 4px;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #C2185B;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block; }
  fieldset .nama-hint,
  fieldset .hint {
    display: block;
    font-size: 12px;
    color: #A0AAAF;
    margin: 0px 4px;
    margin-top: -4px; }
    fieldset .nama-hint a,
    fieldset .hint a {
      font-size: 1em; }

.nama-color-picker {
  appearance: none;
  background-color: #ffffff;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border: 1px solid #D2DADE;
  color: #737B80;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  transition: border-color 400ms ease;
  width: calc(100% - 8px);
  -webkit-appearance: none;
  outline: none;
  height: 44px;
  font-size: 14px;
  padding: 8px 16px;
  height: auto;
  width: 100%;
  overflow: hidden;
  padding: 0px;
  border-radius: 8px; }
  .nama-color-picker::-webkit-inner-spin-button, .nama-color-picker::-webkit-clear-button {
    display: none;
    -webkit-appearance: none; }
  .nama-color-picker::placeholder {
    color: #A0AAAF; }
  .nama-color-picker.compact {
    font-size: 13px;
    height: 36px;
    padding: 4px 16px; }
  .nama-color-picker.transparent {
    border-color: transparent;
    background-color: transparent; }
  .nama-color-picker:hover, .nama-color-picker.hover {
    border-color: #737B80; }
    .nama-color-picker:hover .nama-icon *, .nama-color-picker.hover .nama-icon * {
      fill: #737B80; }
  .nama-color-picker.ng-dirty.ng-touched.ng-invalid, .nama-color-picker.error, .nama-color-picker:invalid, .nama-color-picker.invalid {
    box-shadow: none;
    border: 1px solid #F50057; }
    .nama-color-picker.ng-dirty.ng-touched.ng-invalid .nama-icon *, .nama-color-picker.error .nama-icon *, .nama-color-picker:invalid .nama-icon *, .nama-color-picker.invalid .nama-icon * {
      fill: #F50057; }
  .nama-color-picker.success {
    border: 1px solid #00BFA5; }
    .nama-color-picker.success .nama-icon * {
      fill: #00BFA5; }
  .nama-color-picker.warning {
    border: 1px solid #FFB300; }
    .nama-color-picker.warning .nama-icon * {
      fill: #FFB300; }
  .nama-color-picker:focus, .nama-color-picker.focus {
    border-color: #41A2F0 !important;
    outline: none; }
    .nama-color-picker:focus .nama-icon svg, .nama-color-picker.focus .nama-icon svg {
      fill: #41A2F0 !important; }
      .nama-color-picker:focus .nama-icon svg *, .nama-color-picker.focus .nama-icon svg * {
        fill: #41A2F0 !important; }
  .nama-color-picker:disabled {
    background-color: #D2DADE;
    border-color: #D2DADE;
    color: #A0AAAF;
    cursor: not-allowed; }
    .nama-color-picker:disabled .nama-icon * {
      fill: #A0AAAF; }
