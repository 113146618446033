@import "../../styles/index.scss";

@mixin nama-input() {
  appearance: none;
  background-color: $white;
  font-family: $product-font-family;
  border: 1px solid $grey-500;
  color: $grey-700;
  border-radius: 5px; // box-shadow: $_form-box-shadow;
  box-sizing: border-box;
  margin: $base-spacing/2;
  margin-bottom: $base-spacing;
  border-radius: 4px;
  transition: border-color $base-duration $base-timing;
  width: calc(100% - 8px);
  -webkit-appearance: none;
  outline: none;

  height: 44px;
  font-size: 14px;
  padding: $base-spacing 2 * $base-spacing;

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }

  &::placeholder {
    color: $grey-600;
  }

  &.compact {
    font-size: 13px;
    height: 36px;
    padding: $base-spacing/2 2 * $base-spacing;
  }

  &.transparent {
    border-color: transparent;
    background-color: transparent;
  }

  &:hover,
  &.hover {
    border-color: $grey-700;

    .nama-icon {
      * {
        fill: $grey-700;
      }
    }
  }

  &.ng-dirty.ng-touched.ng-invalid,
  &.error,
  &:invalid,
  &.invalid {
    box-shadow: none;
    border: 1px solid $danger;

    .nama-icon {
      * {
        fill: $danger;
      }
    }
  }

  &.success {
    border: 1px solid $success;

    .nama-icon {
      * {
        fill: $success;
      }
    }
  }

  &.warning {
    border: 1px solid $warning;

    .nama-icon {
      * {
        fill: $warning;
      }
    }
  }

  &:focus,
  &.focus {
    border-color: $blue-200 !important;
    outline: none;

    .nama-icon svg {
      fill: $blue-200 !important;

      * {
        fill: $blue-200 !important;
      }
    }
  }

  &:disabled {
    background-color: $grey-500;
    border-color: $grey-500;
    color: $grey-600;
    cursor: not-allowed;

    .nama-icon {
      * {
        fill: $grey-600;
      }
    }

    &:hover {
      // border: map-get($product-set, base-border);
    }
  }
}

fieldset {
  font-family: $product-font-family;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 2 * $base-spacing;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  textarea {
    height: 80px !important;
  }

  label {
    color: $grey-700;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: $base-spacing/2;
  }

  input,
  select,
  textarea {
    display: block;
    font-family: $product-font-family;
    font-size: 14px;
    font-weight: 400;
    @include nama-input();
  }

  .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: $red-100;
    border: 1px solid $red-200;
    border-radius: 4px;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: $red-400;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block;
  }

  .nama-hint,
  .hint {
    display: block;
    font-size: 12px;
    color: $grey-600;
    margin: 0px 4px;
    margin-top: -4px;

    a {
      font-size: 1em;
    }
  }
}