.content.infinite-scroll {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding: 32px 8px;
  // scroll-behavior: smooth;
}
