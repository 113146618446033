// Nama`s Color Pallet

// DEFAULTS
$white: #ffffff;
$black: #000000;

// GREY SCALE
$grey-100: #FCFDFF;
$grey-200: #F6F7F8;
$grey-300: #EEF0F3;
$grey-400: #E3E8EB;
$grey-500: #D2DADE;
$grey-600: #A0AAAF;
$grey-700: #737B80;
$grey-800: #575E62;
$grey-900: #35383A;
$grey-1000: #252729;
$grey-1100: #121213;

// BLUES
$blue-100: #BBDCF7;
$blue-200: #41A2F0;
$blue-300: #2962FF;
$blue-400: #2753CC;
$blue-500: #183B99;

// GREEN
$green-100: #91F2E5;
$green-200: #2BD9C2;
$green-300: #00BFA5;
$green-400: #00A690;
$green-500: #00806E;

// ORANGE
$orange-100: #FFCCBC;
$orange-200: #FF8A65;
$orange-300: #FF7043;
$orange-400: #E64A19;
$orange-500: #BF360C;

// YELLOWS
$yellow-100: #FFF0CC;
$yellow-200: #FFD166;
$yellow-300: #FFB300;
$yellow-400: #E6A100;
$yellow-500: #B37D00;

// PURPLES
$purple-100: #E1BEE7;
$purple-200: #BA68C8;
$purple-300: #8D25AA;
$purple-400: #6A1C9A;
$purple-500: #49148B;

// REDS
$red-100: #F8BBD0;
$red-200: #F06292;
$red-300: #F50057;
$red-400: #C2185B;
$red-500: #870E4F;

// ALIAS
$primary: $blue-300;
$info: $purple-300;
$danger: $red-300;
$warning: $yellow-300;
$success: $green-300;

@mixin color-2-class($desc, $color) {
  .color-#{$desc} {
    color: $color !important
  }

  .background-#{$desc} {
    background: $color !important
  }

  .fill-#{$desc} {
    * {
      fill: $color !important
    }
  }
}

@mixin _colors() {

  @include color-2-class('primary', $primary);
  @include color-2-class('info', $info);
  @include color-2-class('danger', $danger);
  @include color-2-class('warning', $warning);
  @include color-2-class('success', $success);
  @include color-2-class('white', $white);
  @include color-2-class('black', $black);
  @include color-2-class('grey-100', $grey-100);
  @include color-2-class('grey-200', $grey-200);
  @include color-2-class('grey-300', $grey-300);
  @include color-2-class('grey-400', $grey-400);
  @include color-2-class('grey-500', $grey-500);
  @include color-2-class('grey-600', $grey-600);
  @include color-2-class('grey-700', $grey-700);
  @include color-2-class('grey-800', $grey-800);
  @include color-2-class('grey-900', $grey-900);
  @include color-2-class('grey-1000', $grey-1000);
  @include color-2-class('grey-1100', $grey-1100);
  @include color-2-class('green-100', $green-100);
  @include color-2-class('green-200', $green-200);
  @include color-2-class('green-300', $green-300);
  @include color-2-class('green-400', $green-400);
  @include color-2-class('green-500', $green-500);
  @include color-2-class('yellow-100', $yellow-100);
  @include color-2-class('yellow-200', $yellow-200);
  @include color-2-class('yellow-300', $yellow-300);
  @include color-2-class('yellow-400', $yellow-400);
  @include color-2-class('yellow-500', $yellow-500);
  @include color-2-class('orange-100', $orange-100);
  @include color-2-class('orange-200', $orange-200);
  @include color-2-class('orange-300', $orange-300);
  @include color-2-class('orange-400', $orange-400);
  @include color-2-class('orange-500', $orange-500);
  @include color-2-class('purple-100', $purple-100);
  @include color-2-class('purple-200', $purple-200);
  @include color-2-class('purple-300', $purple-300);
  @include color-2-class('purple-400', $purple-400);
  @include color-2-class('purple-500', $purple-500);
  @include color-2-class('blue-100', $blue-100);
  @include color-2-class('blue-200', $blue-200);
  @include color-2-class('blue-300', $blue-300);
  @include color-2-class('blue-400', $blue-400);
  @include color-2-class('blue-500', $blue-500);
  @include color-2-class('red-100', $red-100);
  @include color-2-class('red-200', $red-200);
  @include color-2-class('red-300', $red-300);
  @include color-2-class('red-400', $red-400);
  @include color-2-class('red-500', $red-500);
}


.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table;

  .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: $grey-200;
    border-radius: 0 0 8px 8px;

    .name,
    .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: $grey-600;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: $product-font-family;
    }

    .hash {
      float: right;
      text-align: right;
    }
  }
}