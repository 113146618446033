.filters {
  .nama-button {
    height: 32px;
    padding: 4px 16px;
    p {
      font-weight: 600;
      font-size: 12px;
    }
    .nama-icon {
      width: 16px;
    }
  }
  .nama-dropdown__list {
    right: 0;
  }
}
