@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up {
	animation-name: fadeInUp;
	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;
	animation-timing-function: ease-in-out;
	-webkit-animation-timing-function: ease-in-out;
	visibility: visible !important;
}
@keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }
    60% {

        transform: translate3d(25px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
				opacity: 1;
        transform: translate3d(5px, 0, 0);
    }
    to {
        transform: none;
    }
}

.bounce-in-left {
    animation-name: bounceInLeft;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;

}

@keyframes bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  }


.bounce-out-right {
    animation-name: bounceOutRight;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: hidden !important;
}

@keyframes bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}


.bounce-in-right {
    animation-name: bounceInRight;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes bounceInUp {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
    }
    75% {
        transform: translate3d(0, 10px, 0);
    }
    90% {
        transform: translate3d(0, -5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

.bounce-in-up {
    animation-name: bounceInUp;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade-out {
    animation-name: fadeOut;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    opacity: 0;
}


/*
==============================================
slideOutUp
==============================================
*/

@keyframes slideOutUp {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        transform: translate3d(0, -100%, 0);
    }
}

.slide-out-up {
    animation-name: slideOutUp;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}



/*
==============================================
fadeInUp
==============================================
*/

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}

/*
==============================================
fadeOutDown
==============================================
*/


@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}


/*
==============================================
fadeInLeft
==============================================
*/

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}


/*
==============================================
fadeInRight
==============================================
*/

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}


/*
==============================================
fadeOutUp
==============================================
*/

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

/*
==============================================
fadeInDown
==============================================
*/


@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible!important;
}


/*
==============================================
slideInDown
==============================================
*/


@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible!important;
}

/*
==============================================
fadeIn
==============================================
*/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}

/*
==============================================
slideInLeft
==============================================
*/


@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

/*
==============================================
slideInLeft
==============================================
*/

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}



/*
==============================================
fadeOut
==============================================
*/

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
/*
==============================================
bounceOutUp
==============================================
*/

@keyframes bounceOutUp {
    20% {
        transform: translate3d(0, -10px, 0);
    }
    40%,
    45% {
        opacity: 1;
        transform: translate3d(0, 20px, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(0, -2000px, 0);
    }
}

.bounce-out-up {
    animation-name: bounceOutUp;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: hidden !important;
}


/*
==============================================
bounceInLeft
==============================================
*/

@keyframes bounceInLeft {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
        opacity: 0;
        transform: translate3d(-3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(25px, 0, 0);
    }
    75% {
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        transform: translate3d(5px, 0, 0);
    }
    to {
        transform: none;
    }
}

.bounce-in-left {
    animation-name: bounceInLeft;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}


/*
==============================================
stretchLeft
==============================================
*/

.stretchLeft {
    animation-name: stretchLeft;
    -webkit-animation-name: stretchLeft;
    animation-duration: .5s;
    -webkit-animation-duration: .5s;
    animation-timing-function: ease-out;
    -webkit-animation-timing-function: ease-out;
    transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    -webkit-transform-origin: 100% 0%;
}

@keyframes stretchLeft {
    0% {
        transform: scaleX(0.3);
    }
    40% {
        transform: scaleX(1.02);
    }
    60% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(0.98);
    }
    80% {
        transform: scaleX(1.01);
    }
    100% {
        transform: scaleX(1);
    }
}

@-webkit-keyframes stretchLeft {
    0% {
        -webkit-transform: scaleX(0.3);
    }
    40% {
        -webkit-transform: scaleX(1.02);
    }
    60% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(0.98);
    }
    80% {
        -webkit-transform: scaleX(1.01);
    }
    100% {
        -webkit-transform: scaleX(1);
    }
}


/*
==============================================
slideUp
==============================================
*/

.slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
    visibility: visible !important;
}

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }
    50% {
        transform: translateY(-8%);
    }
    65% {
        transform: translateY(4%);
    }
    80% {
        transform: translateY(-4%);
    }
    95% {
        transform: translateY(2%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(100%);
    }
    50% {
        -webkit-transform: translateY(-8%);
    }
    65% {
        -webkit-transform: translateY(4%);
    }
    80% {
        -webkit-transform: translateY(-4%);
    }
    95% {
        -webkit-transform: translateY(2%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}


/*
==============================================
tossing
==============================================
*/

.shake-box {
    animation-name: tossing;
    -webkit-animation-name: tossing;
    display: block;
    animation-duration: .6s;
    -webkit-animation-duration: .6s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {
        transform: rotate(-24deg);
    }
    50% {
        transform: rotate(24deg);
    }
    100% {
        transform: rotate(-24deg);
    }
}

@-webkit-keyframes tossing {
    0% {
        -webkit-transform: rotate(-24deg);
    }
    50% {
        -webkit-transform: rotate(24deg);
    }
    100% {
        -webkit-transform: rotate(-24deg);
    }
}


/*
==============================================
slideLeft
==============================================
*/

.slideLeft {
    animation-name: slideLeft;
    -webkit-animation-name: slideLeft;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    visibility: visible !important;
}

@keyframes slideLeft {
    0% {
        transform: translateX(150%);
    }
    50% {
        transform: translateX(-8%);
    }
    65% {
        transform: translateX(4%);
    }
    80% {
        transform: translateX(-4%);
    }
    95% {
        transform: translateX(2%);
    }
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slideLeft {
    0% {
        -webkit-transform: translateX(150%);
    }
    50% {
        -webkit-transform: translateX(-8%);
    }
    65% {
        -webkit-transform: translateX(4%);
    }
    80% {
        -webkit-transform: translateX(-4%);
    }
    95% {
        -webkit-transform: translateX(2%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}
nama-chat-avatar{


  /* entire container, keeps perspective */

  .flip-container {
    perspective: 1000;
  }


  /* flip the pane when hovered */

  .flip-container.flip .flipper {
    transform: rotateY(180deg);
  }

  .flip-container,
  .front,
  .back {
    width: 50px;
    height: 50px;
    display: block;
  }


  /* flip speed goes here */

  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }


  /* hide back of pane during swap */

  .front,
  .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }


  /* front pane, placed above back */

  .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
  }


  /* back, initially hidden pane */

  .back {
    transform: rotateY(180deg);
  }

}



/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse;
}
