@mixin row() {
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  box-sizing: border-box;
  display: table !important;

  .exemple {
    background-color: $grey-400;
    border-radius: 4px;
    height: 42px;
    width: 100%;
    display: block;
  }

  .col {
    float: left;
    margin-left: $base-spacing;
    margin-right: $base-spacing;
    box-sizing: border-box;

    &.grey {
      background-color: $grey-400;
      border-radius: $base-spacing;
      padding: 0px $base-spacing;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &:first-child,
    &:last-child {
      &.col-9 {
        width: calc(75% - #{$base-spacing});
      }

      &.col-6 {
        width: calc(50% - #{$base-spacing});
      }

      &.col-3 {
        width: calc(25% - #{$base-spacing});
      }

      &.col-8 {
        width: calc(66.66% - #{$base-spacing});
      }

      &.col-4 {
        width: calc(33.33% - #{$base-spacing});
      }
    }

    &.col-12 {
      width: 100%;
    }

    &.col-9 {
      width: calc(75% - #{2 * $base-spacing});
    }

    &.col-6 {
      width: calc(50% - #{2 * $base-spacing});
    }

    &.col-3 {
      width: calc(25% - #{2 * $base-spacing});
    }

    &.col-8 {
      width: calc(66.66% - #{2 * $base-spacing});
    }

    &.col-4 {
      width: calc(33.33% - #{2 * $base-spacing});
    }
  }
}

div.nama-row,
section.nama-row {
  @include row();

  &.padded {
    padding: 8px calc(8.3% + 8px);
  }
}

section.nama-card-row {
  @include row();
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch;
}

section.nama-destacked-content {
  display: flex;
  flex-direction: column;
  clear: both;
  background: $grey-300;
  border-radius: 8px;
  padding: 10px;
  font-family: $product-font-family;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .nama-icon {
      width: 20px;
    }
  }
}

section.nama-content {
  width: 100%;
  min-height: calc(100vh - 127px);
  padding: 32px calc(8.3% + 8px);
  max-width: calc(1024px + 16.6%) !important;
  box-sizing: border-box;
  display: block;
  // overflow-y: auto;

  &.small {
    max-width: calc(724px + 16.6%) !important;
  }

  &.micro {
    max-width: calc(524px + 16.6%) !important;
  }

  &.center {
    padding-top: 64px;
    padding-bottom: 64px;
    margin: auto;
  }

  &.right {
    margin-left: auto;
  }
}

.ul-list {
  padding: $base-spacing 2 * $base-spacing;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  height: auto;
  display: table;
  list-style: none;
  font-family: $product-font-family;
  color: #737b80;
  font-weight: 700;

  li::before {
    content: "• ";
    color: $primary;
  }

  li {
    line-height: 24px;
  }
}

// TODO: Move this to a another file
@mixin subitens-intents {
  .intent {
    width: 50%;
    display: block;
    font-family: $product-font-family;
  }

  .volume {
    width: 20%;
    display: block;
    text-align: center;
    font-family: $product-font-family;
  }

  .percentage {
    width: 30%;
    display: block;
    text-align: center;
    font-family: $product-font-family;
  }
}

.analytics-intents {
  margin: 64px 32px;

  .analytics-intents-header {
    box-shadow: $depth-100;
    font-family: $product-font-family;
    color: $grey-600;
    text-transform: uppercase;
    background-color: $grey-200;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 32px;
  }

  .analytics-intents-title {
    padding: 16px 32px;

    .nama-icon {
      width: 22px;
      float: left;
      padding-right: 16px;
    }
  }

  .analytics-intents-subheader {
    background-color: $grey-300;
    height: 22px;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 32px;
    display: flex;
    align-content: space-between;

    @include subitens-intents;

    intent,
    volume,
    percentage {
      height: 22px;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      color: $grey-700;
    }
  }

  .analytics-intents-item {
    margin: 16px 0px;
    background-color: $white;
    border-radius: 8px;
    padding: 20px 32px;
    box-shadow: $depth-100;
    align-items: center;
    font-family: $product-font-family;
    display: flex;
    align-content: space-between;

    @include subitens-intents;

    intent,
    volume,
    percentage {
      min-height: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: $grey-800;
    }
  }
}

.operators-or-team-selector {
  width: 100%;
  box-shadow: $depth-100 !important;
  margin-bottom: 10px;

  .nama-button-old {
    .nama-icon svg * {
      transition: 0.2s fill;
    }
  }

  .nama-button-old:hover,
  .nama-button-old.option-selected {
    .nama-icon svg * {
      fill: $green-300 !important;
    }
  }
}

// Operators
.title-operators {
  display: block;
  background-image: linear-gradient(156deg, #41a2f0 0%, #91f2e6 100%);
  border: 1px solid #bbdcf7;
  font-family: $product-font-family;
  font-size: 14px;
  font-weight: 600;
  color: $white;
  letter-spacing: 0;
  line-height: 16px;
  padding: 6px 14px;
  border-radius: 20px;
  margin-bottom: 25px;
  text-transform: uppercase;

  &.orange-title {
    background-image: linear-gradient(143deg, #ff6c35 0%, #ff9e7c 100%);
    border: 1px solid #e3e8eb;
  }

  .nama-icon {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;

    svg path {
      fill: $white;
    }
  }
}
