@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

fieldset {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%; }
  fieldset textarea {
    height: 80px !important; }
  fieldset label {
    color: #737B80;
    margin: 0px 4px;
    display: block;
    font-weight: 300;
    margin-bottom: 4px; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    display: block;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    appearance: none;
    background-color: #ffffff;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    border: 1px solid #D2DADE;
    color: #737B80;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 4px;
    margin-bottom: 8px;
    border-radius: 4px;
    transition: border-color 400ms ease;
    width: calc(100% - 8px);
    -webkit-appearance: none;
    outline: none;
    height: 44px;
    font-size: 14px;
    padding: 8px 16px; }
    fieldset input::-webkit-inner-spin-button, fieldset input::-webkit-clear-button,
    fieldset select::-webkit-inner-spin-button,
    fieldset select::-webkit-clear-button,
    fieldset textarea::-webkit-inner-spin-button,
    fieldset textarea::-webkit-clear-button {
      display: none;
      -webkit-appearance: none; }
    fieldset input::placeholder,
    fieldset select::placeholder,
    fieldset textarea::placeholder {
      color: #A0AAAF; }
    fieldset input.compact,
    fieldset select.compact,
    fieldset textarea.compact {
      font-size: 13px;
      height: 36px;
      padding: 4px 16px; }
    fieldset input.transparent,
    fieldset select.transparent,
    fieldset textarea.transparent {
      border-color: transparent;
      background-color: transparent; }
    fieldset input:hover, fieldset input.hover,
    fieldset select:hover,
    fieldset select.hover,
    fieldset textarea:hover,
    fieldset textarea.hover {
      border-color: #737B80; }
      fieldset input:hover .nama-icon *, fieldset input.hover .nama-icon *,
      fieldset select:hover .nama-icon *,
      fieldset select.hover .nama-icon *,
      fieldset textarea:hover .nama-icon *,
      fieldset textarea.hover .nama-icon * {
        fill: #737B80; }
    fieldset input.ng-dirty.ng-touched.ng-invalid, fieldset input.error, fieldset input:invalid, fieldset input.invalid,
    fieldset select.ng-dirty.ng-touched.ng-invalid,
    fieldset select.error,
    fieldset select:invalid,
    fieldset select.invalid,
    fieldset textarea.ng-dirty.ng-touched.ng-invalid,
    fieldset textarea.error,
    fieldset textarea:invalid,
    fieldset textarea.invalid {
      box-shadow: none;
      border: 1px solid #F50057; }
      fieldset input.ng-dirty.ng-touched.ng-invalid .nama-icon *, fieldset input.error .nama-icon *, fieldset input:invalid .nama-icon *, fieldset input.invalid .nama-icon *,
      fieldset select.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset select.error .nama-icon *,
      fieldset select:invalid .nama-icon *,
      fieldset select.invalid .nama-icon *,
      fieldset textarea.ng-dirty.ng-touched.ng-invalid .nama-icon *,
      fieldset textarea.error .nama-icon *,
      fieldset textarea:invalid .nama-icon *,
      fieldset textarea.invalid .nama-icon * {
        fill: #F50057; }
    fieldset input.success,
    fieldset select.success,
    fieldset textarea.success {
      border: 1px solid #00BFA5; }
      fieldset input.success .nama-icon *,
      fieldset select.success .nama-icon *,
      fieldset textarea.success .nama-icon * {
        fill: #00BFA5; }
    fieldset input.warning,
    fieldset select.warning,
    fieldset textarea.warning {
      border: 1px solid #FFB300; }
      fieldset input.warning .nama-icon *,
      fieldset select.warning .nama-icon *,
      fieldset textarea.warning .nama-icon * {
        fill: #FFB300; }
    fieldset input:focus, fieldset input.focus,
    fieldset select:focus,
    fieldset select.focus,
    fieldset textarea:focus,
    fieldset textarea.focus {
      border-color: #41A2F0 !important;
      outline: none; }
      fieldset input:focus .nama-icon svg, fieldset input.focus .nama-icon svg,
      fieldset select:focus .nama-icon svg,
      fieldset select.focus .nama-icon svg,
      fieldset textarea:focus .nama-icon svg,
      fieldset textarea.focus .nama-icon svg {
        fill: #41A2F0 !important; }
        fieldset input:focus .nama-icon svg *, fieldset input.focus .nama-icon svg *,
        fieldset select:focus .nama-icon svg *,
        fieldset select.focus .nama-icon svg *,
        fieldset textarea:focus .nama-icon svg *,
        fieldset textarea.focus .nama-icon svg * {
          fill: #41A2F0 !important; }
    fieldset input:disabled,
    fieldset select:disabled,
    fieldset textarea:disabled {
      background-color: #D2DADE;
      border-color: #D2DADE;
      color: #A0AAAF;
      cursor: not-allowed; }
      fieldset input:disabled .nama-icon *,
      fieldset select:disabled .nama-icon *,
      fieldset textarea:disabled .nama-icon * {
        fill: #A0AAAF; }
  fieldset .field-error-message {
    overflow: hidden;
    width: calc(100% - 8px);
    height: 0px;
    background: #F8BBD0;
    border: 1px solid #F06292;
    border-radius: 4px;
    transition: 0.2s height;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    color: #C2185B;
    letter-spacing: 0.11px;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    margin: -5px 4px 8px 4px;
    height: auto;
    display: block; }
  fieldset .nama-hint,
  fieldset .hint {
    display: block;
    font-size: 12px;
    color: #A0AAAF;
    margin: 0px 4px;
    margin-top: -4px; }
    fieldset .nama-hint a,
    fieldset .hint a {
      font-size: 1em; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-button-old.dropdown,
nama-button-old.dropdown {
  overflow: visible;
  position: relative; }
  .nama-button-old.dropdown .nama-icon,
  .nama-button-old.dropdown span,
  nama-button-old.dropdown .nama-icon,
  nama-button-old.dropdown span {
    float: left; }
  .nama-button-old.dropdown:hover .subactions,
  nama-button-old.dropdown:hover .subactions {
    display: flex;
    justify-content: center; }
  .nama-button-old.dropdown.huge .subactions,
  nama-button-old.dropdown.huge .subactions {
    margin-top: 52px; }
  .nama-button-old.dropdown.big .subactions,
  nama-button-old.dropdown.big .subactions {
    margin-top: 40px; }
  .nama-button-old.dropdown.medium .subactions,
  nama-button-old.dropdown.medium .subactions {
    margin-top: 32px; }
  .nama-button-old.dropdown.small .subactions,
  nama-button-old.dropdown.small .subactions {
    margin-top: 28px; }
  .nama-button-old.dropdown.tiny .subactions,
  nama-button-old.dropdown.tiny .subactions {
    margin-top: 20px; }
  .nama-button-old.dropdown .subactions,
  nama-button-old.dropdown .subactions {
    background-color: #FCFDFF;
    border: 1px solid #F6F7F8;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(87, 94, 98, 0.3);
    margin-top: 32px;
    display: none;
    position: absolute;
    flex-flow: column;
    z-index: 10000000;
    right: 0px;
    top: 3px; }
    .nama-button-old.dropdown .subactions .nama-button-old,
    nama-button-old.dropdown .subactions .nama-button-old {
      margin: 0px !important; }

nama-button-old,
.nama-button-old {
  font-weight: 400;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 4px;
  box-sizing: border-box;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  transition: all 400ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F6F7F8;
  border: 1px solid #F6F7F8;
  color: #737B80; }
  nama-button-old span,
  .nama-button-old span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  nama-button-old.avatar,
  .nama-button-old.avatar {
    padding-left: 8px !important; }
    nama-button-old.avatar .nama-avatar,
    .nama-button-old.avatar .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px; }
    nama-button-old.avatar span,
    .nama-button-old.avatar span {
      float: left;
      width: auto; }
    nama-button-old.avatar .nama-icon,
    .nama-button-old.avatar .nama-icon {
      margin-right: 8px;
      float: left; }
  nama-button-old.sub-menu-item,
  .nama-button-old.sub-menu-item {
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    letter-spacing: -0.06px;
    color: #575E62;
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 32px;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent; }
    nama-button-old.sub-menu-item:hover,
    .nama-button-old.sub-menu-item:hover {
      border-bottom: 1px solid #2753CC;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: #252729; }
    nama-button-old.sub-menu-item.active,
    .nama-button-old.sub-menu-item.active {
      border-bottom: 1px solid #2962FF;
      color: #575E62;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent; }
  nama-button-old.expanded,
  .nama-button-old.expanded {
    width: calc(100% - 8px); }
  nama-button-old.capitalize,
  .nama-button-old.capitalize {
    text-transform: capitalize; }
  nama-button-old.full,
  .nama-button-old.full {
    padding: 0px 32px; }
  nama-button-old.padded,
  .nama-button-old.padded {
    padding: 0px 16px; }
  nama-button-old.compact,
  .nama-button-old.compact {
    padding: 0px; }
  nama-button-old.icon .nama-icon,
  .nama-button-old.icon .nama-icon {
    display: inherit;
    margin-left: 0px; }
  nama-button-old.icon.left span,
  .nama-button-old.icon.left span {
    float: right; }
  nama-button-old.icon.left .nama-icon,
  .nama-button-old.icon.left .nama-icon {
    margin-right: 8px;
    float: left; }
  nama-button-old.icon.right span,
  .nama-button-old.icon.right span {
    float: left; }
  nama-button-old.icon.right .nama-icon,
  .nama-button-old.icon.right .nama-icon {
    margin-left: 8px;
    float: right; }
  nama-button-old.icon.center .nama-icon,
  .nama-button-old.icon.center .nama-icon {
    margin-left: auto;
    float: none;
    margin-right: auto; }
  nama-button-old span,
  .nama-button-old span {
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 34px;
    display: table; }
  nama-button-old.avatar span,
  .nama-button-old.avatar span {
    padding-right: 8px;
    width: calc(100% - 114px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    width: 20px;
    margin: 16px;
    margin-top: 7px;
    height: 20px; }
  nama-button-old.nama-submit,
  .nama-button-old.nama-submit {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 34px;
    height: 34px; }
  nama-button-old.huge,
  .nama-button-old.huge {
    height: 56px;
    font-size: 16px;
    line-height: 52px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.huge span,
    .nama-button-old.huge span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 54px;
      display: table; }
    nama-button-old.huge.avatar span,
    .nama-button-old.huge.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.huge .nama-icon,
    .nama-button-old.huge .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 17px;
      height: 20px; }
  nama-button-old.nama-submit.huge,
  .nama-button-old.nama-submit.huge {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 56px;
    height: 56px; }
  nama-button-old.big,
  .nama-button-old.big {
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.big span,
    .nama-button-old.big span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 42px;
      display: table; }
    nama-button-old.big.avatar span,
    .nama-button-old.big.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.big .nama-icon,
    .nama-button-old.big .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 11px;
      height: 20px; }
  nama-button-old.nama-submit.big,
  .nama-button-old.nama-submit.big {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 44px;
    height: 44px; }
  nama-button-old.medium,
  .nama-button-old.medium {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.medium span,
    .nama-button-old.medium span {
      width: calc(100% - 40px);
      font-size: 14px;
      line-height: 34px;
      display: table; }
    nama-button-old.medium.avatar span,
    .nama-button-old.medium.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.medium .nama-icon,
    .nama-button-old.medium .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 7px;
      height: 20px; }
  nama-button-old.nama-submit.medium,
  .nama-button-old.nama-submit.medium {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 36px;
    height: 36px; }
  nama-button-old.small,
  .nama-button-old.small {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.small span,
    .nama-button-old.small span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 30px;
      display: table; }
    nama-button-old.small.avatar span,
    .nama-button-old.small.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.small .nama-icon,
    .nama-button-old.small .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 7px;
      height: 16px; }
  nama-button-old.nama-submit.small,
  .nama-button-old.nama-submit.small {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    height: 32px; }
  nama-button-old.tiny,
  .nama-button-old.tiny {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px; }
    nama-button-old.tiny span,
    .nama-button-old.tiny span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 22px;
      display: table; }
    nama-button-old.tiny.avatar span,
    .nama-button-old.tiny.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    nama-button-old.tiny .nama-icon,
    .nama-button-old.tiny .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 3px;
      height: 16px; }
  nama-button-old.nama-submit.tiny,
  .nama-button-old.nama-submit.tiny {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    height: 24px; }
  nama-button-old .nama-icon,
  .nama-button-old .nama-icon {
    fill: #737B80; }
  nama-button-old:hover, nama-button-old.hover,
  .nama-button-old:hover,
  .nama-button-old.hover {
    background-color: #F6F7F8;
    border: 1px solid #A0AAAF;
    color: #575E62; }
    nama-button-old:hover .nama-icon, nama-button-old.hover .nama-icon,
    .nama-button-old:hover .nama-icon,
    .nama-button-old.hover .nama-icon {
      fill: #575E62; }
  nama-button-old.selected,
  .nama-button-old.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    nama-button-old.selected .nama-icon,
    .nama-button-old.selected .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    background-color: #F6F7F8;
    color: #575E62;
    border: 1px solid #F6F7F8;
    outline: none; }
    nama-button-old:focus .nama-icon, nama-button-old.focus .nama-icon,
    .nama-button-old:focus .nama-icon,
    .nama-button-old.focus .nama-icon {
      fill: #575E62 !important; }
  nama-button-old:active, nama-button-old.active,
  .nama-button-old:active,
  .nama-button-old.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    nama-button-old:active .nama-icon, nama-button-old.active .nama-icon,
    .nama-button-old:active .nama-icon,
    .nama-button-old.active .nama-icon {
      fill: #ffffff !important; }
  nama-button-old:disabled, nama-button-old.disabled,
  .nama-button-old:disabled,
  .nama-button-old.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    nama-button-old:disabled .nama-icon, nama-button-old.disabled .nama-icon,
    .nama-button-old:disabled .nama-icon,
    .nama-button-old.disabled .nama-icon {
      fill: #A0AAAF !important; }
  nama-button-old.white,
  .nama-button-old.white {
    background-color: #FCFDFF;
    border: 1px solid #FCFDFF;
    color: #737B80; }
    nama-button-old.white .nama-icon,
    .nama-button-old.white .nama-icon {
      fill: #737B80; }
    nama-button-old.white:hover, nama-button-old.white.hover,
    .nama-button-old.white:hover,
    .nama-button-old.white.hover {
      background-color: #F6F7F8;
      border: 1px solid #F6F7F8;
      color: #575E62; }
      nama-button-old.white:hover .nama-icon, nama-button-old.white.hover .nama-icon,
      .nama-button-old.white:hover .nama-icon,
      .nama-button-old.white.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.white.selected,
    .nama-button-old.white.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white.selected .nama-icon,
      .nama-button-old.white.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:focus, nama-button-old.white.focus,
    .nama-button-old.white:focus,
    .nama-button-old.white.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.white:focus .nama-icon, nama-button-old.white.focus .nama-icon,
      .nama-button-old.white:focus .nama-icon,
      .nama-button-old.white.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.white:active, nama-button-old.white.active,
    .nama-button-old.white:active,
    .nama-button-old.white.active {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.white:active .nama-icon, nama-button-old.white.active .nama-icon,
      .nama-button-old.white:active .nama-icon,
      .nama-button-old.white.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.white:disabled, nama-button-old.white.disabled,
    .nama-button-old.white:disabled,
    .nama-button-old.white.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.white:disabled .nama-icon, nama-button-old.white.disabled .nama-icon,
      .nama-button-old.white:disabled .nama-icon,
      .nama-button-old.white.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.clear,
  .nama-button-old.clear {
    background-color: transparent;
    border: 1px solid transparent;
    color: #737B80; }
    nama-button-old.clear .nama-icon,
    .nama-button-old.clear .nama-icon {
      fill: #737B80; }
    nama-button-old.clear:hover, nama-button-old.clear.hover,
    .nama-button-old.clear:hover,
    .nama-button-old.clear.hover {
      background-color: transparent;
      border: 1px solid transparent;
      color: #575E62; }
      nama-button-old.clear:hover .nama-icon, nama-button-old.clear.hover .nama-icon,
      .nama-button-old.clear:hover .nama-icon,
      .nama-button-old.clear.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.clear.selected,
    .nama-button-old.clear.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.clear.selected .nama-icon,
      .nama-button-old.clear.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.clear:focus, nama-button-old.clear.focus,
    .nama-button-old.clear:focus,
    .nama-button-old.clear.focus {
      background-color: transparent;
      color: #737B80;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:focus .nama-icon, nama-button-old.clear.focus .nama-icon,
      .nama-button-old.clear:focus .nama-icon,
      .nama-button-old.clear.focus .nama-icon {
        fill: #737B80 !important; }
    nama-button-old.clear:active, nama-button-old.clear.active,
    .nama-button-old.clear:active,
    .nama-button-old.clear.active {
      background-color: transparent;
      color: #183B99;
      border: 1px solid transparent;
      outline: none; }
      nama-button-old.clear:active .nama-icon, nama-button-old.clear.active .nama-icon,
      .nama-button-old.clear:active .nama-icon,
      .nama-button-old.clear.active .nama-icon {
        fill: #183B99 !important; }
    nama-button-old.clear:disabled, nama-button-old.clear.disabled,
    .nama-button-old.clear:disabled,
    .nama-button-old.clear.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
      .nama-button-old.clear:disabled .nama-icon,
      .nama-button-old.clear.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.default,
  .nama-button-old.default {
    background-color: #F6F7F8;
    border: 1px solid #F6F7F8;
    color: #737B80; }
    nama-button-old.default .nama-icon,
    .nama-button-old.default .nama-icon {
      fill: #737B80; }
    nama-button-old.default:hover, nama-button-old.default.hover,
    .nama-button-old.default:hover,
    .nama-button-old.default.hover {
      background-color: #F6F7F8;
      border: 1px solid #A0AAAF;
      color: #575E62; }
      nama-button-old.default:hover .nama-icon, nama-button-old.default.hover .nama-icon,
      .nama-button-old.default:hover .nama-icon,
      .nama-button-old.default.hover .nama-icon {
        fill: #575E62; }
    nama-button-old.default.selected,
    .nama-button-old.default.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.default.selected .nama-icon,
      .nama-button-old.default.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:focus, nama-button-old.default.focus,
    .nama-button-old.default:focus,
    .nama-button-old.default.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      nama-button-old.default:focus .nama-icon, nama-button-old.default.focus .nama-icon,
      .nama-button-old.default:focus .nama-icon,
      .nama-button-old.default.focus .nama-icon {
        fill: #575E62 !important; }
    nama-button-old.default:active, nama-button-old.default.active,
    .nama-button-old.default:active,
    .nama-button-old.default.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.default:active .nama-icon, nama-button-old.default.active .nama-icon,
      .nama-button-old.default:active .nama-icon,
      .nama-button-old.default.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.default:disabled, nama-button-old.default.disabled,
    .nama-button-old.default:disabled,
    .nama-button-old.default.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.default:disabled .nama-icon, nama-button-old.default.disabled .nama-icon,
      .nama-button-old.default:disabled .nama-icon,
      .nama-button-old.default.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.primary,
  .nama-button-old.primary {
    background-color: #2962FF;
    border: 1px solid #2962FF;
    color: #ffffff; }
    nama-button-old.primary .nama-icon,
    .nama-button-old.primary .nama-icon {
      fill: #ffffff; }
    nama-button-old.primary:hover, nama-button-old.primary.hover,
    .nama-button-old.primary:hover,
    .nama-button-old.primary.hover {
      background-color: #41A2F0;
      border: 1px solid #41A2F0;
      color: #ffffff; }
      nama-button-old.primary:hover .nama-icon, nama-button-old.primary.hover .nama-icon,
      .nama-button-old.primary:hover .nama-icon,
      .nama-button-old.primary.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.primary.selected,
    .nama-button-old.primary.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.primary.selected .nama-icon,
      .nama-button-old.primary.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:focus, nama-button-old.primary.focus,
    .nama-button-old.primary:focus,
    .nama-button-old.primary.focus {
      background-color: #2962FF;
      color: #ffffff;
      border: 1px solid #2962FF;
      outline: none; }
      nama-button-old.primary:focus .nama-icon, nama-button-old.primary.focus .nama-icon,
      .nama-button-old.primary:focus .nama-icon,
      .nama-button-old.primary.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:active, nama-button-old.primary.active,
    .nama-button-old.primary:active,
    .nama-button-old.primary.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      nama-button-old.primary:active .nama-icon, nama-button-old.primary.active .nama-icon,
      .nama-button-old.primary:active .nama-icon,
      .nama-button-old.primary.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.primary:disabled, nama-button-old.primary.disabled,
    .nama-button-old.primary:disabled,
    .nama-button-old.primary.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.primary:disabled .nama-icon, nama-button-old.primary.disabled .nama-icon,
      .nama-button-old.primary:disabled .nama-icon,
      .nama-button-old.primary.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.info,
  .nama-button-old.info {
    background-color: #8D25AA;
    border: 1px solid #8D25AA;
    color: #ffffff; }
    nama-button-old.info .nama-icon,
    .nama-button-old.info .nama-icon {
      fill: #ffffff; }
    nama-button-old.info:hover, nama-button-old.info.hover,
    .nama-button-old.info:hover,
    .nama-button-old.info.hover {
      background-color: #BA68C8;
      border: 1px solid #BA68C8;
      color: #ffffff; }
      nama-button-old.info:hover .nama-icon, nama-button-old.info.hover .nama-icon,
      .nama-button-old.info:hover .nama-icon,
      .nama-button-old.info.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.info.selected,
    .nama-button-old.info.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.info.selected .nama-icon,
      .nama-button-old.info.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:focus, nama-button-old.info.focus,
    .nama-button-old.info:focus,
    .nama-button-old.info.focus {
      background-color: #8D25AA;
      color: #ffffff;
      border: 1px solid #8D25AA;
      outline: none; }
      nama-button-old.info:focus .nama-icon, nama-button-old.info.focus .nama-icon,
      .nama-button-old.info:focus .nama-icon,
      .nama-button-old.info.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:active, nama-button-old.info.active,
    .nama-button-old.info:active,
    .nama-button-old.info.active {
      background-color: #49148B;
      color: #ffffff;
      border: 1px solid #49148B;
      outline: none; }
      nama-button-old.info:active .nama-icon, nama-button-old.info.active .nama-icon,
      .nama-button-old.info:active .nama-icon,
      .nama-button-old.info.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.info:disabled, nama-button-old.info.disabled,
    .nama-button-old.info:disabled,
    .nama-button-old.info.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.info:disabled .nama-icon, nama-button-old.info.disabled .nama-icon,
      .nama-button-old.info:disabled .nama-icon,
      .nama-button-old.info.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.success,
  .nama-button-old.success {
    background-color: #00BFA5;
    border: 1px solid #00BFA5;
    color: #ffffff; }
    nama-button-old.success .nama-icon,
    .nama-button-old.success .nama-icon {
      fill: #ffffff; }
    nama-button-old.success:hover, nama-button-old.success.hover,
    .nama-button-old.success:hover,
    .nama-button-old.success.hover {
      background-color: #2BD9C2;
      border: 1px solid #2BD9C2;
      color: #ffffff; }
      nama-button-old.success:hover .nama-icon, nama-button-old.success.hover .nama-icon,
      .nama-button-old.success:hover .nama-icon,
      .nama-button-old.success.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.success.selected,
    .nama-button-old.success.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.success.selected .nama-icon,
      .nama-button-old.success.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:focus, nama-button-old.success.focus,
    .nama-button-old.success:focus,
    .nama-button-old.success.focus {
      background-color: #00BFA5;
      color: #ffffff;
      border: 1px solid #00BFA5;
      outline: none; }
      nama-button-old.success:focus .nama-icon, nama-button-old.success.focus .nama-icon,
      .nama-button-old.success:focus .nama-icon,
      .nama-button-old.success.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:active, nama-button-old.success.active,
    .nama-button-old.success:active,
    .nama-button-old.success.active {
      background-color: #00806E;
      color: #ffffff;
      border: 1px solid #00806E;
      outline: none; }
      nama-button-old.success:active .nama-icon, nama-button-old.success.active .nama-icon,
      .nama-button-old.success:active .nama-icon,
      .nama-button-old.success.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.success:disabled, nama-button-old.success.disabled,
    .nama-button-old.success:disabled,
    .nama-button-old.success.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.success:disabled .nama-icon, nama-button-old.success.disabled .nama-icon,
      .nama-button-old.success:disabled .nama-icon,
      .nama-button-old.success.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.warning,
  .nama-button-old.warning {
    background-color: #FFB300;
    border: 1px solid #FFB300;
    color: #ffffff; }
    nama-button-old.warning .nama-icon,
    .nama-button-old.warning .nama-icon {
      fill: #ffffff; }
    nama-button-old.warning:hover, nama-button-old.warning.hover,
    .nama-button-old.warning:hover,
    .nama-button-old.warning.hover {
      background-color: #FFD166;
      border: 1px solid #FFD166;
      color: #ffffff; }
      nama-button-old.warning:hover .nama-icon, nama-button-old.warning.hover .nama-icon,
      .nama-button-old.warning:hover .nama-icon,
      .nama-button-old.warning.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.warning.selected,
    .nama-button-old.warning.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.warning.selected .nama-icon,
      .nama-button-old.warning.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:focus, nama-button-old.warning.focus,
    .nama-button-old.warning:focus,
    .nama-button-old.warning.focus {
      background-color: #FFB300;
      color: #ffffff;
      border: 1px solid #FFB300;
      outline: none; }
      nama-button-old.warning:focus .nama-icon, nama-button-old.warning.focus .nama-icon,
      .nama-button-old.warning:focus .nama-icon,
      .nama-button-old.warning.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:active, nama-button-old.warning.active,
    .nama-button-old.warning:active,
    .nama-button-old.warning.active {
      background-color: #B37D00;
      color: #ffffff;
      border: 1px solid #B37D00;
      outline: none; }
      nama-button-old.warning:active .nama-icon, nama-button-old.warning.active .nama-icon,
      .nama-button-old.warning:active .nama-icon,
      .nama-button-old.warning.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.warning:disabled, nama-button-old.warning.disabled,
    .nama-button-old.warning:disabled,
    .nama-button-old.warning.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.warning:disabled .nama-icon, nama-button-old.warning.disabled .nama-icon,
      .nama-button-old.warning:disabled .nama-icon,
      .nama-button-old.warning.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.danger,
  .nama-button-old.danger {
    background-color: #F50057;
    border: 1px solid #F50057;
    color: #ffffff; }
    nama-button-old.danger .nama-icon,
    .nama-button-old.danger .nama-icon {
      fill: #ffffff; }
    nama-button-old.danger:hover, nama-button-old.danger.hover,
    .nama-button-old.danger:hover,
    .nama-button-old.danger.hover {
      background-color: #F06292;
      border: 1px solid #F06292;
      color: #ffffff; }
      nama-button-old.danger:hover .nama-icon, nama-button-old.danger.hover .nama-icon,
      .nama-button-old.danger:hover .nama-icon,
      .nama-button-old.danger.hover .nama-icon {
        fill: #ffffff; }
    nama-button-old.danger.selected,
    .nama-button-old.danger.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      nama-button-old.danger.selected .nama-icon,
      .nama-button-old.danger.selected .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:focus, nama-button-old.danger.focus,
    .nama-button-old.danger:focus,
    .nama-button-old.danger.focus {
      background-color: #F50057;
      color: #ffffff;
      border: 1px solid #F50057;
      outline: none; }
      nama-button-old.danger:focus .nama-icon, nama-button-old.danger.focus .nama-icon,
      .nama-button-old.danger:focus .nama-icon,
      .nama-button-old.danger.focus .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:active, nama-button-old.danger.active,
    .nama-button-old.danger:active,
    .nama-button-old.danger.active {
      background-color: #870E4F;
      color: #ffffff;
      border: 1px solid #870E4F;
      outline: none; }
      nama-button-old.danger:active .nama-icon, nama-button-old.danger.active .nama-icon,
      .nama-button-old.danger:active .nama-icon,
      .nama-button-old.danger.active .nama-icon {
        fill: #ffffff !important; }
    nama-button-old.danger:disabled, nama-button-old.danger.disabled,
    .nama-button-old.danger:disabled,
    .nama-button-old.danger.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      nama-button-old.danger:disabled .nama-icon, nama-button-old.danger.disabled .nama-icon,
      .nama-button-old.danger:disabled .nama-icon,
      .nama-button-old.danger.disabled .nama-icon {
        fill: #A0AAAF !important; }
  nama-button-old.icon-white .nama-icon,
  .nama-button-old.icon-white .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white:hover .nama-icon, nama-button-old.icon-white.hover .nama-icon,
  .nama-button-old.icon-white:hover .nama-icon,
  .nama-button-old.icon-white.hover .nama-icon {
    fill: #FCFDFF; }
  nama-button-old.icon-white.selected .nama-icon,
  .nama-button-old.icon-white.selected .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:focus .nama-icon, nama-button-old.icon-white.focus .nama-icon,
  .nama-button-old.icon-white:focus .nama-icon,
  .nama-button-old.icon-white.focus .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:active .nama-icon, nama-button-old.icon-white.active .nama-icon,
  .nama-button-old.icon-white:active .nama-icon,
  .nama-button-old.icon-white.active .nama-icon {
    fill: #FCFDFF !important; }
  nama-button-old.icon-white:disabled .nama-icon, nama-button-old.icon-white.disabled .nama-icon,
  .nama-button-old.icon-white:disabled .nama-icon,
  .nama-button-old.icon-white.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-clear .nama-icon,
  .nama-button-old.icon-clear .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear:hover .nama-icon, nama-button-old.icon-clear.hover .nama-icon,
  .nama-button-old.icon-clear:hover .nama-icon,
  .nama-button-old.icon-clear.hover .nama-icon {
    fill: transparent; }
  nama-button-old.icon-clear.selected .nama-icon,
  .nama-button-old.icon-clear.selected .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:focus .nama-icon, nama-button-old.icon-clear.focus .nama-icon,
  .nama-button-old.icon-clear:focus .nama-icon,
  .nama-button-old.icon-clear.focus .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:active .nama-icon, nama-button-old.icon-clear.active .nama-icon,
  .nama-button-old.icon-clear:active .nama-icon,
  .nama-button-old.icon-clear.active .nama-icon {
    fill: transparent !important; }
  nama-button-old.icon-clear:disabled .nama-icon, nama-button-old.icon-clear.disabled .nama-icon,
  .nama-button-old.icon-clear:disabled .nama-icon,
  .nama-button-old.icon-clear.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-default .nama-icon,
  .nama-button-old.icon-default .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default:hover .nama-icon, nama-button-old.icon-default.hover .nama-icon,
  .nama-button-old.icon-default:hover .nama-icon,
  .nama-button-old.icon-default.hover .nama-icon {
    fill: #F6F7F8; }
  nama-button-old.icon-default.selected .nama-icon,
  .nama-button-old.icon-default.selected .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:focus .nama-icon, nama-button-old.icon-default.focus .nama-icon,
  .nama-button-old.icon-default:focus .nama-icon,
  .nama-button-old.icon-default.focus .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:active .nama-icon, nama-button-old.icon-default.active .nama-icon,
  .nama-button-old.icon-default:active .nama-icon,
  .nama-button-old.icon-default.active .nama-icon {
    fill: #F6F7F8 !important; }
  nama-button-old.icon-default:disabled .nama-icon, nama-button-old.icon-default.disabled .nama-icon,
  .nama-button-old.icon-default:disabled .nama-icon,
  .nama-button-old.icon-default.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-primary .nama-icon,
  .nama-button-old.icon-primary .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary:hover .nama-icon, nama-button-old.icon-primary.hover .nama-icon,
  .nama-button-old.icon-primary:hover .nama-icon,
  .nama-button-old.icon-primary.hover .nama-icon {
    fill: #2962FF; }
  nama-button-old.icon-primary.selected .nama-icon,
  .nama-button-old.icon-primary.selected .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:focus .nama-icon, nama-button-old.icon-primary.focus .nama-icon,
  .nama-button-old.icon-primary:focus .nama-icon,
  .nama-button-old.icon-primary.focus .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:active .nama-icon, nama-button-old.icon-primary.active .nama-icon,
  .nama-button-old.icon-primary:active .nama-icon,
  .nama-button-old.icon-primary.active .nama-icon {
    fill: #2962FF !important; }
  nama-button-old.icon-primary:disabled .nama-icon, nama-button-old.icon-primary.disabled .nama-icon,
  .nama-button-old.icon-primary:disabled .nama-icon,
  .nama-button-old.icon-primary.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-info .nama-icon,
  .nama-button-old.icon-info .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info:hover .nama-icon, nama-button-old.icon-info.hover .nama-icon,
  .nama-button-old.icon-info:hover .nama-icon,
  .nama-button-old.icon-info.hover .nama-icon {
    fill: #8D25AA; }
  nama-button-old.icon-info.selected .nama-icon,
  .nama-button-old.icon-info.selected .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:focus .nama-icon, nama-button-old.icon-info.focus .nama-icon,
  .nama-button-old.icon-info:focus .nama-icon,
  .nama-button-old.icon-info.focus .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:active .nama-icon, nama-button-old.icon-info.active .nama-icon,
  .nama-button-old.icon-info:active .nama-icon,
  .nama-button-old.icon-info.active .nama-icon {
    fill: #8D25AA !important; }
  nama-button-old.icon-info:disabled .nama-icon, nama-button-old.icon-info.disabled .nama-icon,
  .nama-button-old.icon-info:disabled .nama-icon,
  .nama-button-old.icon-info.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-success .nama-icon,
  .nama-button-old.icon-success .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success:hover .nama-icon, nama-button-old.icon-success.hover .nama-icon,
  .nama-button-old.icon-success:hover .nama-icon,
  .nama-button-old.icon-success.hover .nama-icon {
    fill: #00BFA5; }
  nama-button-old.icon-success.selected .nama-icon,
  .nama-button-old.icon-success.selected .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:focus .nama-icon, nama-button-old.icon-success.focus .nama-icon,
  .nama-button-old.icon-success:focus .nama-icon,
  .nama-button-old.icon-success.focus .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:active .nama-icon, nama-button-old.icon-success.active .nama-icon,
  .nama-button-old.icon-success:active .nama-icon,
  .nama-button-old.icon-success.active .nama-icon {
    fill: #00BFA5 !important; }
  nama-button-old.icon-success:disabled .nama-icon, nama-button-old.icon-success.disabled .nama-icon,
  .nama-button-old.icon-success:disabled .nama-icon,
  .nama-button-old.icon-success.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-warning .nama-icon,
  .nama-button-old.icon-warning .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning:hover .nama-icon, nama-button-old.icon-warning.hover .nama-icon,
  .nama-button-old.icon-warning:hover .nama-icon,
  .nama-button-old.icon-warning.hover .nama-icon {
    fill: #FFB300; }
  nama-button-old.icon-warning.selected .nama-icon,
  .nama-button-old.icon-warning.selected .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:focus .nama-icon, nama-button-old.icon-warning.focus .nama-icon,
  .nama-button-old.icon-warning:focus .nama-icon,
  .nama-button-old.icon-warning.focus .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:active .nama-icon, nama-button-old.icon-warning.active .nama-icon,
  .nama-button-old.icon-warning:active .nama-icon,
  .nama-button-old.icon-warning.active .nama-icon {
    fill: #FFB300 !important; }
  nama-button-old.icon-warning:disabled .nama-icon, nama-button-old.icon-warning.disabled .nama-icon,
  .nama-button-old.icon-warning:disabled .nama-icon,
  .nama-button-old.icon-warning.disabled .nama-icon {
    fill: background !important; }
  nama-button-old.icon-danger .nama-icon,
  .nama-button-old.icon-danger .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger:hover .nama-icon, nama-button-old.icon-danger.hover .nama-icon,
  .nama-button-old.icon-danger:hover .nama-icon,
  .nama-button-old.icon-danger.hover .nama-icon {
    fill: #F50057; }
  nama-button-old.icon-danger.selected .nama-icon,
  .nama-button-old.icon-danger.selected .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:focus .nama-icon, nama-button-old.icon-danger.focus .nama-icon,
  .nama-button-old.icon-danger:focus .nama-icon,
  .nama-button-old.icon-danger.focus .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:active .nama-icon, nama-button-old.icon-danger.active .nama-icon,
  .nama-button-old.icon-danger:active .nama-icon,
  .nama-button-old.icon-danger.active .nama-icon {
    fill: #F50057 !important; }
  nama-button-old.icon-danger:disabled .nama-icon, nama-button-old.icon-danger.disabled .nama-icon,
  .nama-button-old.icon-danger:disabled .nama-icon,
  .nama-button-old.icon-danger.disabled .nama-icon {
    fill: background !important; }
  nama-button-old:focus, nama-button-old.focus,
  .nama-button-old:focus,
  .nama-button-old.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:focus, nama-button-old.clear.focus, nama-button-old.default:focus, nama-button-old.default.focus,
  .nama-button-old.clear:focus,
  .nama-button-old.clear.focus,
  .nama-button-old.default:focus,
  .nama-button-old.default.focus {
    border: 1px solid #41A2F0; }
  nama-button-old.clear:disabled, nama-button-old.clear.disabled,
  .nama-button-old.clear:disabled,
  .nama-button-old.clear.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #E3E8EB;
    border: 1px solid transparent;
    outline: none; }
    nama-button-old.clear:disabled .nama-icon, nama-button-old.clear.disabled .nama-icon,
    .nama-button-old.clear:disabled .nama-icon,
    .nama-button-old.clear.disabled .nama-icon {
      fill: #E3E8EB !important; }

.react-tagsinput {
  appearance: none;
  background-color: #ffffff;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border: 1px solid #D2DADE;
  color: #737B80;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  transition: border-color 400ms ease;
  width: calc(100% - 8px);
  -webkit-appearance: none;
  outline: none;
  height: 44px;
  font-size: 14px;
  padding: 8px 16px;
  height: auto; }
  .react-tagsinput::-webkit-inner-spin-button, .react-tagsinput::-webkit-clear-button {
    display: none;
    -webkit-appearance: none; }
  .react-tagsinput::placeholder {
    color: #A0AAAF; }
  .react-tagsinput.compact {
    font-size: 13px;
    height: 36px;
    padding: 4px 16px; }
  .react-tagsinput.transparent {
    border-color: transparent;
    background-color: transparent; }
  .react-tagsinput:hover, .react-tagsinput.hover {
    border-color: #737B80; }
    .react-tagsinput:hover .nama-icon *, .react-tagsinput.hover .nama-icon * {
      fill: #737B80; }
  .react-tagsinput.ng-dirty.ng-touched.ng-invalid, .react-tagsinput.error, .react-tagsinput:invalid, .react-tagsinput.invalid {
    box-shadow: none;
    border: 1px solid #F50057; }
    .react-tagsinput.ng-dirty.ng-touched.ng-invalid .nama-icon *, .react-tagsinput.error .nama-icon *, .react-tagsinput:invalid .nama-icon *, .react-tagsinput.invalid .nama-icon * {
      fill: #F50057; }
  .react-tagsinput.success {
    border: 1px solid #00BFA5; }
    .react-tagsinput.success .nama-icon * {
      fill: #00BFA5; }
  .react-tagsinput.warning {
    border: 1px solid #FFB300; }
    .react-tagsinput.warning .nama-icon * {
      fill: #FFB300; }
  .react-tagsinput:focus, .react-tagsinput.focus {
    border-color: #41A2F0 !important;
    outline: none; }
    .react-tagsinput:focus .nama-icon svg, .react-tagsinput.focus .nama-icon svg {
      fill: #41A2F0 !important; }
      .react-tagsinput:focus .nama-icon svg *, .react-tagsinput.focus .nama-icon svg * {
        fill: #41A2F0 !important; }
  .react-tagsinput:disabled {
    background-color: #D2DADE;
    border-color: #D2DADE;
    color: #A0AAAF;
    cursor: not-allowed; }
    .react-tagsinput:disabled .nama-icon * {
      fill: #A0AAAF; }

.react-tagsinput--focused {
  border-color: #41A2F0 !important;
  outline: none; }

.react-tagsinput-tag {
  font-weight: 400;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer !important;
  display: inline-block;
  align-content: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  margin: 4px;
  box-sizing: border-box;
  padding: 8px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  transition: all 400ms ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #F6F7F8;
  border: 1px solid #F6F7F8;
  color: #737B80;
  background-color: #2962FF;
  border: 1px solid #2962FF;
  color: #ffffff; }
  .react-tagsinput-tag span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .react-tagsinput-tag.avatar {
    padding-left: 8px !important; }
    .react-tagsinput-tag.avatar .nama-avatar {
      float: left;
      margin-right: 16px;
      margin-top: 10px; }
    .react-tagsinput-tag.avatar span {
      float: left;
      width: auto; }
    .react-tagsinput-tag.avatar .nama-icon {
      margin-right: 8px;
      float: left; }
  .react-tagsinput-tag.sub-menu-item {
    font-size: 16px;
    line-height: 20px;
    margin-top: 32px;
    letter-spacing: -0.06px;
    color: #575E62;
    text-transform: capitalize;
    display: table;
    line-height: 43px;
    margin: 0px;
    margin-right: 32px;
    border-radius: 0px;
    border-color: 0px;
    background-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent; }
    .react-tagsinput-tag.sub-menu-item:hover {
      border-bottom: 1px solid #2753CC;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      background-color: transparent;
      color: #252729; }
    .react-tagsinput-tag.sub-menu-item.active {
      border-bottom: 1px solid #2962FF;
      color: #575E62;
      background-color: transparent;
      border-left-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent; }
  .react-tagsinput-tag.expanded {
    width: calc(100% - 8px); }
  .react-tagsinput-tag.capitalize {
    text-transform: capitalize; }
  .react-tagsinput-tag.full {
    padding: 0px 32px; }
  .react-tagsinput-tag.padded {
    padding: 0px 16px; }
  .react-tagsinput-tag.compact {
    padding: 0px; }
  .react-tagsinput-tag.icon .nama-icon {
    display: inherit;
    margin-left: 0px; }
  .react-tagsinput-tag.icon.left span {
    float: right; }
  .react-tagsinput-tag.icon.left .nama-icon {
    margin-right: 8px;
    float: left; }
  .react-tagsinput-tag.icon.right span {
    float: left; }
  .react-tagsinput-tag.icon.right .nama-icon {
    margin-left: 8px;
    float: right; }
  .react-tagsinput-tag.icon.center .nama-icon {
    margin-left: auto;
    float: none;
    margin-right: auto; }
  .react-tagsinput-tag span {
    width: calc(100% - 40px);
    font-size: 14px;
    line-height: 34px;
    display: table; }
  .react-tagsinput-tag.avatar span {
    padding-right: 8px;
    width: calc(100% - 114px);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  .react-tagsinput-tag .nama-icon {
    width: 20px;
    margin: 16px;
    margin-top: 7px;
    height: 20px; }
  .react-tagsinput-tag.nama-submit {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 34px;
    height: 34px; }
  .react-tagsinput-tag.huge {
    height: 56px;
    font-size: 16px;
    line-height: 52px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.huge span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 54px;
      display: table; }
    .react-tagsinput-tag.huge.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.huge .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 17px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.huge {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 56px;
    height: 56px; }
  .react-tagsinput-tag.big {
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.big span {
      width: calc(100% - 40px);
      font-size: 16px;
      line-height: 42px;
      display: table; }
    .react-tagsinput-tag.big.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.big .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 11px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.big {
    box-sizing: border-box;
    font-size: 16px;
    line-height: 44px;
    height: 44px; }
  .react-tagsinput-tag.medium {
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.medium span {
      width: calc(100% - 40px);
      font-size: 14px;
      line-height: 34px;
      display: table; }
    .react-tagsinput-tag.medium.avatar span {
      padding-right: 8px;
      width: calc(100% - 114px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.medium .nama-icon {
      width: 20px;
      margin: 16px;
      margin-top: 7px;
      height: 20px; }
  .react-tagsinput-tag.nama-submit.medium {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 36px;
    height: 36px; }
  .react-tagsinput-tag.small {
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.small span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 30px;
      display: table; }
    .react-tagsinput-tag.small.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.small .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 7px;
      height: 16px; }
  .react-tagsinput-tag.nama-submit.small {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 32px;
    height: 32px; }
  .react-tagsinput-tag.tiny {
    height: 24px;
    font-size: 12px;
    line-height: 24px;
    padding-top: 0px;
    padding-bottom: 0px; }
    .react-tagsinput-tag.tiny span {
      width: calc(100% - 36px);
      font-size: 12px;
      line-height: 22px;
      display: table; }
    .react-tagsinput-tag.tiny.avatar span {
      padding-right: 8px;
      width: calc(100% - 110px);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis; }
    .react-tagsinput-tag.tiny .nama-icon {
      width: 16px;
      margin: 16px;
      margin-top: 3px;
      height: 16px; }
  .react-tagsinput-tag.nama-submit.tiny {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 24px;
    height: 24px; }
  .react-tagsinput-tag .nama-icon {
    fill: #737B80; }
  .react-tagsinput-tag:hover, .react-tagsinput-tag.hover {
    background-color: #F6F7F8;
    border: 1px solid #A0AAAF;
    color: #575E62; }
    .react-tagsinput-tag:hover .nama-icon, .react-tagsinput-tag.hover .nama-icon {
      fill: #575E62; }
  .react-tagsinput-tag.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    .react-tagsinput-tag.selected .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    background-color: #F6F7F8;
    color: #575E62;
    border: 1px solid #F6F7F8;
    outline: none; }
    .react-tagsinput-tag:focus .nama-icon, .react-tagsinput-tag.focus .nama-icon {
      fill: #575E62 !important; }
  .react-tagsinput-tag:active, .react-tagsinput-tag.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    .react-tagsinput-tag:active .nama-icon, .react-tagsinput-tag.active .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:disabled, .react-tagsinput-tag.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    .react-tagsinput-tag:disabled .nama-icon, .react-tagsinput-tag.disabled .nama-icon {
      fill: #A0AAAF !important; }
  .react-tagsinput-tag.white {
    background-color: #FCFDFF;
    border: 1px solid #FCFDFF;
    color: #737B80; }
    .react-tagsinput-tag.white .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.white:hover, .react-tagsinput-tag.white.hover {
      background-color: #F6F7F8;
      border: 1px solid #F6F7F8;
      color: #575E62; }
      .react-tagsinput-tag.white:hover .nama-icon, .react-tagsinput-tag.white.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.white.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.white.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.white:focus, .react-tagsinput-tag.white.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      .react-tagsinput-tag.white:focus .nama-icon, .react-tagsinput-tag.white.focus .nama-icon {
        fill: #575E62 !important; }
    .react-tagsinput-tag.white:active, .react-tagsinput-tag.white.active {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.white:active .nama-icon, .react-tagsinput-tag.white.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.white:disabled, .react-tagsinput-tag.white.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.white:disabled .nama-icon, .react-tagsinput-tag.white.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.clear {
    background-color: transparent;
    border: 1px solid transparent;
    color: #737B80; }
    .react-tagsinput-tag.clear .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.clear:hover, .react-tagsinput-tag.clear.hover {
      background-color: transparent;
      border: 1px solid transparent;
      color: #575E62; }
      .react-tagsinput-tag.clear:hover .nama-icon, .react-tagsinput-tag.clear.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.clear.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.clear.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.clear:focus, .react-tagsinput-tag.clear.focus {
      background-color: transparent;
      color: #737B80;
      border: 1px solid transparent;
      outline: none; }
      .react-tagsinput-tag.clear:focus .nama-icon, .react-tagsinput-tag.clear.focus .nama-icon {
        fill: #737B80 !important; }
    .react-tagsinput-tag.clear:active, .react-tagsinput-tag.clear.active {
      background-color: transparent;
      color: #183B99;
      border: 1px solid transparent;
      outline: none; }
      .react-tagsinput-tag.clear:active .nama-icon, .react-tagsinput-tag.clear.active .nama-icon {
        fill: #183B99 !important; }
    .react-tagsinput-tag.clear:disabled, .react-tagsinput-tag.clear.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.clear:disabled .nama-icon, .react-tagsinput-tag.clear.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.default {
    background-color: #F6F7F8;
    border: 1px solid #F6F7F8;
    color: #737B80; }
    .react-tagsinput-tag.default .nama-icon {
      fill: #737B80; }
    .react-tagsinput-tag.default:hover, .react-tagsinput-tag.default.hover {
      background-color: #F6F7F8;
      border: 1px solid #A0AAAF;
      color: #575E62; }
      .react-tagsinput-tag.default:hover .nama-icon, .react-tagsinput-tag.default.hover .nama-icon {
        fill: #575E62; }
    .react-tagsinput-tag.default.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.default.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.default:focus, .react-tagsinput-tag.default.focus {
      background-color: #F6F7F8;
      color: #575E62;
      border: 1px solid #F6F7F8;
      outline: none; }
      .react-tagsinput-tag.default:focus .nama-icon, .react-tagsinput-tag.default.focus .nama-icon {
        fill: #575E62 !important; }
    .react-tagsinput-tag.default:active, .react-tagsinput-tag.default.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      .react-tagsinput-tag.default:active .nama-icon, .react-tagsinput-tag.default.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.default:disabled, .react-tagsinput-tag.default.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.default:disabled .nama-icon, .react-tagsinput-tag.default.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.primary {
    background-color: #2962FF;
    border: 1px solid #2962FF;
    color: #ffffff; }
    .react-tagsinput-tag.primary .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.primary:hover, .react-tagsinput-tag.primary.hover {
      background-color: #41A2F0;
      border: 1px solid #41A2F0;
      color: #ffffff; }
      .react-tagsinput-tag.primary:hover .nama-icon, .react-tagsinput-tag.primary.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.primary.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.primary.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:focus, .react-tagsinput-tag.primary.focus {
      background-color: #2962FF;
      color: #ffffff;
      border: 1px solid #2962FF;
      outline: none; }
      .react-tagsinput-tag.primary:focus .nama-icon, .react-tagsinput-tag.primary.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:active, .react-tagsinput-tag.primary.active {
      background-color: #183B99;
      color: #ffffff;
      border: 1px solid #183B99;
      outline: none; }
      .react-tagsinput-tag.primary:active .nama-icon, .react-tagsinput-tag.primary.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.primary:disabled, .react-tagsinput-tag.primary.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.primary:disabled .nama-icon, .react-tagsinput-tag.primary.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.info {
    background-color: #8D25AA;
    border: 1px solid #8D25AA;
    color: #ffffff; }
    .react-tagsinput-tag.info .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.info:hover, .react-tagsinput-tag.info.hover {
      background-color: #BA68C8;
      border: 1px solid #BA68C8;
      color: #ffffff; }
      .react-tagsinput-tag.info:hover .nama-icon, .react-tagsinput-tag.info.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.info.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.info.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:focus, .react-tagsinput-tag.info.focus {
      background-color: #8D25AA;
      color: #ffffff;
      border: 1px solid #8D25AA;
      outline: none; }
      .react-tagsinput-tag.info:focus .nama-icon, .react-tagsinput-tag.info.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:active, .react-tagsinput-tag.info.active {
      background-color: #49148B;
      color: #ffffff;
      border: 1px solid #49148B;
      outline: none; }
      .react-tagsinput-tag.info:active .nama-icon, .react-tagsinput-tag.info.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.info:disabled, .react-tagsinput-tag.info.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.info:disabled .nama-icon, .react-tagsinput-tag.info.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.success {
    background-color: #00BFA5;
    border: 1px solid #00BFA5;
    color: #ffffff; }
    .react-tagsinput-tag.success .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.success:hover, .react-tagsinput-tag.success.hover {
      background-color: #2BD9C2;
      border: 1px solid #2BD9C2;
      color: #ffffff; }
      .react-tagsinput-tag.success:hover .nama-icon, .react-tagsinput-tag.success.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.success.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.success.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:focus, .react-tagsinput-tag.success.focus {
      background-color: #00BFA5;
      color: #ffffff;
      border: 1px solid #00BFA5;
      outline: none; }
      .react-tagsinput-tag.success:focus .nama-icon, .react-tagsinput-tag.success.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:active, .react-tagsinput-tag.success.active {
      background-color: #00806E;
      color: #ffffff;
      border: 1px solid #00806E;
      outline: none; }
      .react-tagsinput-tag.success:active .nama-icon, .react-tagsinput-tag.success.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.success:disabled, .react-tagsinput-tag.success.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.success:disabled .nama-icon, .react-tagsinput-tag.success.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.warning {
    background-color: #FFB300;
    border: 1px solid #FFB300;
    color: #ffffff; }
    .react-tagsinput-tag.warning .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.warning:hover, .react-tagsinput-tag.warning.hover {
      background-color: #FFD166;
      border: 1px solid #FFD166;
      color: #ffffff; }
      .react-tagsinput-tag.warning:hover .nama-icon, .react-tagsinput-tag.warning.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.warning.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.warning.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:focus, .react-tagsinput-tag.warning.focus {
      background-color: #FFB300;
      color: #ffffff;
      border: 1px solid #FFB300;
      outline: none; }
      .react-tagsinput-tag.warning:focus .nama-icon, .react-tagsinput-tag.warning.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:active, .react-tagsinput-tag.warning.active {
      background-color: #B37D00;
      color: #ffffff;
      border: 1px solid #B37D00;
      outline: none; }
      .react-tagsinput-tag.warning:active .nama-icon, .react-tagsinput-tag.warning.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.warning:disabled, .react-tagsinput-tag.warning.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.warning:disabled .nama-icon, .react-tagsinput-tag.warning.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.danger {
    background-color: #F50057;
    border: 1px solid #F50057;
    color: #ffffff; }
    .react-tagsinput-tag.danger .nama-icon {
      fill: #ffffff; }
    .react-tagsinput-tag.danger:hover, .react-tagsinput-tag.danger.hover {
      background-color: #F06292;
      border: 1px solid #F06292;
      color: #ffffff; }
      .react-tagsinput-tag.danger:hover .nama-icon, .react-tagsinput-tag.danger.hover .nama-icon {
        fill: #ffffff; }
    .react-tagsinput-tag.danger.selected {
      background-color: #737B80;
      color: #ffffff;
      border: 1px solid #737B80;
      outline: none; }
      .react-tagsinput-tag.danger.selected .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:focus, .react-tagsinput-tag.danger.focus {
      background-color: #F50057;
      color: #ffffff;
      border: 1px solid #F50057;
      outline: none; }
      .react-tagsinput-tag.danger:focus .nama-icon, .react-tagsinput-tag.danger.focus .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:active, .react-tagsinput-tag.danger.active {
      background-color: #870E4F;
      color: #ffffff;
      border: 1px solid #870E4F;
      outline: none; }
      .react-tagsinput-tag.danger:active .nama-icon, .react-tagsinput-tag.danger.active .nama-icon {
        fill: #ffffff !important; }
    .react-tagsinput-tag.danger:disabled, .react-tagsinput-tag.danger.disabled {
      cursor: not-allowed !important;
      background-color: #E3E8EB;
      color: #A0AAAF;
      border: 1px solid #E3E8EB;
      outline: none; }
      .react-tagsinput-tag.danger:disabled .nama-icon, .react-tagsinput-tag.danger.disabled .nama-icon {
        fill: #A0AAAF !important; }
  .react-tagsinput-tag.icon-white .nama-icon {
    fill: #FCFDFF; }
  .react-tagsinput-tag.icon-white:hover .nama-icon, .react-tagsinput-tag.icon-white.hover .nama-icon {
    fill: #FCFDFF; }
  .react-tagsinput-tag.icon-white.selected .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:focus .nama-icon, .react-tagsinput-tag.icon-white.focus .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:active .nama-icon, .react-tagsinput-tag.icon-white.active .nama-icon {
    fill: #FCFDFF !important; }
  .react-tagsinput-tag.icon-white:disabled .nama-icon, .react-tagsinput-tag.icon-white.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-clear .nama-icon {
    fill: transparent; }
  .react-tagsinput-tag.icon-clear:hover .nama-icon, .react-tagsinput-tag.icon-clear.hover .nama-icon {
    fill: transparent; }
  .react-tagsinput-tag.icon-clear.selected .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:focus .nama-icon, .react-tagsinput-tag.icon-clear.focus .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:active .nama-icon, .react-tagsinput-tag.icon-clear.active .nama-icon {
    fill: transparent !important; }
  .react-tagsinput-tag.icon-clear:disabled .nama-icon, .react-tagsinput-tag.icon-clear.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-default .nama-icon {
    fill: #F6F7F8; }
  .react-tagsinput-tag.icon-default:hover .nama-icon, .react-tagsinput-tag.icon-default.hover .nama-icon {
    fill: #F6F7F8; }
  .react-tagsinput-tag.icon-default.selected .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:focus .nama-icon, .react-tagsinput-tag.icon-default.focus .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:active .nama-icon, .react-tagsinput-tag.icon-default.active .nama-icon {
    fill: #F6F7F8 !important; }
  .react-tagsinput-tag.icon-default:disabled .nama-icon, .react-tagsinput-tag.icon-default.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-primary .nama-icon {
    fill: #2962FF; }
  .react-tagsinput-tag.icon-primary:hover .nama-icon, .react-tagsinput-tag.icon-primary.hover .nama-icon {
    fill: #2962FF; }
  .react-tagsinput-tag.icon-primary.selected .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:focus .nama-icon, .react-tagsinput-tag.icon-primary.focus .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:active .nama-icon, .react-tagsinput-tag.icon-primary.active .nama-icon {
    fill: #2962FF !important; }
  .react-tagsinput-tag.icon-primary:disabled .nama-icon, .react-tagsinput-tag.icon-primary.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-info .nama-icon {
    fill: #8D25AA; }
  .react-tagsinput-tag.icon-info:hover .nama-icon, .react-tagsinput-tag.icon-info.hover .nama-icon {
    fill: #8D25AA; }
  .react-tagsinput-tag.icon-info.selected .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:focus .nama-icon, .react-tagsinput-tag.icon-info.focus .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:active .nama-icon, .react-tagsinput-tag.icon-info.active .nama-icon {
    fill: #8D25AA !important; }
  .react-tagsinput-tag.icon-info:disabled .nama-icon, .react-tagsinput-tag.icon-info.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-success .nama-icon {
    fill: #00BFA5; }
  .react-tagsinput-tag.icon-success:hover .nama-icon, .react-tagsinput-tag.icon-success.hover .nama-icon {
    fill: #00BFA5; }
  .react-tagsinput-tag.icon-success.selected .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:focus .nama-icon, .react-tagsinput-tag.icon-success.focus .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:active .nama-icon, .react-tagsinput-tag.icon-success.active .nama-icon {
    fill: #00BFA5 !important; }
  .react-tagsinput-tag.icon-success:disabled .nama-icon, .react-tagsinput-tag.icon-success.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-warning .nama-icon {
    fill: #FFB300; }
  .react-tagsinput-tag.icon-warning:hover .nama-icon, .react-tagsinput-tag.icon-warning.hover .nama-icon {
    fill: #FFB300; }
  .react-tagsinput-tag.icon-warning.selected .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:focus .nama-icon, .react-tagsinput-tag.icon-warning.focus .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:active .nama-icon, .react-tagsinput-tag.icon-warning.active .nama-icon {
    fill: #FFB300 !important; }
  .react-tagsinput-tag.icon-warning:disabled .nama-icon, .react-tagsinput-tag.icon-warning.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag.icon-danger .nama-icon {
    fill: #F50057; }
  .react-tagsinput-tag.icon-danger:hover .nama-icon, .react-tagsinput-tag.icon-danger.hover .nama-icon {
    fill: #F50057; }
  .react-tagsinput-tag.icon-danger.selected .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:focus .nama-icon, .react-tagsinput-tag.icon-danger.focus .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:active .nama-icon, .react-tagsinput-tag.icon-danger.active .nama-icon {
    fill: #F50057 !important; }
  .react-tagsinput-tag.icon-danger:disabled .nama-icon, .react-tagsinput-tag.icon-danger.disabled .nama-icon {
    fill: background !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    border: 1px solid #41A2F0; }
  .react-tagsinput-tag.clear:focus, .react-tagsinput-tag.clear.focus, .react-tagsinput-tag.default:focus, .react-tagsinput-tag.default.focus {
    border: 1px solid #41A2F0; }
  .react-tagsinput-tag.clear:disabled, .react-tagsinput-tag.clear.disabled {
    cursor: not-allowed;
    background-color: transparent;
    color: #E3E8EB;
    border: 1px solid transparent;
    outline: none; }
    .react-tagsinput-tag.clear:disabled .nama-icon, .react-tagsinput-tag.clear.disabled .nama-icon {
      fill: #E3E8EB !important; }
  .react-tagsinput-tag .nama-icon {
    fill: #ffffff; }
  .react-tagsinput-tag:hover, .react-tagsinput-tag.hover {
    background-color: #41A2F0;
    border: 1px solid #41A2F0;
    color: #ffffff; }
    .react-tagsinput-tag:hover .nama-icon, .react-tagsinput-tag.hover .nama-icon {
      fill: #ffffff; }
  .react-tagsinput-tag.selected {
    background-color: #737B80;
    color: #ffffff;
    border: 1px solid #737B80;
    outline: none; }
    .react-tagsinput-tag.selected .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:focus, .react-tagsinput-tag.focus {
    background-color: #2962FF;
    color: #ffffff;
    border: 1px solid #2962FF;
    outline: none; }
    .react-tagsinput-tag:focus .nama-icon, .react-tagsinput-tag.focus .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:active, .react-tagsinput-tag.active {
    background-color: #183B99;
    color: #ffffff;
    border: 1px solid #183B99;
    outline: none; }
    .react-tagsinput-tag:active .nama-icon, .react-tagsinput-tag.active .nama-icon {
      fill: #ffffff !important; }
  .react-tagsinput-tag:disabled, .react-tagsinput-tag.disabled {
    cursor: not-allowed !important;
    background-color: #E3E8EB;
    color: #A0AAAF;
    border: 1px solid #E3E8EB;
    outline: none; }
    .react-tagsinput-tag:disabled .nama-icon, .react-tagsinput-tag.disabled .nama-icon {
      fill: #A0AAAF !important; }

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold; }

.react-tagsinput-tag a::before {
  content: " ×";
  color: #FFF;
  margin: 8px; }

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #333;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 8px;
  outline: none;
  padding: 5px;
  width: 100%; }
