.nama-graph {
  font-family: $product-font-family;

  .nama-graph-y-tick {
    font-weight: 600;
    font-size: 12px;
    color: $grey-700;
    letter-spacing: 0;
    text-align: right;
  }

  .nama-graph-x-tick {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.15px;
    text-align: center;
  }

  .recharts-default-tooltip {
    background: $grey-100;
    box-shadow: $depth-500;
    font-weight: 600;
    font-size: 11px;
    color: $grey-700;
    letter-spacing: 0.06px;
    border-radius: 10px;
    line-height: 12px;
  }
}
