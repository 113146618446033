@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600");
.color-shower {
  border-radius: 8px 8px 16px 16px;
  width: calc(20% - 8px);
  height: 120px;
  float: left;
  margin: 4px;
  display: inline-table; }
  .color-shower .description {
    width: 100%;
    height: 30px;
    margin-top: 101px;
    background-color: #F6F7F8;
    border-radius: 0 0 8px 8px; }
    .color-shower .description .name,
    .color-shower .description .hash {
      width: 50%;
      padding: 4px;
      text-overflow: hidden;
      overflow: hidden;
      color: #A0AAAF;
      float: left;
      display: block;
      font-weight: 200;
      font-size: 10px;
      box-sizing: border-box;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .color-shower .description .hash {
      float: right;
      text-align: right; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fade-in-up {
  animation-name: fadeInUp;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    opacity: 1;
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.3s;
  -webkit-animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounce-out-right {
  animation-name: bounceOutRight;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-right {
  animation-name: bounceInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounce-in-up {
  animation-name: bounceInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  opacity: 0; }

/*
==============================================
slideOutUp
==============================================
*/
@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slide-out-up {
  animation-name: slideOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInUp
==============================================
*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutDown
==============================================
*/
@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInLeft
==============================================
*/
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeInRight
==============================================
*/
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOutUp
==============================================
*/
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
fadeInDown
==============================================
*/
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInDown
==============================================
*/
@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeIn
==============================================
*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
slideInLeft
==============================================
*/
@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

/*
==============================================
slideInLeft
==============================================
*/
@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
fadeOut
==============================================
*/
@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; }

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

/*
==============================================
bounceOutUp
==============================================
*/
@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounce-out-up {
  animation-name: bounceOutUp;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: hidden !important; }

/*
==============================================
bounceInLeft
==============================================
*/
@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounce-in-left {
  animation-name: bounceInLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: .5s;
  -webkit-animation-duration: .5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.shake-box {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  display: block;
  animation-duration: .6s;
  -webkit-animation-duration: .6s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-24deg); }
  50% {
    transform: rotate(24deg); }
  100% {
    transform: rotate(-24deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-24deg); }
  50% {
    -webkit-transform: rotate(24deg); }
  100% {
    -webkit-transform: rotate(-24deg); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

nama-chat-avatar {
  /* entire container, keeps perspective */
  /* flip the pane when hovered */
  /* flip speed goes here */
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  nama-chat-avatar .flip-container {
    perspective: 1000; }
  nama-chat-avatar .flip-container.flip .flipper {
    transform: rotateY(180deg); }
  nama-chat-avatar .flip-container,
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    width: 50px;
    height: 50px;
    display: block; }
  nama-chat-avatar .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
  nama-chat-avatar .front,
  nama-chat-avatar .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0; }
  nama-chat-avatar .front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg); }
  nama-chat-avatar .back {
    transform: rotateY(180deg); }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
  -webkit-animation-name: pulse;
  animation-iteration-count: infinite;
  animation-name: pulse; }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }

.nama-copy-value {
  margin-bottom: 8px;
  float: left;
  display: table;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  border-radius: 4px;
  color: #737B80;
  font-size: 12px;
  padding: 4px 16px;
  border: 1px solid #D2DADE;
  height: 24px;
  line-height: 22px;
  max-width: 100%;
  white-space: nowrap; }
  .nama-copy-value .nama-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    float: left;
    fill: #737B80; }

.nama-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  margin: 0 !important;
  z-index: 5; }
  .nama-overlay.show {
    display: block; }

.nama-form {
  display: block;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 14px;
  width: 100%; }
  .nama-form.inline {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
    max-width: none !important;
    align-items: flex-end; }
    .nama-form.inline input,
    .nama-form.inline select {
      margin-bottom: 0px; }
    .nama-form.inline .field-error-message {
      position: absolute;
      width: 163px;
      margin-top: 10px; }
    .nama-form.inline.actions,
    .nama-form.inline .actions {
      flex: 2;
      margin: 0px 0px;
      max-width: 80px;
      text-align: center;
      display: block;
      width: auto;
      display: block; }
      .nama-form.inline.actions .nama-button-old,
      .nama-form.inline .actions .nama-button-old {
        width: initial !important;
        float: left; }
      .nama-form.inline.actions span,
      .nama-form.inline .actions span {
        float: left; }
    .nama-form.inline span {
      line-height: 44px;
      margin: 0px 8px; }
    .nama-form.inline fieldset {
      flex: 1;
      margin: 0px;
      width: auto; }
      .nama-form.inline fieldset.small {
        max-width: 88px; }
      .nama-form.inline fieldset input,
      .nama-form.inline fieldset select {
        width: calc(100% - 8px); }
      .nama-form.inline fieldset .form-group {
        display: flex;
        flex-direction: row !important;
        padding: 0px;
        margin: 0px;
        align-items: flex-end; }
  .nama-form.actions,
  .nama-form .actions {
    margin-top: 16px;
    margin: 0px 4px;
    display: flex; }
    .nama-form.actions .nama-button-old,
    .nama-form .actions .nama-button-old {
      width: 100% !important;
      margin-right: 8px;
      width: auto;
      box-sizing: content-box; }
    .nama-form.actions .nama-button-old:last-child,
    .nama-form .actions .nama-button-old:last-child {
      margin-right: 0; }
  .nama-form p.form-group {
    display: flex; }
    .nama-form p.form-group input {
      margin-right: 8px; }

.nama-graph {
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  .nama-graph .nama-graph-y-tick {
    font-weight: 600;
    font-size: 12px;
    color: #737B80;
    letter-spacing: 0;
    text-align: right; }
  .nama-graph .nama-graph-x-tick {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.15px;
    text-align: center; }
  .nama-graph .recharts-default-tooltip {
    background: #FCFDFF;
    box-shadow: 0 24px 32px 0 rgba(87, 94, 98, 0.3);
    font-weight: 600;
    font-size: 11px;
    color: #737B80;
    letter-spacing: 0.06px;
    border-radius: 10px;
    line-height: 12px; }

div.nama-row,
section.nama-row {
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  box-sizing: border-box;
  display: table !important; }
  div.nama-row .exemple,
  section.nama-row .exemple {
    background-color: #E3E8EB;
    border-radius: 4px;
    height: 42px;
    width: 100%;
    display: block; }
  div.nama-row .col,
  section.nama-row .col {
    float: left;
    margin-left: 8px;
    margin-right: 8px;
    box-sizing: border-box; }
    div.nama-row .col.grey,
    section.nama-row .col.grey {
      background-color: #E3E8EB;
      border-radius: 8px;
      padding: 0px 8px; }
    div.nama-row .col:first-child,
    section.nama-row .col:first-child {
      margin-left: 0px; }
    div.nama-row .col:last-child,
    section.nama-row .col:last-child {
      margin-right: 0px; }
    div.nama-row .col:first-child.col-9, div.nama-row .col:last-child.col-9,
    section.nama-row .col:first-child.col-9,
    section.nama-row .col:last-child.col-9 {
      width: calc(75% - 8px); }
    div.nama-row .col:first-child.col-6, div.nama-row .col:last-child.col-6,
    section.nama-row .col:first-child.col-6,
    section.nama-row .col:last-child.col-6 {
      width: calc(50% - 8px); }
    div.nama-row .col:first-child.col-3, div.nama-row .col:last-child.col-3,
    section.nama-row .col:first-child.col-3,
    section.nama-row .col:last-child.col-3 {
      width: calc(25% - 8px); }
    div.nama-row .col:first-child.col-8, div.nama-row .col:last-child.col-8,
    section.nama-row .col:first-child.col-8,
    section.nama-row .col:last-child.col-8 {
      width: calc(66.66% - 8px); }
    div.nama-row .col:first-child.col-4, div.nama-row .col:last-child.col-4,
    section.nama-row .col:first-child.col-4,
    section.nama-row .col:last-child.col-4 {
      width: calc(33.33% - 8px); }
    div.nama-row .col.col-12,
    section.nama-row .col.col-12 {
      width: 100%; }
    div.nama-row .col.col-9,
    section.nama-row .col.col-9 {
      width: calc(75% - 16px); }
    div.nama-row .col.col-6,
    section.nama-row .col.col-6 {
      width: calc(50% - 16px); }
    div.nama-row .col.col-3,
    section.nama-row .col.col-3 {
      width: calc(25% - 16px); }
    div.nama-row .col.col-8,
    section.nama-row .col.col-8 {
      width: calc(66.66% - 16px); }
    div.nama-row .col.col-4,
    section.nama-row .col.col-4 {
      width: calc(33.33% - 16px); }
  div.nama-row.padded,
  section.nama-row.padded {
    padding: 8px calc(8.3% + 8px); }

section.nama-card-row {
  width: 100%;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  box-sizing: border-box;
  display: table !important;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: stretch; }
  section.nama-card-row .exemple {
    background-color: #E3E8EB;
    border-radius: 4px;
    height: 42px;
    width: 100%;
    display: block; }
  section.nama-card-row .col {
    float: left;
    margin-left: 8px;
    margin-right: 8px;
    box-sizing: border-box; }
    section.nama-card-row .col.grey {
      background-color: #E3E8EB;
      border-radius: 8px;
      padding: 0px 8px; }
    section.nama-card-row .col:first-child {
      margin-left: 0px; }
    section.nama-card-row .col:last-child {
      margin-right: 0px; }
    section.nama-card-row .col:first-child.col-9, section.nama-card-row .col:last-child.col-9 {
      width: calc(75% - 8px); }
    section.nama-card-row .col:first-child.col-6, section.nama-card-row .col:last-child.col-6 {
      width: calc(50% - 8px); }
    section.nama-card-row .col:first-child.col-3, section.nama-card-row .col:last-child.col-3 {
      width: calc(25% - 8px); }
    section.nama-card-row .col:first-child.col-8, section.nama-card-row .col:last-child.col-8 {
      width: calc(66.66% - 8px); }
    section.nama-card-row .col:first-child.col-4, section.nama-card-row .col:last-child.col-4 {
      width: calc(33.33% - 8px); }
    section.nama-card-row .col.col-12 {
      width: 100%; }
    section.nama-card-row .col.col-9 {
      width: calc(75% - 16px); }
    section.nama-card-row .col.col-6 {
      width: calc(50% - 16px); }
    section.nama-card-row .col.col-3 {
      width: calc(25% - 16px); }
    section.nama-card-row .col.col-8 {
      width: calc(66.66% - 16px); }
    section.nama-card-row .col.col-4 {
      width: calc(33.33% - 16px); }

section.nama-destacked-content {
  display: flex;
  flex-direction: column;
  clear: both;
  background: #EEF0F3;
  border-radius: 8px;
  padding: 10px;
  font-family: "Source Sans Pro", "Open Sans", sans-serif; }
  section.nama-destacked-content .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    section.nama-destacked-content .header .nama-icon {
      width: 20px; }

section.nama-content {
  width: 100%;
  min-height: calc(100vh - 127px);
  padding: 32px calc(8.3% + 8px);
  max-width: calc(1024px + 16.6%) !important;
  box-sizing: border-box;
  display: block; }
  section.nama-content.small {
    max-width: calc(724px + 16.6%) !important; }
  section.nama-content.micro {
    max-width: calc(524px + 16.6%) !important; }
  section.nama-content.center {
    padding-top: 64px;
    padding-bottom: 64px;
    margin: auto; }
  section.nama-content.right {
    margin-left: auto; }

.ul-list {
  padding: 8px 16px;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  height: auto;
  display: table;
  list-style: none;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  color: #737b80;
  font-weight: 700; }
  .ul-list li::before {
    content: "• ";
    color: #2962FF; }
  .ul-list li {
    line-height: 24px; }

.analytics-intents {
  margin: 64px 32px; }
  .analytics-intents .analytics-intents-header {
    box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25);
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    color: #A0AAAF;
    text-transform: uppercase;
    background-color: #F6F7F8;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 32px; }
  .analytics-intents .analytics-intents-title {
    padding: 16px 32px; }
    .analytics-intents .analytics-intents-title .nama-icon {
      width: 22px;
      float: left;
      padding-right: 16px; }
  .analytics-intents .analytics-intents-subheader {
    background-color: #EEF0F3;
    height: 22px;
    border-radius: 0px 0px 8px 8px;
    padding: 0px 32px;
    display: flex;
    align-content: space-between; }
    .analytics-intents .analytics-intents-subheader .intent {
      width: 50%;
      display: block;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader .volume {
      width: 20%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader .percentage {
      width: 30%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-subheader intent,
    .analytics-intents .analytics-intents-subheader volume,
    .analytics-intents .analytics-intents-subheader percentage {
      height: 22px;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      color: #737B80; }
  .analytics-intents .analytics-intents-item {
    margin: 16px 0px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px 32px;
    box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25);
    align-items: center;
    font-family: "Source Sans Pro", "Open Sans", sans-serif;
    display: flex;
    align-content: space-between; }
    .analytics-intents .analytics-intents-item .intent {
      width: 50%;
      display: block;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item .volume {
      width: 20%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item .percentage {
      width: 30%;
      display: block;
      text-align: center;
      font-family: "Source Sans Pro", "Open Sans", sans-serif; }
    .analytics-intents .analytics-intents-item intent,
    .analytics-intents .analytics-intents-item volume,
    .analytics-intents .analytics-intents-item percentage {
      min-height: 22px;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      color: #575E62; }

.operators-or-team-selector {
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(87, 94, 98, 0.25) !important;
  margin-bottom: 10px; }
  .operators-or-team-selector .nama-button-old .nama-icon svg * {
    transition: 0.2s fill; }
  .operators-or-team-selector .nama-button-old:hover .nama-icon svg *,
  .operators-or-team-selector .nama-button-old.option-selected .nama-icon svg * {
    fill: #00BFA5 !important; }

.title-operators {
  display: block;
  background-image: linear-gradient(156deg, #41a2f0 0%, #91f2e6 100%);
  border: 1px solid #bbdcf7;
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 16px;
  padding: 6px 14px;
  border-radius: 20px;
  margin-bottom: 25px;
  text-transform: uppercase; }
  .title-operators.orange-title {
    background-image: linear-gradient(143deg, #ff6c35 0%, #ff9e7c 100%);
    border: 1px solid #e3e8eb; }
  .title-operators .nama-icon {
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px; }
    .title-operators .nama-icon svg path {
      fill: #ffffff; }

.space-above {
  margin-top: 45px; }

.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 8.33333%;
  -moz-flex-basis: 8.33333%;
  -ms-flex-preferred-size: 8.33333%;
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 16.66667%;
  -moz-flex-basis: 16.66667%;
  -ms-flex-preferred-size: 16.66667%;
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 33.33333%;
  -moz-flex-basis: 33.33333%;
  -ms-flex-preferred-size: 33.33333%;
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 41.66667%;
  -moz-flex-basis: 41.66667%;
  -ms-flex-preferred-size: 41.66667%;
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 58.33333%;
  -moz-flex-basis: 58.33333%;
  -ms-flex-preferred-size: 58.33333%;
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 66.66667%;
  -moz-flex-basis: 66.66667%;
  -ms-flex-preferred-size: 66.66667%;
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 83.33333%;
  -moz-flex-basis: 83.33333%;
  -ms-flex-preferred-size: 83.33333%;
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 91.66667%;
  -moz-flex-basis: 91.66667%;
  -ms-flex-preferred-size: 91.66667%;
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 0; }

.col-xs-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.33333%;
    -moz-flex-basis: 8.33333%;
    -ms-flex-preferred-size: 8.33333%;
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.66667%;
    -moz-flex-basis: 16.66667%;
    -ms-flex-preferred-size: 16.66667%;
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.33333%;
    -moz-flex-basis: 33.33333%;
    -ms-flex-preferred-size: 33.33333%;
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.66667%;
    -moz-flex-basis: 41.66667%;
    -ms-flex-preferred-size: 41.66667%;
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.33333%;
    -moz-flex-basis: 58.33333%;
    -ms-flex-preferred-size: 58.33333%;
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.66667%;
    -moz-flex-basis: 66.66667%;
    -ms-flex-preferred-size: 66.66667%;
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.33333%;
    -moz-flex-basis: 83.33333%;
    -ms-flex-preferred-size: 83.33333%;
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.66667%;
    -moz-flex-basis: 91.66667%;
    -ms-flex-preferred-size: 91.66667%;
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.infinite-calendar.automation-calendar {
  position: absolute;
  right: 0px;
  box-shadow: 0 24px 32px 0 rgba(87, 94, 98, 0.3); }

p, code, span, a {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300; }

h1, h2, h3, h4, h5, h6, h7, h8, hr {
  font-family: "Source Sans Pro", "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0px; }
  h1.row, h2.row, h3.row, h4.row, h5.row, h6.row, h7.row, h8.row, hr.row {
    padding-top: 8px;
    border-top: 1px solid #D2DADE; }

h2, h3, h4, h7, h8 {
  font-weight: 300; }

h1, p {
  font-weight: 400; }

h5, h6 {
  font-weight: 500; }

h1 {
  font-size: 32px;
  line-height: 40px;
  margin-top: 48px;
  letter-spacing: -0.04px;
  color: #575E62; }

h2 {
  font-size: 24px;
  line-height: 28px;
  margin-top: 40px;
  letter-spacing: -0.04px;
  color: #575E62; }

h3 {
  font-size: 20px;
  line-height: 28px;
  margin-top: 36px;
  letter-spacing: -0.08px;
  color: #575E62; }

h4 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
  letter-spacing: -0.06px;
  color: #575E62; }

h5 {
  font-size: 14px;
  line-height: 16px;
  margin-top: 24px;
  letter-spacing: -0.04px;
  color: #575E62; }

h6 {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #A0AAAF; }

h7 {
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0px;
  color: #A0AAAF;
  display: block; }

h8 {
  font-size: 11px;
  line-height: 16px;
  margin-top: 20px;
  letter-spacing: 0.06px;
  color: #A0AAAF;
  display: block; }

p {
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
  letter-spacing: -0.04px;
  color: #575E62;
  font-weight: 300; }

a {
  color: #41A2F0;
  cursor: pointer;
  transition: color 400ms ease; }
  a:hover {
    color: #2962FF; }
  a:focus {
    outline: none; }

hr {
  border-bottom: 1px solid #A0AAAF;
  margin: 0px;
  border-left: 0;
  border-right: 0;
  border-top: 0; }

.color-primary {
  color: #2962FF !important; }

.background-primary {
  background: #2962FF !important; }

.fill-primary * {
  fill: #2962FF !important; }

.color-info {
  color: #8D25AA !important; }

.background-info {
  background: #8D25AA !important; }

.fill-info * {
  fill: #8D25AA !important; }

.color-danger {
  color: #F50057 !important; }

.background-danger {
  background: #F50057 !important; }

.fill-danger * {
  fill: #F50057 !important; }

.color-warning {
  color: #FFB300 !important; }

.background-warning {
  background: #FFB300 !important; }

.fill-warning * {
  fill: #FFB300 !important; }

.color-success {
  color: #00BFA5 !important; }

.background-success {
  background: #00BFA5 !important; }

.fill-success * {
  fill: #00BFA5 !important; }

.color-white {
  color: #ffffff !important; }

.background-white {
  background: #ffffff !important; }

.fill-white * {
  fill: #ffffff !important; }

.color-black {
  color: #000000 !important; }

.background-black {
  background: #000000 !important; }

.fill-black * {
  fill: #000000 !important; }

.color-grey-100 {
  color: #FCFDFF !important; }

.background-grey-100 {
  background: #FCFDFF !important; }

.fill-grey-100 * {
  fill: #FCFDFF !important; }

.color-grey-200 {
  color: #F6F7F8 !important; }

.background-grey-200 {
  background: #F6F7F8 !important; }

.fill-grey-200 * {
  fill: #F6F7F8 !important; }

.color-grey-300 {
  color: #EEF0F3 !important; }

.background-grey-300 {
  background: #EEF0F3 !important; }

.fill-grey-300 * {
  fill: #EEF0F3 !important; }

.color-grey-400 {
  color: #E3E8EB !important; }

.background-grey-400 {
  background: #E3E8EB !important; }

.fill-grey-400 * {
  fill: #E3E8EB !important; }

.color-grey-500 {
  color: #D2DADE !important; }

.background-grey-500 {
  background: #D2DADE !important; }

.fill-grey-500 * {
  fill: #D2DADE !important; }

.color-grey-600 {
  color: #A0AAAF !important; }

.background-grey-600 {
  background: #A0AAAF !important; }

.fill-grey-600 * {
  fill: #A0AAAF !important; }

.color-grey-700 {
  color: #737B80 !important; }

.background-grey-700 {
  background: #737B80 !important; }

.fill-grey-700 * {
  fill: #737B80 !important; }

.color-grey-800 {
  color: #575E62 !important; }

.background-grey-800 {
  background: #575E62 !important; }

.fill-grey-800 * {
  fill: #575E62 !important; }

.color-grey-900 {
  color: #35383A !important; }

.background-grey-900 {
  background: #35383A !important; }

.fill-grey-900 * {
  fill: #35383A !important; }

.color-grey-1000 {
  color: #252729 !important; }

.background-grey-1000 {
  background: #252729 !important; }

.fill-grey-1000 * {
  fill: #252729 !important; }

.color-grey-1100 {
  color: #121213 !important; }

.background-grey-1100 {
  background: #121213 !important; }

.fill-grey-1100 * {
  fill: #121213 !important; }

.color-green-100 {
  color: #91F2E5 !important; }

.background-green-100 {
  background: #91F2E5 !important; }

.fill-green-100 * {
  fill: #91F2E5 !important; }

.color-green-200 {
  color: #2BD9C2 !important; }

.background-green-200 {
  background: #2BD9C2 !important; }

.fill-green-200 * {
  fill: #2BD9C2 !important; }

.color-green-300 {
  color: #00BFA5 !important; }

.background-green-300 {
  background: #00BFA5 !important; }

.fill-green-300 * {
  fill: #00BFA5 !important; }

.color-green-400 {
  color: #00A690 !important; }

.background-green-400 {
  background: #00A690 !important; }

.fill-green-400 * {
  fill: #00A690 !important; }

.color-green-500 {
  color: #00806E !important; }

.background-green-500 {
  background: #00806E !important; }

.fill-green-500 * {
  fill: #00806E !important; }

.color-yellow-100 {
  color: #FFF0CC !important; }

.background-yellow-100 {
  background: #FFF0CC !important; }

.fill-yellow-100 * {
  fill: #FFF0CC !important; }

.color-yellow-200 {
  color: #FFD166 !important; }

.background-yellow-200 {
  background: #FFD166 !important; }

.fill-yellow-200 * {
  fill: #FFD166 !important; }

.color-yellow-300 {
  color: #FFB300 !important; }

.background-yellow-300 {
  background: #FFB300 !important; }

.fill-yellow-300 * {
  fill: #FFB300 !important; }

.color-yellow-400 {
  color: #E6A100 !important; }

.background-yellow-400 {
  background: #E6A100 !important; }

.fill-yellow-400 * {
  fill: #E6A100 !important; }

.color-yellow-500 {
  color: #B37D00 !important; }

.background-yellow-500 {
  background: #B37D00 !important; }

.fill-yellow-500 * {
  fill: #B37D00 !important; }

.color-orange-100 {
  color: #FFCCBC !important; }

.background-orange-100 {
  background: #FFCCBC !important; }

.fill-orange-100 * {
  fill: #FFCCBC !important; }

.color-orange-200 {
  color: #FF8A65 !important; }

.background-orange-200 {
  background: #FF8A65 !important; }

.fill-orange-200 * {
  fill: #FF8A65 !important; }

.color-orange-300 {
  color: #FF7043 !important; }

.background-orange-300 {
  background: #FF7043 !important; }

.fill-orange-300 * {
  fill: #FF7043 !important; }

.color-orange-400 {
  color: #E64A19 !important; }

.background-orange-400 {
  background: #E64A19 !important; }

.fill-orange-400 * {
  fill: #E64A19 !important; }

.color-orange-500 {
  color: #BF360C !important; }

.background-orange-500 {
  background: #BF360C !important; }

.fill-orange-500 * {
  fill: #BF360C !important; }

.color-purple-100 {
  color: #E1BEE7 !important; }

.background-purple-100 {
  background: #E1BEE7 !important; }

.fill-purple-100 * {
  fill: #E1BEE7 !important; }

.color-purple-200 {
  color: #BA68C8 !important; }

.background-purple-200 {
  background: #BA68C8 !important; }

.fill-purple-200 * {
  fill: #BA68C8 !important; }

.color-purple-300 {
  color: #8D25AA !important; }

.background-purple-300 {
  background: #8D25AA !important; }

.fill-purple-300 * {
  fill: #8D25AA !important; }

.color-purple-400 {
  color: #6A1C9A !important; }

.background-purple-400 {
  background: #6A1C9A !important; }

.fill-purple-400 * {
  fill: #6A1C9A !important; }

.color-purple-500 {
  color: #49148B !important; }

.background-purple-500 {
  background: #49148B !important; }

.fill-purple-500 * {
  fill: #49148B !important; }

.color-blue-100 {
  color: #BBDCF7 !important; }

.background-blue-100 {
  background: #BBDCF7 !important; }

.fill-blue-100 * {
  fill: #BBDCF7 !important; }

.color-blue-200 {
  color: #41A2F0 !important; }

.background-blue-200 {
  background: #41A2F0 !important; }

.fill-blue-200 * {
  fill: #41A2F0 !important; }

.color-blue-300 {
  color: #2962FF !important; }

.background-blue-300 {
  background: #2962FF !important; }

.fill-blue-300 * {
  fill: #2962FF !important; }

.color-blue-400 {
  color: #2753CC !important; }

.background-blue-400 {
  background: #2753CC !important; }

.fill-blue-400 * {
  fill: #2753CC !important; }

.color-blue-500 {
  color: #183B99 !important; }

.background-blue-500 {
  background: #183B99 !important; }

.fill-blue-500 * {
  fill: #183B99 !important; }

.color-red-100 {
  color: #F8BBD0 !important; }

.background-red-100 {
  background: #F8BBD0 !important; }

.fill-red-100 * {
  fill: #F8BBD0 !important; }

.color-red-200 {
  color: #F06292 !important; }

.background-red-200 {
  background: #F06292 !important; }

.fill-red-200 * {
  fill: #F06292 !important; }

.color-red-300 {
  color: #F50057 !important; }

.background-red-300 {
  background: #F50057 !important; }

.fill-red-300 * {
  fill: #F50057 !important; }

.color-red-400 {
  color: #C2185B !important; }

.background-red-400 {
  background: #C2185B !important; }

.fill-red-400 * {
  fill: #C2185B !important; }

.color-red-500 {
  color: #870E4F !important; }

.background-red-500 {
  background: #870E4F !important; }

.fill-red-500 * {
  fill: #870E4F !important; }

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  border: 1px solid #e3e7ea; }

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px; }

::-webkit-scrollbar-thumb {
  background: #d1dade;
  border: 3px solid #edf0f2;
  border-radius: 100px; }
  ::-webkit-scrollbar-thumb:hover {
    background: #d1dade; }
  ::-webkit-scrollbar-thumb:active {
    background: #d1dade; }

::-webkit-scrollbar-track {
  background: #edf0f2;
  border: 0px none #ffffff;
  border-radius: 0px;
  width: 14px; }
  ::-webkit-scrollbar-track:hover {
    background: #edf0f2; }
  ::-webkit-scrollbar-track:active {
    background: #edf0f2; }

::-webkit-scrollbar-corner {
  background: transparent; }

.conversable-selector-component {
  display: block;
  background: #FCFDFF;
  color: #A0AAAF;
  padding: 20px 20px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #E3E8EB; }
  .conversable-selector-component .conversable {
    display: flex;
    justify-content: space-between; }
    .conversable-selector-component .conversable h3 {
      margin-top: 0px;
      line-height: 42px; }
    .conversable-selector-component .conversable span {
      display: block;
      line-height: 40px;
      width: 100%;
      text-transform: capitalize; }
      .conversable-selector-component .conversable span .nama-icon {
        width: 24px;
        float: left;
        margin: 8px 8px 8px 0px;
        height: 24px; }

#nama-platform {
  display: flex;
  box-sizing: border-box;
  background-color: #FCFDFF;
  overflow: hidden;
  padding: 0px;
  margin: 0px; }

body.full-body-app {
  display: flex;
  box-sizing: border-box;
  background-color: #FCFDFF;
  overflow: hidden;
  padding: 0px;
  margin: 0px; }

.nama-button p {
  margin: 0;
  padding: 0; }
