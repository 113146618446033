
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,500,600');

$all-titles: 'h1,h2,h3,h4,h5,h6,h7,h8,hr';
$all-titles-normal: 'h2,h3,h4,h7,h8';
$all-titles-semibolded: 'h1,p';
$all-titles-bolded: 'h5,h6';


@mixin title_typography($font, $height, $top, $spacing, $color){
    font-size: $font;
    line-height: $height;
    margin-top: $top;
    letter-spacing: $spacing;
    color:$color;
}

@mixin product_typography(){
  font-family: $product-font-family;
  font-size: 16px;
  font-weight: 300;
}

@mixin _typography() {

    p, code, span, a{
      @include product_typography();
    }

    #{$all-titles} {
      @include product_typography();
      margin: 0px;
        &.row{
          padding-top: 8px;
          border-top: 1px solid $grey-500;
        }
    }


    #{$all-titles-normal} {
      font-weight: 300;
    }
    #{$all-titles-semibolded} {
      font-weight: 400;
    }
    #{$all-titles-bolded} {
      font-weight: 500;
    }

    h1{
      @include title_typography(32px, 40px, 48px,  -0.04px, $grey-800);
    }
    h2{
      @include title_typography(24px, 28px, 40px,  -0.04px, $grey-800);
    }
    h3{
      @include title_typography(20px, 28px, 36px,  -0.08px, $grey-800);
    }
    h4{
      @include title_typography(16px, 20px, 32px,  -0.06px, $grey-800);
    }
    h5{
      @include title_typography(14px, 16px, 24px,  -0.04px, $grey-800);
    }
    h6{
      @include title_typography(12px, 16px, 20px,  0px, $grey-600);
    }
    h7{
      @include title_typography(12px, 16px, 20px,  0px, $grey-600);
      display: block;
    }
    h8{
      @include title_typography(11px, 16px, 20px,  0.06px, $grey-600);
      display: block;
    }
    p{
      @include title_typography(16px, 22px, 12px,  -0.04px, $grey-800);
      font-weight: 300;
    }


    a {
      color: $blue-200;
        cursor: pointer;
        transition: color $base-duration $base-timing;
        &:hover {
            color: $blue-300;
        }
        &:focus {
            outline: none;
        }
    }

    hr {
        border-bottom: 1px solid $grey-600;
        margin: 0px;
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }
}
